/* eslint-disable */
import React, { useEffect, useState, useMemo } from "react";

// Material UI table
import { MaterialReactTable } from "material-react-table";
//Material UI components for rendring menu and others
import { MenuItem, Box, Tooltip, IconButton } from "@mui/material";

import ProjectManagementCommonHeader from "components/ProjectManagementComponents/Common/Header/ProjectManagementCommonHeader";
import ProjectManagementListViewHeader from "./Header/ProjectManagementListViewHeader";

const ProjectManagementListViewBody = () => {
  const samepleData = [
    {
      project: "Lobees",
      section: "UI/UX Design",
      taskCount: "10",
      January: "-€100",
      February: "+€6000",
      March: "-€800",
      April: "-€600",
      May: "+€900",
      June: "€0",
      July: "+€300",
      August: "-€800",
      September: "-€600",
      October: "+€900",
      November: "+€8000",
      December: "-€200",
    },
    {
      project: "Lobees",
      section: "Development",
      taskCount: "12",
      January: "-€900",
      February: "-€100",
      March: "-€800",
      April: "-€600",
      May: "€0",
      June: "€0",
      July: "+€6000",
      August: "-€800",
      September: "-€600",
      October: "+€900",
      November: "+€8000",
      December: "-€200",
    },
    {
      project: "Funbrain",
      section: "UI/UX Design",
      taskCount: "10",
      January: "-€100",
      February: "+€6000",
      March: "-€800",
      April: "-€600",
      May: "+€900",
      June: "€0",
      July: "+€300",
      August: "-€800",
      September: "-€600",
      October: "+€900",
      November: "+€8000",
      December: "-€200",
    },
    {
      project: "Funbrain",
      section: "Development",
      taskCount: "12",
      January: "-€900",
      February: "-€100",
      March: "-€800",
      April: "-€600",
      May: "€0",
      June: "€0",
      July: "+€6000",
      August: "-€800",
      September: "-€600",
      October: "+€900",
      November: "+€8000",
      December: "-€200",
    },
    {
      project: "Sonina",
      section: "UI/UX Design",
      taskCount: "10",
      January: "-€100",
      February: "+€6000",
      March: "-€800",
      April: "-€600",
      May: "+€900",
      June: "€0",
      July: "+€300",
      August: "-€800",
      September: "-€600",
      October: "+€900",
      November: "+€8000",
      December: "-€200",
    },
    {
      project: "MpforAll",
      section: "Development",
      taskCount: "12",
      January: "-€900",
      February: "-€100",
      March: "-€800",
      April: "-€600",
      May: "€0",
      June: "€0",
      July: "+€6000",
      August: "-€800",
      September: "-€600",
      October: "+€900",
      November: "+€8000",
      December: "-€200",
    },
  ];

  const columns = useMemo(
    () => [
      {
        accessorKey: "project",
        header: "Project",
      },
      {
        accessorKey: "section",
        header: "Section",
      },
      {
        accessorKey: "taskCount",
        header: "Task",
      },
      {
        accessorKey: "January",
        header: "January",
      },
      {
        accessorKey: "February",
        header: "February",
      },

      {
        accessorKey: "March",
        header: "March",
      },
      {
        accessorKey: "April",
        header: "April",
      },
      {
        accessorKey: "May",
        header: "May",
      },
      {
        accessorKey: "June",
        header: "June",
      },
      {
        accessorKey: "July",
        header: "July",
      },
      {
        accessorKey: "August",
        header: "August",
      },
      {
        accessorKey: "September",
        header: "September",
      },
      {
        accessorKey: "October",
        header: "October",
      },
      {
        accessorKey: "November",
        header: "November",
      },
      {
        accessorKey: "December",
        header: "December",
      },
    ],
    []
  );

  return (
    <div id="content_wrapper">
      <section class="survey-wrapper bg-white pb-5">
        <ProjectManagementCommonHeader componentName="fullreport" />

        <div class="container-fluid px-lg-5">
          <ProjectManagementListViewHeader />

          <div class="table-wrapper">
            <MaterialReactTable
              columns={columns} // map columns to be displayed with api data,
              data={samepleData} // data from api to be displayed
              enableGrouping
              //   enableRowSelection // enable showing checkbox
              //   getRowId={(row) => row._id} // map which value to select with row checkbox
              //   onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
              //   state={{ rowSelection }} //pass our managed row selection state to the table to use
              //   enableRowActions // show the action column
              //   renderRowActionMenuItems={({ row }) => [
              //     <MenuItem key="view">
              //       <Link
              //         className="dropdown-item"
              //         to={`/admin/projectmanagement/details/${row.id}`}
              //       >
              //         {t("View Details")}
              //       </Link>
              //     </MenuItem>,
              //     <MenuItem key="copy">
              //       <Link
              //         className="dropdown-item"
              //         to={`/admin/projectmanagement/copyproject/${row.id}`}
              //       >
              //         {t("Copy Project")}
              //       </Link>
              //     </MenuItem>,
              //     <MenuItem key="member">
              //       <Link
              //         className="dropdown-item"
              //         to={`/admin/projectmanagement/members/${row.id}`}
              //       >
              //         {t("View Members")}
              //       </Link>
              //     </MenuItem>,
              //     <MenuItem key="task">
              //       <Link
              //         className="dropdown-item"
              //         to={`/admin/projectmanagement/tasksection/${row.id}`}
              //       >
              //         {t("View Task")}
              //       </Link>
              //     </MenuItem>,
              //     <MenuItem key="log">
              //       <Link
              //         className="dropdown-item"
              //         to={`/admin/projectmanagement/logs/${row.id}`}
              //       >
              //         {t("View Logs")}
              //       </Link>
              //     </MenuItem>,
              //   ]} // action columns menu items
              defaultColumn={{
                minSize: 20,
                maxSize: 300,
                size: 50, //make columns wider by default
              }}
              muiTableContainerProps={{
                sx: {
                  maxHeight: "60vh",
                },
              }}
              enableStickyHeader
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default ProjectManagementListViewBody;
