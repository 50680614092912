import Header from "components/Common/Header/Header";
import ProjectDashboardBody from "components/ProjectManagementComponents/Dashboard/DashboardBody/ProjectDashboardBody";
import React, { useEffect } from "react";

const ProjectManagementDashboard = () => {
  // -------- for title ------------
  useEffect(() => {
    document.title = "Project Management";
  }, []);

  return (
    <main id="app">
      {/* ---- common header ---- */}
      <Header moduleName="projects" />
      {/* ---- dashboard body ---- */}
      <ProjectDashboardBody />
    </main>
  );
};

export default ProjectManagementDashboard;
