/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Select from "react-select";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { deleteData, getData, postData, putData } from "utils/Gateway";

const AddFaqPopup = ({
  noteId,
  setNoteId,
  afterPopupclose,
  setShowAlert,
  setAlertMessage,
  setMessageType,
}) => {
  const { t } = useTranslation();

  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const params = useParams();

  const [lessonInput, setlessonInput] = useState("");
  const [lessonList, setlessonList] = useState([]);

  const [noteTitle, setNoteTitle] = useState("");
  const [noteDetails, setNoteDetails] = useState("");
  const [faqNumber, setfaqNumber] = useState("");
  const [lessonValue, setlessonValue] = useState(null);
  const [selectedLessonId, setselectedLessonId] = useState(null);
  const [isPrivate, setisPrivate] = useState(false);

  const [isSaving, setIsSaving] = useState(false);

  const lessonInputHandler = (val) => {
    setlessonInput(val);
  };

  const getAllLessons = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_COURSE_LESSON_LIST_OPTIONS +
        `?token=${token}&courseid=${params.id}&filtertitle=${lessonInput}`;

      const response = await getData(requestURL);

      if (response.status) {
        setlessonList(response.data);
      }
    } catch (error) {}
  };

  const lessonSelectionHandler = (val) => {
    if (val) {
      setlessonValue(val);
      setselectedLessonId(val.value);
    } else {
      setlessonValue(null);
      setselectedLessonId(null);
      setlessonInput("");
      setlessonList([]);
    }
  };

  //get note details
  const getFaqDetails = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_NOTE_DETAILS +
        `/${noteId}?token=${token}`;

      const response = await getData(requestURL);

      console.log(response);

      if (response.status) {
        setNoteTitle(response.data.title);
        setNoteDetails(response.data.details);
        setfaqNumber(response.data.rank);

        if (response.data?.lessonvalue) {
          lessonSelectionHandler(response.data?.lessonvalue);
        }

        setisPrivate(response.data.isprivate);
      }
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
      setShowAlert(true);
    }
  };

  //function for save faq
  const saveFaqHandler = async () => {
    try {
      setIsSaving(true);

      let noteData = {
        notetype: "2",
        title: noteTitle,
        details: noteDetails,
        rank: faqNumber,
        isprivate: isPrivate,
        course: params.id,
        lessonid: selectedLessonId,
      };

      let requestUrl = url.API_BASE_URL;

      let response = {};

      if (noteId) {
        requestUrl += url.API_UPDATE_NOTE + `/${noteId}?token=${token}`;
        response = await putData(requestUrl, noteData);
      } else {
        requestUrl += url.API_ADD_NEW_NOTE + `?token=${token}`;
        response = await postData(requestUrl, noteData);
      }

      setIsSaving(false);

      console.log(response);

      if (response.status) {
        setMessageType("success");

        let offCanvasPopup = document.querySelector("#offcanvasAddFaq");
        let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
        offCanvas.hide();
        resetHandler();
        afterPopupclose();
      } else {
        setMessageType("error");
      }

      setAlertMessage(response.message);
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
    }

    setShowAlert(true);
  };

  const resetHandler = () => {
    setNoteTitle("");
    setNoteDetails("");
    setfaqNumber("");
    setlessonValue(null);
    setselectedLessonId(null);
    setlessonInput("");
    setlessonList([]);
    setNoteId(null);
  };

  useEffect(() => {
    if (noteId) {
      getFaqDetails();
    }
  }, [noteId]);

  useEffect(() => {
    if (params.id && lessonInput.length > 0) {
      getAllLessons();
    }
  }, [params.id, lessonInput]);

  return (
    <div
      className="offcanvas md offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="offcanvasAddFaq"
      aria-labelledby="offcanvasAddFaqLabel"
    >
      <div className="offcanvas-header p-4 pb-0">
        <h3 className="offcanvas-title" id="offcanvasAddFaqLabel">
          {t("Add Faq")}
        </h3>
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div className="offcanvas-body p-4">
        <form
          onSubmit={(e) => e.preventDefault()}
          className="d-flex flex-column h-100"
        >
          <div className="field-container flex-fill">
            <div className="form-group mb-4">
              <label
                htmlFor="question"
                className="d-block fs-sm fw-semibold mb-2"
              >
                {t("Question")}
              </label>
              <input
                type="text"
                id="question"
                className="form-control fs-sm shadow-none"
                placeholder={t("Enter Question")}
                value={noteTitle}
                onChange={(e) => setNoteTitle(e.target.value)}
              />
            </div>

            <div className="form-group mb-4">
              <label
                htmlFor="answer"
                className="d-block fs-sm fw-semibold mb-2"
              >
                {t("Answer")}
              </label>
              <textarea
                name="answer"
                id="answer"
                cols="30"
                rows="5"
                className="form-control fs-sm shadow-none"
                placeholder={t("Enter Details")}
                value={noteDetails}
                onChange={(e) => setNoteDetails(e.target.value)}
              ></textarea>
            </div>

            <div className="form-group mb-4">
              <label
                htmlFor="question"
                className="d-block fs-sm fw-semibold mb-2"
              >
                {t("Rank")}
              </label>
              <input
                type="number"
                id="question"
                min={1}
                className="form-control fs-sm shadow-none"
                placeholder={t("Enter Rank")}
                value={faqNumber}
                onChange={(e) => setfaqNumber(e.target.value)}
              />
            </div>

            <div className="form-group mb-4">
              <label
                htmlFor="answer"
                className="d-block fs-sm fw-semibold mb-2"
              >
                {t("Lesson")}
              </label>
              <Select
                isClearable
                placeholder="Select Lesson"
                options={lessonList}
                value={lessonValue}
                onChange={(val) => {
                  lessonSelectionHandler(val);
                }}
                onInputChange={(val) => {
                  lessonInputHandler(val);
                }}
              />
            </div>

            <div className="form-group mb-4">
              <label
                htmlFor="answer"
                className="d-block fs-sm fw-semibold mb-2"
              >
                {t("Is Private")}
              </label>
              <div className="form-check d-flex align-items-center gap-2 fs-sm p-0 m-0 mb-3">
                <input
                  type="checkbox"
                  id="question"
                  className="form-check-input bg-transparent border-2 border-gray-600 shadow-none m-0"
                  checked={isPrivate}
                  onChange={(e) => {
                    setisPrivate(e.target.checked);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="action d-flex flex-wrap gap-3 justify-content-between">
            <Link
              to="#"
              className="btn btn-outline-primary"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              {t("Close")}
            </Link>
            <button
              type="button"
              className="btn btn-primary"
              onClick={saveFaqHandler}
              disabled={isSaving}
              style={{ cursor: isSaving ? "not-allowed" : "pointer" }}
            >
              {t("Save Faq")}
              {isSaving && (
                <div
                  className="mx-2 spinner-border spinner-border-sm"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddFaqPopup;
