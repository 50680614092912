import Header from "components/Common/Header/Header";
import ProjectManagementListViewBody from "components/ProjectManagementComponents/FullReport/ListView/ProjectManagementListViewBody";
import React, { useEffect } from "react";

const ProjectManagementList = () => {
  // -------- for title ------------
  useEffect(() => {
    document.title = "Project Management";
  }, []);
  return (
    <main id="app">
      {/* ---- common header ---- */}
      <Header moduleName="projects" />

      <ProjectManagementListViewBody />
    </main>
  );
};

export default ProjectManagementList;
