/* eslint-disable */
import React, { useEffect, useContext, useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import Select from "react-select";

import BreadCrumb from "components/Common/BreadCrumb/BreadCrumb";
import VatOptions from "data/Invoice/VAT.json";

import * as url from "helper/UrlHelper";
import { deleteData, getData, postData, putData } from "utils/Gateway";

//import translation function for language translation
import { useTranslation } from "react-i18next";

//import current date
import { getCurrentDateInString } from "helper/getcurrentdatestring";
import { assetImages } from "constants";

const SaveNotePopup = ({
  selectedNoteId = null,
  setSelectedNoteId = () => {},
  afterPopupClose = () => {},
  setShowAlert,
  setAlertMessage,
  setMessageType,
}) => {
  const token = localStorage.getItem("token");
  const { t, i18n } = useTranslation();

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");

  const [noteLeadId, setNoteLeadId] = useState(null);
  const [noteContactId, setNoteContactId] = useState(null);
  const [noteOpportunityId, setNoteOpportunityId] = useState(null);

  const [noteModuleName, setNoteModuleName] = useState("");
  const [noteComponentName, setNoteComponentName] = useState("");
  const [existingNoteTitle, setExistingNoteTitle] = useState("");
  const [existingNoteDetails, setExistingNoteDetails] = useState("");
  const [existingNoteDate, setExistingNoteDate] = useState("");
  const [existingNotePrivate, setExistingNotePrivate] = useState(false);

  const [title, settitle] = useState("");
  const [description, setdescription] = useState("");
  const [isPrivate, setIsPrivate] = useState(false);

  const [updateExistingNote, setUpdateExistingNote] = useState(false);

  const [isSaving, setIsSaving] = useState(false);

  // validation of invoice
  const [validation, setValidation] = useState({
    titleWarning: false,
    detailsWarning: false,
  });

  // function for validation
  const validationHandler = () => {
    let isValid = true;

    if (title === "") {
      setValidation((prevState) => ({ ...prevState, titleWarning: true }));
      isValid = false;
    }

    if (description === "") {
      setValidation((prevState) => ({ ...prevState, detailsWarning: true }));
      isValid = false;
    }

    return isValid;
  };

  //function for get note details
  const getNoteDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_NOTE_DETAILS +
        `/${selectedNoteId}?token=${token}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        setNoteLeadId(response.data.leadid);
        setNoteContactId(response.data.contactid);
        setNoteOpportunityId(response.data.invoiceid);
        setNoteModuleName(response.data.notemodulename);
        setNoteComponentName(response.data.notecomponent);
        setExistingNoteTitle(response.data.title);
        setExistingNoteDetails(response.data.details);
        setExistingNoteDate(response.data.notedate);
        setExistingNotePrivate(response.data.isprivate);
      }
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
      setShowAlert(true);
    }
  };

  const updateNoteStatus = () => {
    setUpdateExistingNote(true);
    settitle(existingNoteTitle);
    setdescription(existingNoteDetails);
    setIsPrivate(existingNotePrivate);
  };

  const clearNoteStatus = () => {
    setUpdateExistingNote(false);
    settitle("");
    setdescription("");
    setIsPrivate(false);
  };

  //* save the note record */
  const saveNote = async () => {
    if (validationHandler()) {
      try {
        setIsSaving(true);

        let noteData = {
          notetype: "1",
          title,
          details: description,
          isprivate: isPrivate,
        };

        if (noteLeadId) {
          noteData.leadid = noteLeadId;
        }

        if (noteContactId) {
          noteData.contactid = noteContactId;
        }

        if (noteOpportunityId) {
          noteData.invoiceid = noteOpportunityId;
        }

        // if (taskId) {
        //   noteData.taskid = taskId;
        // }

        let requestUrl = url.API_BASE_URL;

        let response = {};

        if (updateExistingNote) {
          requestUrl +=
            url.API_UPDATE_NOTE + `/${selectedNoteId}?token=${token}`;

          response = await putData(requestUrl, noteData);
        } else {
          requestUrl += url.API_ADD_NEW_NOTE + `?token=${token}`;

          response = await postData(requestUrl, noteData);
        }

        setIsSaving(false);

        console.log(response);

        if (response.status) {
          setMessageType("success");
          bootstrap.Offcanvas.getInstance(
            document.querySelector("#saveNote")
          ).hide();

          resetHandler();
          afterPopupClose();
        } else {
          setMessageType("error");
        }

        setAlertMessage(response.message);
      } catch (error) {
        setAlertMessage(error.message);
        setMessageType("error");
      }
    } else {
      setAlertMessage("Please fill up all required fields");
      setMessageType("error");
    }

    setShowAlert(true);
  };

  const resetHandler = () => {
    setValidation({
      titleWarning: false,
      detailsWarning: false,
    });

    //reset component ids
    setNoteLeadId(null);
    setNoteContactId(null);
    setNoteOpportunityId(null);

    settitle("");
    setdescription("");
    setIsPrivate(false);

    setNoteModuleName("");
    setNoteComponentName("");

    setExistingNoteTitle("");
    setExistingNoteDetails("");
    setExistingNotePrivate(false);

    setUpdateExistingNote(false);

    setSelectedNoteId(null);
  };

  useEffect(() => {
    if (selectedNoteId) {
      getNoteDetails();
    }
  }, [selectedNoteId]);

  return (
    <div
      className="offcanvas invoice_w offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="saveNote"
      aria-labelledby="saveNote"
    >
      <div className="offcanvas-header p-4 pb-0">
        <h3 className="offcanvas-title" id="offcanvasLabelDetailsLabel">
          Save Note
        </h3>
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetHandler}
        ></button>
      </div>
      <div className="offcanvas-body p-4">
        <div className="row">
          <div className="col-lg-8">
            <div className="p-3 p-md-4 border border-gray-300 rounded-10 shadow-sm">
              <form
                onSubmit={(e) => e.preventDefault()}
                className="d-flex flex-column h-100"
              >
                <div className="fields-container flex-fill">
                  {/* <!-- ========== Start module type Section ========== --> */}
                  <div className="form-group mb-4">
                    <label
                      htmlFor="labelName"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      {t("Module")}
                    </label>
                    {noteModuleName === "" ? null : (
                      <p className="form-control fs-sm shadow-none">
                        {noteModuleName}
                      </p>
                    )}
                  </div>
                  {/* <!-- ========== End module type Section ========== --> */}

                  {/* <!-- ========== Start component lead/contact Section ========== --> */}

                  <div className="form-group mb-4">
                    <label
                      htmlFor="labelName"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      {t("Component")}
                    </label>

                    {noteComponentName === "" ? null : (
                      <p className="form-control fs-sm shadow-none">
                        {noteComponentName}
                      </p>
                    )}
                  </div>

                  {/* <!-- ========== End component lead/contact Section ========== --> */}

                  {/* <!-- ========== Start title Section ========== --> */}
                  <div className="form-group mb-4">
                    <label
                      htmlFor="labelName"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      Title
                    </label>
                    <input
                      type="text"
                      id="labelName"
                      className="form-control fs-sm shadow-none"
                      placeholder="Enter here"
                      value={title}
                      onChange={(e) => {
                        settitle(e.target.value);
                        setValidation((prevState) => ({
                          ...prevState,
                          titleWarning: false,
                        }));
                      }}
                    />
                    {validation.titleWarning && (
                      <div className="error-message mt-2">
                        <p className="d-flex align-items-center gap-1 text-danger">
                          <span className="material-symbols-outlined">
                            warning
                          </span>
                          <span>Please enter a title!</span>
                        </p>
                      </div>
                    )}
                  </div>
                  {/* <!-- ========== End title Section ========== --> */}

                  {/* <!-- ========== Start description Section ========== --> */}
                  <div className="form-group mb-4">
                    <label
                      htmlFor="description"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      Description
                    </label>
                    <textarea
                      name=""
                      id=""
                      rows="5"
                      className="form-control fs-sm shadow-none"
                      value={description}
                      onChange={(e) => {
                        setdescription(e.target.value);
                      }}
                    ></textarea>
                    {validation.detailsWarning && (
                      <div className="error-message mt-2">
                        <p className="d-flex align-items-center gap-1 text-danger">
                          <span className="material-symbols-outlined">
                            warning
                          </span>
                          <span>Please enter details!</span>
                        </p>
                      </div>
                    )}
                  </div>
                  {/* <!-- ========== End description Section ========== --> */}

                  {/* <!-- ========== Start is private checkbox Section ========== --> */}
                  <div className="form-group d-flex align-items-center gap-2 mb-4">
                    <div className="form-check">
                      <input
                        className="form-check-input check-success border-primary"
                        type="checkbox"
                        checked={isPrivate}
                        onChange={(e) => setIsPrivate(e.target.checked)}
                      />
                    </div>

                    <span className="d-block fs-sm fw-semibold">
                      {t("Mark as Private")}
                    </span>
                  </div>
                  {/* <!-- ========== End is private checkbox Section ========== --> */}
                </div>

                <div className="action d-flex justify-content-between gap-3">
                  <Link
                    to="#"
                    className="btn btn-outline-primary"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                    onClick={resetHandler}
                  >
                    {t("Close")}
                  </Link>
                  <button
                    onClick={saveNote}
                    type="button"
                    className="btn btn-primary"
                    disabled={isSaving ? true : false}
                    style={{
                      cursor: isSaving ? "not-allowed" : "pointer",
                    }}
                  >
                    {t("Save Note")}
                    {isSaving && (
                      <div
                        className="mx-2 spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="p-4 border border-gray-300 rounded-10 shadow-sm">
              <h3>{`${t("Note")}`}</h3>

              <ul className="note-list">
                <li className="note mb-4">
                  <div className="title d-flex gap-2 align-items-center justify-content-between mb-2">
                    <h3 className="mb-0">{existingNoteTitle}</h3>

                    <div className="dropdown">
                      <button
                        className="d-block p-0 bg-transparent text-gray border-0 hide-dropdown-arrow dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <span className="d-block material-symbols-outlined">
                          more_vert
                        </span>
                      </button>
                      <ul className="dropdown-menu dropdown-menu-end bg-white fs-sm border-0 rounded-10 shadow-sm">
                        <li>
                          <Link
                            className="dropdown-item"
                            to="#"
                            onClick={() => {
                              updateNoteStatus();
                            }}
                          >
                            Edit
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item"
                            to="#"
                            onClick={() => {
                              clearNoteStatus();
                            }}
                          >
                            Clear
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="desc fs-sm">
                    <p className="date mb-0">{existingNoteDate}</p>
                    <p className="text-gray">{existingNoteDetails}</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SaveNotePopup;
