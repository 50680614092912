import React from "react";
import { Link } from "react-router-dom";

const DropdownMenuHeader = () => {
  return (
    <ul className="dropdown-menu w-100 bg-white fs-sm border-0 rounded-10 shadow-sm">
      <li>
        <Link
          to="/admin/projectmanagement/fullreport/graph"
          className="dropdown-item d-flex align-items-center gap-1"
        >
          <span className="d-block material-symbols-outlined icon-sm">
            leaderboard
          </span>
          <span className="d-block">Graph View</span>
        </Link>
      </li>
      <li>
        <Link
          to="/admin/projectmanagement/fullreport/list"
          className="dropdown-item d-flex align-items-center gap-1"
        >
          <span className="d-block material-symbols-outlined icon-sm">
            table_rows
          </span>
          <span className="d-block">List View</span>
        </Link>
      </li>
      {/* <li>
        <Link
          to="/admin/projectmanagement/fullreport/kanban"
          className="dropdown-item d-flex align-items-center gap-1"
        >
          <span className="d-block material-symbols-outlined icon-sm">
            dashboard
          </span>
          <span className="d-block">Kanban View</span>
        </Link>
      </li> */}
    </ul>
  );
};

export default DropdownMenuHeader;
