/* eslint-disable */
import React, { useRef, useEffect, useState } from "react";
import { useParams, Link, useHistory } from "react-router-dom";

// Import CKEditor here (both required).
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";

import {
  postData,
  getData,
  putData,
  uploadSingleFile,
  uploadMultipleFile,
} from "utils/Gateway";

import TabsHeader from "components/Common/TabsHeader/TabsHeader";
import BreadCrumb from "components/Common/BreadCrumb/BreadCrumb";

// import Select from "react-select";
import AfterSaveProjectModal from "components/Common/Modal/AfterSaveProjectModal";
import { assetImages } from "constants";
import { projectDetailsHeaderLinks } from "helper/ProjectHelper/ProjectHelper";
import downloadFileHandler from "helper/DownloadFile";
import {
  createdAtToDateConverter,
  getDateFormatInSpanish,
} from "helper/Common/CommonHelper";
import AlertNotification from "components/Common/AlertNotification/AlertNotification";

// translation --------------
import { useTranslation } from "react-i18next";

const ProjectDetailBody = () => {
  const loadingCircle = [1, 2, 3, 4, 5, 6]; //for upload doc

  const params = useParams();
  const history = useHistory();

  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");

  const { t } = useTranslation(); //for translation

  const docFileInputRef = useRef(null);

  /*---- CKEditor classic editor configuration start ----- */
  const editorConfig = {
    toolbar: ["bold", "italic", "link", "bulletedList", "numberedList"],
    ckfinder: {
      // Upload the images to the server using the CKFinder QuickUpload command.
      //   uploadUrl: url.URL_TEXT_EDITOR_IMAGE_UPLOAD_2,
    },
    placeholder: t("Type description here..."),
  };
  /*---- CKEditor classic editor configuration end ----- */

  // -------- tabs header object --------------------
  const commonHeaderObject = projectDetailsHeaderLinks(params.id, t);

  // -------- breadcrumb text --------
  const breadCrumbText = [
    { title: t("Projects"), link: "/admin/projectmanagement/list" },
    { title: t("Details") },
  ];

  const [isLoading, setisLoading] = useState(false);
  const [title, settitle] = useState("");
  const [summary, setsummary] = useState("");
  const [details, setdetails] = useState("");
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [docFileUploading, setDocFileUploading] = useState(false);
  const [uploadedDocFiles, setUploadedDocFiles] = useState([]);
  const [uploadedDocFileIds, setUploadedDocFileIds] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [savedProjectId, setsavedProjectId] = useState(null);

  const [isProjectModerator, setisProjectModerator] = useState(false);
  const [projectModeratorId, setprojectModeratorId] = useState(null);

  const [projectMemberStatus, setProjectMemberStatus] = useState("1");
  const [inviteDate, setinviteDate] = useState("");
  const [loggedInMemberId, setLoggedInMemberId] = useState(null);
  const [isAccepting, setisAccepting] = useState(false);

  //* alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  // const loadingCircle = [1, 2, 3, 4, 5, 6]; //for upload doc

  //function for open doc imput
  const openDocFileInput = () => {
    docFileInputRef.current.click();
  };

  //function for upload doc files
  const docFileUploadHandler = async (e) => {
    try {
      setDocFileUploading(true);

      const docFiles = e.target.files;

      let requestUrl =
        url.API_BASE_URL + url.API_MULTIPLE_FILE_UPLOAD + `?token=${token}`;

      const response = await uploadMultipleFile(requestUrl, docFiles);

      if (response.status) {
        /*----- assign uploaded files with path for display start ------*/
        const uploadedFileValues = response.data.map(
          ({ name, path, _id, ...other }) => ({
            name,
            path,
            _id,
          })
        );

        //check old file exist or not if exist then new file will concat with old file
        if (uploadedDocFiles.length == 0) {
          setUploadedDocFiles(uploadedFileValues);
        } else {
          let fileArr = uploadedDocFiles.concat(uploadedFileValues);
          setUploadedDocFiles(fileArr);
        }
        /*----- assign uploaded files with path for display end ------*/

        setDocFileUploading(false);

        /*----- assign uploaded file ids start ------*/
        const fileIds = response.data.map(({ _id, ...other }) => ({ _id }));

        //check old file ids are exist or not.If exist then new id will concat with old id
        if (uploadedDocFileIds.length == 0) {
          setUploadedDocFileIds(fileIds);
        } else {
          let fileArr = uploadedDocFileIds.concat(fileIds);
          setUploadedDocFileIds(fileArr);
        }
        /*----- assign uploaded file ids end ------*/
      } else {
        setAlertMessage(response.message);
        setMessageType("error");
        setShowAlert(true);
      }
      resetDocInput();
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
      setShowAlert(true);
    }
  };

  //function for delete file from upload stack
  const closeDocFileHandler = (index) => {
    const closedFile = [...uploadedDocFiles];
    const removedFileId = [...uploadedDocFileIds];

    closedFile.splice(index, 1);
    removedFileId.splice(index, 1);

    setUploadedDocFiles(closedFile);
    setUploadedDocFileIds(removedFileId);

    resetDocInput();
  };

  //function for reset doc inputs
  const resetDocInput = () => {
    const file = document.getElementById("uploadedProjectDoc");
    if (file) {
      file.value = null;
    } else {
      return;
    }
  };

  //function for get details of event member
  const getMemberDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_PROJECT_MEMBER_DETAILS_QUERY +
        `?token=${token}&projectid=${params.id}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        if (response.data) {
          setLoggedInMemberId(response.data._id);
          setProjectMemberStatus(response.data.memberstatus);
          setinviteDate(createdAtToDateConverter(response.data.createdAt));
        }
      } else {
        console.log(response.message);
      }
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
      setShowAlert(true);
    }
  };

  //function for get project details
  const getProjectDetails = async () => {
    try {
      setisLoading(true);
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_PROJECT_DETAILS +
        `/${params.id}?token=${token}`;

      const response = await getData(requestUrl);

      setisLoading(false);
      // console.log(response);

      if (response.status && response.data) {
        const data = response.data;

        setprojectModeratorId(data.moderator._id);
        let isModerator = false;

        if (
          data.moderator._id.toString() === userInfo._id.toString() ||
          userInfo.role.slug === "ADMIN" ||
          userInfo.role.slug === "SUPER_ADMIN"
        ) {
          isModerator = true;
        } else {
          getMemberDetails();
        }

        setisProjectModerator(isModerator);

        settitle(data.title);
        setsummary(data.summary);
        setdetails(data.description);
        setstartDate(data.startdate);
        setendDate(data.enddate);
        //assign uploaded files
        setUploadedDocFiles(response?.data?.uploadedfiles);
        setUploadedDocFileIds(response?.data?.uploadedfileids);
      } else {
        setAlertMessage(response.message);
        setMessageType("error");
        setShowAlert(true);
      }
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
      setShowAlert(true);
    }
  };

  //accept invite
  const acceptMemberInviteHandler = async () => {
    try {
      setisAccepting(true);
      let memberProjectData = {
        memberstatus: "1",
      };

      let requestUrl =
        url.API_BASE_URL +
        url.API_ACCEPT_INVITE_PROJECT_MEMBER +
        `/${loggedInMemberId}` +
        `?token=${token}&projectmodid=${projectModeratorId}`;

      const response = await putData(requestUrl, memberProjectData);

      // console.log(response);

      setisAccepting(false);
      if (response.status) {
        getProjectDetails();
      } else {
        setAlertMessage(response.message);
        setMessageType("error");
        setShowAlert(true);
      }
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
      setShowAlert(true);
    }
  };

  //function for save project
  const saveProjectHandler = async () => {
    try {
      setIsSaving(true);

      let projectData = {
        title,
        summary,
        description: details,
        startdate: startDate,
        enddate: endDate,
        uploads: uploadedDocFileIds,
      };

      let requestUrl = url.API_BASE_URL;

      let response = {};

      if (params.id) {
        requestUrl =
          requestUrl + url.API_UPDATE_PROJECT + `/${params.id}?token=${token}`;
        response = await putData(requestUrl, projectData);
      } else {
        requestUrl = requestUrl + url.API_ADD_NEW_PROJECT + `?token=${token}`;
        response = await postData(requestUrl, projectData);
      }

      // console.log(response);

      setIsSaving(false);

      if (response.status) {
        setMessageType("success");
        setsavedProjectId(response.data._id);
        const bootstrapModal = new bootstrap.Modal(
          document.getElementById("afterSaveProjectModal")
        );
        bootstrapModal.show();
      } else {
        setMessageType("error");
      }

      setAlertMessage(response.message);
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
    }

    setShowAlert(true);
  };

  //function for reset
  const resetHandler = () => {
    settitle("");
    setsummary("");
    setdetails("");
    setstartDate("");
    setendDate("");
    setUploadedDocFiles([]);
    setUploadedDocFileIds([]);
  };

  //function for aftermodal close
  const afterSaveModalClose = (pageName) => {
    if (pageName == "details") {
      history.push(`/admin/projectmanagement/details/${savedProjectId}`);
    } else {
      resetHandler();
      history.push("/admin/projectmanagement/list");
    }
  };

  useEffect(() => {
    if (params.id) {
      getProjectDetails();
    }
  }, [params.id]);

  const onAlertClose = () => {
    // Set the state variable 'showAlert' to false to hide the alert.
    setShowAlert(false);

    // Clear the alert message by setting the state variable 'alertMessage' to an empty string.
    setAlertMessage("");

    // Clear the alert type by setting the state variable 'messageType' to an empty string.
    setMessageType("");
  };

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    moduleAccess.includes("MOD_PROJECT")
  ) {
    return (
      <div id="content_wrapper">
        <section className="event-details-wrapper bg-white pb-5">
          {/* ------ common header -------- */}
          {params.id && (
            <TabsHeader
              commonHeaderObject={commonHeaderObject}
              activeOption={t("Details")}
            />
          )}

          {isLoading ? null : (
            <div className="container-fluid px-lg-5 pt-4 pt-md-0">
              {/* ------- bread crumb -------- */}
              <BreadCrumb
                breadCrumbText={breadCrumbText}
                bottom={true}
                displayName={`${title}`}
              />

              {/* -------- form area ---------- */}
              {params.id ? (
                isProjectModerator ? (
                  <div className="create-lead-container p-3 p-md-4 border border-gray-300 rounded-10 shadow-sm">
                    {/* title */}
                    <div className="form-group mb-4">
                      <label
                        htmlFor="projectName"
                        className="d-block fs-sm fw-semibold mb-2"
                      >
                        {t("Project Name")}
                      </label>
                      <input
                        type="text"
                        id="projectName"
                        className="form-control fs-sm shadow-none"
                        placeholder={t("Name")}
                        value={title}
                        onChange={(e) => settitle(e.target.value)}
                      />
                    </div>

                    {/* summary */}
                    <div className="form-group mb-4">
                      <label
                        htmlFor="description"
                        className="d-block fs-sm fw-semibold mb-2"
                      >
                        {t("Summary")}
                      </label>
                      <textarea
                        name="description"
                        id="description"
                        cols="30"
                        rows="5"
                        className="form-control fs-sm shadow-none"
                        placeholder={t("Enter Summary")}
                        value={summary}
                        onChange={(e) => setsummary(e.target.value)}
                      ></textarea>
                    </div>

                    {/* description */}
                    <div className="form-group mb-4">
                      <label
                        htmlFor="description"
                        className="d-block fs-sm fw-semibold mb-2"
                      >
                        {t("Description")}
                      </label>
                      <CKEditor
                        className="form-control border-0 shadow-none"
                        editor={ClassicEditor}
                        config={editorConfig}
                        data={details ? details : ""}
                        onReady={(editor) => {
                          editor.editing.view.change((writer) => {
                            writer.setStyle(
                              "height",
                              "200px",
                              editor.editing.view.document.getRoot()
                            );
                          });
                        }}
                        onChange={(event, editor) => {
                          let editorContent = editor.getData();
                          setdetails(editorContent);
                        }}
                      />
                    </div>

                    {/* dates */}
                    <div className="form-group mb-4">
                      <div className="row">
                        <div className="col-md-6 mb-4 mb-md-0">
                          <label
                            htmlFor="startDate"
                            className="d-block fs-sm fw-semibold mb-2"
                          >
                            {t("Start Date")}
                          </label>
                          <input
                            type="date"
                            id="startDate"
                            className="form-control fs-sm shadow-none"
                            value={startDate}
                            onChange={(e) => setstartDate(e.target.value)}
                          />
                        </div>
                        <div className="col-md-6">
                          <label
                            htmlFor="endDate"
                            className="d-block fs-sm fw-semibold mb-2"
                          >
                            {t("End Date")}
                          </label>
                          <input
                            type="date"
                            id="endDate"
                            className="form-control fs-sm shadow-none"
                            value={endDate}
                            onChange={(e) => setendDate(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>

                    {/* ====== documents section ======== */}
                    <div className="form-group mb-3 mb-sm-4">
                      <label
                        htmlFor="documents"
                        className="d-block fs-sm fw-semibold mb-2"
                      >
                        {t("Upload Documents")}
                      </label>

                      <ul className="upload-files d-flex flex-wrap gap-3 fs-sm mb-3">
                        <li className="file d-flex flex-column align-items-center justify-content-center p-3 border border-dashed rounded-8">
                          <Link
                            to="#"
                            data-target="upload"
                            className="upload-file text-center"
                            onClick={openDocFileInput}
                          >
                            <span className="d-block material-symbols-outlined">
                              upload
                            </span>
                            <span className="d-block fw-semibold">
                              {t("Upload")}
                            </span>
                          </Link>
                          <span className="fs-xs">Max 20mb doc/img/mp4</span>
                          <input
                            type="file"
                            data-id="upload"
                            id="uploadedProjectDoc"
                            className="d-none"
                            ref={docFileInputRef}
                            onChange={docFileUploadHandler}
                            multiple
                          />
                        </li>
                      </ul>

                      {docFileUploading ? (
                        <div className="d-flex align-items-center mt-3">
                          <p className="mb-0 me-1">{t("Uploading Files")}</p>
                          {loadingCircle.map((circle) => {
                            return (
                              <div
                                key={circle}
                                className="spinner-grow mx-1"
                                style={{ height: "6px", width: "6px" }}
                                role="status"
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        <ul className="uploaded-files d-flex flex-wrap gap-3 p-0 m-0 mt-3">
                          {uploadedDocFiles.map((file, index) => {
                            return (
                              <li
                                className="file d-flex gap-3 align-items-center px-3 py-2 border border-gray rounded-3"
                                key={index}
                              >
                                <Link
                                  to="#"
                                  onClick={() => downloadFileHandler(file)}
                                >
                                  <span className="d-block material-symbols-outlined">
                                    download
                                  </span>
                                </Link>
                                <p className="fw-medium">{file.name}</p>
                                <Link
                                  to="#"
                                  onClick={() => closeDocFileHandler(index)}
                                >
                                  <span className="d-block material-symbols-outlined">
                                    close
                                  </span>
                                </Link>
                              </li>
                            );
                          })}
                        </ul>
                      )}
                    </div>

                    <div className="action d-flex flex-wrap align-items-center justify-content-between gap-2">
                      <Link
                        to="/admin/projectmanagement/list"
                        className="btn btn-outline-primary"
                      >
                        {t("Cancel")}
                      </Link>
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={saveProjectHandler}
                        style={{ cursor: isSaving ? "not-allowed" : "pointer" }}
                        disabled={isSaving ? true : false}
                      >
                        {t("Save Now")}
                        {isSaving && (
                          <div
                            className="mx-2 spinner-border spinner-border-sm"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        )}
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="create-lead-container p-3 p-md-4 border border-gray-300 rounded-10 shadow-sm">
                    {/* title */}
                    <div
                      className={`details_info_heading d-flex align-items-center justify-content-between ${
                        projectMemberStatus === "1" ? "mb-4" : ""
                      }`}
                    >
                      <h2 className="fw-bold mb-0 text-black">{title}</h2>
                      {projectMemberStatus === "1" ? null : (
                        <button
                          onClick={acceptMemberInviteHandler}
                          className="btn btn-primary"
                          disabled={isAccepting ? true : false}
                          style={{
                            cursor: isAccepting ? "not-allowed" : "pointer",
                          }}
                        >
                          {t("Accept Invite")}
                          {isAccepting && (
                            <div
                              className="mx-2 spinner-border spinner-border-sm"
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          )}
                        </button>
                      )}
                    </div>

                    {projectMemberStatus === "1" ? null : (
                      <div className="d-flex justify-content-end mt-1 mb-4">
                        <p className="fs-md mb-0 text-black">
                          {t("Invitation sent on")} - {inviteDate}
                        </p>
                      </div>
                    )}

                    {/* summary */}
                    <div className="descriptiion_bx mb-4">
                      <h4 className="fs-sm mb-2 fw-semibold">{t("Summary")}</h4>
                      <div className="details_info_paragraph">
                        <p className="mb-4 text-black fs-md">{summary}</p>
                      </div>
                    </div>

                    {/* description */}
                    <div className="descriptiion_bx mb-4">
                      <h4 className="fs-sm mb-2 fw-semibold">
                        {t("Description")}
                      </h4>
                      <div className="details_info_paragraph">
                        <p
                          className="mb-4 text-black fs-md"
                          dangerouslySetInnerHTML={{ __html: details }}
                        ></p>
                      </div>
                    </div>

                    {/* dates */}
                    <div className="descriptiion_bx mb-4">
                      <div className="info_date_outer d-flex flex-wrap gap-2 mt-4">
                        <div className="info_date_bx body-bg rounded-10 px-3 py-2">
                          <label className="mb-1 fs-sm text-black fw-regular">
                            {t("Start Date")}
                          </label>
                          <h5 className="mb-0 text-black fs-sm fw-semibold">
                            {getDateFormatInSpanish(startDate)}
                          </h5>
                        </div>
                        <div className="info_date_bx body-bg rounded-10 px-3 py-2">
                          <label className="mb-1 fs-sm text-black fw-regular">
                            {t("End Date")}
                          </label>
                          <h5 className="mb-0 text-black fs-sm fw-semibold">
                            {getDateFormatInSpanish(endDate)}
                          </h5>
                        </div>
                      </div>
                    </div>

                    {/* documents */}

                    <div className="documents">
                      <h4 className="fs-sm mb-2 fw-semibold">
                        {t("Documents")}
                      </h4>
                      <ul className="uploaded-files d-flex flex-wrap gap-3 p-0 m-0 mt-3">
                        {uploadedDocFiles.map((file, index) => {
                          return (
                            <li
                              className="file d-flex gap-3 align-items-center px-3 py-2 border border-gray rounded-3"
                              key={index}
                            >
                              {/* <Link to="#" onClick={() => downloadFileHandler(file)}>
                          <span className="d-block material-symbols-outlined">
                            download
                          </span>
                        </Link> */}
                              <p className="fw-medium">{file.name}</p>
                              {/* <Link to="#" onClick={() => closeDocFileHandler(index)}>
                          <span className="d-block material-symbols-outlined">
                            close
                          </span>
                        </Link> */}
                              <Link
                                to="#"
                                onClick={() => downloadFileHandler(file)}
                              >
                                <span className="d-block material-symbols-outlined">
                                  download
                                </span>
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                      {/* <div className="doc_outer d-flex align-items-center gap-2 flex-wrap">
                  <div className="doc_item body-bg px-3 py-3 d-flex align-items-center justify-content-between gap-3 rounded-10 mb-2">
                    <div className="pdf_info d-flex align-items-center gap-2">
                      <i className="d-inline-block">
                        <img src="assets/img/pdf-icon.svg" alt="" />
                      </i>
                      <div className="pdf_text">
                        <h5 className="text-black fs-sm mb-0">MyResume.pdf</h5>
                        <h6 className="mb-0 text-gray fs-xs">1.5mb</h6>
                      </div>
                    </div>
                    <div className="pdf_dwnload">
                      <a href="javascript:void(0);">
                        <span className="material-symbols-outlined text-danger">
                          download
                        </span>
                      </a>
                    </div>
                  </div>
                  <div className="doc_item body-bg px-3 py-3 d-flex align-items-center justify-content-between gap-3 rounded-10 mb-2">
                    <div className="pdf_info d-flex align-items-center gap-2">
                      <i className="d-inline-block">
                        <img src="assets/img/pdf-icon.svg" alt="" />
                      </i>
                      <div className="pdf_text">
                        <h5 className="text-black fs-sm mb-0">MyResume.pdf</h5>
                        <h6 className="mb-0 text-gray fs-xs">1.5mb</h6>
                      </div>
                    </div>
                    <div className="pdf_dwnload">
                      <a href="javascript:void(0);">
                        <span className="material-symbols-outlined text-danger">
                          download
                        </span>
                      </a>
                    </div>
                  </div>
                  <div className="doc_item body-bg px-3 py-3 d-flex align-items-center justify-content-between gap-3 rounded-10 mb-2">
                    <div className="pdf_info d-flex align-items-center gap-2">
                      <i className="d-inline-block">
                        <img src="assets/img/pdf-icon.svg" alt="" />
                      </i>
                      <div className="pdf_text">
                        <h5 className="text-black fs-sm mb-0">MyResume.pdf</h5>
                        <h6 className="mb-0 text-gray fs-xs">1.5mb</h6>
                      </div>
                    </div>
                    <div className="pdf_dwnload">
                      <a href="javascript:void(0);">
                        <span className="material-symbols-outlined text-danger">
                          download
                        </span>
                      </a>
                    </div>
                  </div>
                </div> */}
                    </div>
                  </div>
                )
              ) : (
                <div className="create-lead-container p-3 p-md-4 border border-gray-300 rounded-10 shadow-sm">
                  {/* title */}
                  <div className="form-group mb-4">
                    <label
                      htmlFor="projectName"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      {t("Project Name")}
                    </label>
                    <input
                      type="text"
                      id="projectName"
                      className="form-control fs-sm shadow-none"
                      placeholder={t("Name")}
                      value={title}
                      onChange={(e) => settitle(e.target.value)}
                    />
                  </div>

                  {/* summary */}
                  <div className="form-group mb-4">
                    <label
                      htmlFor="description"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      {t("Summary")}
                    </label>
                    <textarea
                      name="description"
                      id="description"
                      cols="30"
                      rows="5"
                      className="form-control fs-sm shadow-none"
                      placeholder={t("Enter Summary")}
                      value={summary}
                      onChange={(e) => setsummary(e.target.value)}
                    ></textarea>
                  </div>

                  {/* description */}
                  <div className="form-group mb-4">
                    <label
                      htmlFor="description"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      {t("Description")}
                    </label>
                    <CKEditor
                      className="form-control border-0 shadow-none"
                      editor={ClassicEditor}
                      config={editorConfig}
                      data={details ? details : ""}
                      onReady={(editor) => {
                        editor.editing.view.change((writer) => {
                          writer.setStyle(
                            "height",
                            "200px",
                            editor.editing.view.document.getRoot()
                          );
                        });
                      }}
                      onChange={(event, editor) => {
                        let editorContent = editor.getData();
                        setdetails(editorContent);
                      }}
                    />
                  </div>

                  {/* dates */}
                  <div className="form-group mb-4">
                    <div className="row">
                      <div className="col-md-6 mb-4 mb-md-0">
                        <label
                          htmlFor="startDate"
                          className="d-block fs-sm fw-semibold mb-2"
                        >
                          {t("Start Date")}
                        </label>
                        <input
                          type="date"
                          id="startDate"
                          className="form-control fs-sm shadow-none"
                          value={startDate}
                          onChange={(e) => setstartDate(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6">
                        <label
                          htmlFor="endDate"
                          className="d-block fs-sm fw-semibold mb-2"
                        >
                          {t("End Date")}
                        </label>
                        <input
                          type="date"
                          id="endDate"
                          className="form-control fs-sm shadow-none"
                          value={endDate}
                          onChange={(e) => setendDate(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>

                  {/* ====== documents section ======== */}
                  <div className="form-group mb-3 mb-sm-4">
                    <label
                      htmlFor="documents"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      {t("Upload Documents")}
                    </label>

                    <ul className="upload-files d-flex flex-wrap gap-3 fs-sm mb-3">
                      <li className="file d-flex flex-column align-items-center justify-content-center p-3 border border-dashed rounded-8">
                        <Link
                          to="#"
                          data-target="upload"
                          className="upload-file text-center"
                          onClick={openDocFileInput}
                        >
                          <span className="d-block material-symbols-outlined">
                            upload
                          </span>
                          <span className="d-block fw-semibold">
                            {t("Upload")}
                          </span>
                        </Link>
                        <span className="fs-xs">Max 20mb doc/img/mp4</span>
                        <input
                          type="file"
                          data-id="upload"
                          id="uploadedProjectDoc"
                          className="d-none"
                          ref={docFileInputRef}
                          onChange={docFileUploadHandler}
                          multiple
                        />
                      </li>
                    </ul>

                    {docFileUploading ? (
                      <div className="d-flex align-items-center mt-3">
                        <p className="mb-0 me-1">{t("Uploading Files")}</p>
                        {loadingCircle.map((circle) => {
                          return (
                            <div
                              key={circle}
                              className="spinner-grow mx-1"
                              style={{ height: "6px", width: "6px" }}
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <ul className="uploaded-files d-flex flex-wrap gap-3 p-0 m-0 mt-3">
                        {uploadedDocFiles.map((file, index) => {
                          return (
                            <li
                              className="file d-flex gap-3 align-items-center px-3 py-2 border border-gray rounded-3"
                              key={index}
                            >
                              <Link
                                to="#"
                                onClick={() => downloadFileHandler(file)}
                              >
                                <span className="d-block material-symbols-outlined">
                                  download
                                </span>
                              </Link>
                              <p className="fw-medium">{file.name}</p>
                              <Link
                                to="#"
                                onClick={() => closeDocFileHandler(index)}
                              >
                                <span className="d-block material-symbols-outlined">
                                  close
                                </span>
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </div>

                  <div className="action d-flex flex-wrap align-items-center justify-content-between gap-2">
                    <Link
                      to="/admin/projectmanagement/list"
                      className="btn btn-outline-primary"
                    >
                      {t("Cancel")}
                    </Link>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={saveProjectHandler}
                      style={{ cursor: isSaving ? "not-allowed" : "pointer" }}
                      disabled={isSaving ? true : false}
                    >
                      {t("Save Now")}
                      {isSaving && (
                        <div
                          className="mx-2 spinner-border spinner-border-sm"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      )}
                    </button>
                  </div>
                </div>
              )}
            </div>
          )}
          <AfterSaveProjectModal afterSaveModalClose={afterSaveModalClose} />

          {showAlert && (
            <AlertNotification
              showAlert={showAlert}
              message={alertMessage}
              alertType={messageType}
              onClose={onAlertClose}
            />
          )}
        </section>
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {" "}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};

export default ProjectDetailBody;
