import BreadCrumb from "components/Common/BreadCrumb/BreadCrumb";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const SurveyInviteHeader = ({
  reloadList = () => {},
  name,
  copyUrlHandler = () => {},
}) => {
  const { t } = useTranslation(); //for translation
  /* ---- bread crumb text ---- */
  const breadcrumbText = [
    { title: t("Survey"), link: "/admin/survey/list" },
    { title: `${t("Invite")} / ${name}` },
  ];

  return (
    <div className="filter-container py-3">
      <div className="row align-items-center">
        <div className="col-md-5 col-lg-6 d-none d-md-block mb-3 mb-md-0">
          {/* --- bread crumb start --- */}
          <BreadCrumb breadCrumbText={breadcrumbText} />
          {/* --- bread crumb end --- */}
        </div>
        <div className="col-md-7 col-lg-6 d-flex justify-content-end gap-1 gap-sm-2">
          <Link
            onClick={reloadList}
            to="#"
            className="btn btn-gray d-flex align-items-center"
          >
            <span className="d-block material-symbols-outlined icon-md">
              refresh
            </span>
          </Link>
          <button
            className="btn btn-gray d-flex align-items-center gap-2"
            onClick={copyUrlHandler}
          >
            <span className="d-block">{t("Copy Url")}</span>
            <span className="d-block material-symbols-outlined icon-md">
              content_copy
            </span>
          </button>
          <div className="dropdown flex-fill flex-grow-sm-0">
            <button
              className="btn btn-gray text-start w-100 dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {t("Action")}
            </button>
            <ul className="dropdown-menu w-100 bg-white fs-sm fw-semibold border-0 rounded-10 shadow-sm">
              <li>
                <Link
                  to="#"
                  className="dropdown-item d-flex align-items-center gap-2"
                  data-bs-toggle="modal"
                  data-bs-target="#addLabelModal"
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    label
                  </span>
                  <span className="d-block">{t("Add Label")}</span>
                </Link>
              </li>
              {/* <li>
                <Link
                  to="#"
                  className="dropdown-item d-flex align-items-center gap-2"
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    delete
                  </span>
                  <span className="d-block">{t("Delete")}</span>
                </Link>
              </li> */}
            </ul>
          </div>
          <Link
            to="#"
            className="btn btn-gray d-flex align-items-center gap-1"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasInviteFilter"
            aria-controls="offcanvasInviteFilter"
          >
            <span className="d-block material-symbols-outlined icon-md">
              tune
            </span>
            <span className="d-block">{t("Filter")}</span>
          </Link>

          {/* --- add new members start --- */}
          <Link
            to="#"
            className="btn btn-primary d-flex align-items-center gap-1"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasSurveyInvite"
          >
            <span className="d-block material-symbols-outlined icon-md">
              add
            </span>
            <span className="d-block">{t("Invite Users")}</span>
          </Link>
          {/* --- add new members end --- */}
        </div>
      </div>
    </div>
  );
};

export default SurveyInviteHeader;
