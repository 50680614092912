/* eslint-disable */
import { useState } from "react";
import { useParams } from "react-router-dom";
// import use translation ------------------------------
import { useTranslation } from "react-i18next";

/*import url and gateway methods */
import { getData, postData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

import SurveyComponentDetailsCard from "components/Common/SurveyComponentDetailsCard/SurveyComponentDetailsCard";

const SurveyFeedbackModal = ({
  componentDetails = {},
  afterSaveSurvey = () => {},
}) => {
  const { t } = useTranslation(); // translation marker
  const params = useParams();

  const [name, setname] = useState("");
  const [surname, setsurname] = useState("");
  const [email, setemail] = useState("");
  const [mobile, setmobile] = useState("");

  //** Hooks for validation */
  const [validation, setValidation] = useState({
    nameWarning: false,
    surnameWarning: false,
    emailWarning: false,
  });

  const [isSaving, setisSaving] = useState(false);

  //** Validate function */
  const validationHandler = () => {
    let isValid = true;

    if (name === "") {
      setValidation((prevState) => ({ ...prevState, nameWarning: true }));
      isValid = false;
    }

    if (surname === "") {
      setValidation((prevState) => ({ ...prevState, surnameWarning: true }));
      isValid = false;
    }

    const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (email === "" || !email.match(mailformat)) {
      setValidation((prevState) => ({ ...prevState, emailWarning: true }));
      isValid = false;
    }

    return isValid;
  };

  //function for save survey
  const saveSurveyHandler = async () => {
    if (validationHandler()) {
      try {
        setisSaving(true);

        let surveyData = {
          name,
          surname,
          email,
          mobile,
          surveyid: params.id,
        };

        let requestUrl = url.API_BASE_URL + url.API_SAVE_SURVEY_FEEDBACK;

        const response = await postData(requestUrl, surveyData);

        setisSaving(false);

        console.log(response);

        if (response.status) {
          resetHandler();
          let bootstrapModal = document.querySelector("#login_event_modal");
          let modal = bootstrap.Modal.getInstance(bootstrapModal);
          modal.hide();
          afterSaveSurvey(response.data);
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  //function for reset all states
  const resetHandler = () => {
    setname("");
    setsurname("");
    setemail("");
    setmobile("");

    setValidation({
      nameWarning: false,
      surnameWarning: false,
      emailWarning: false,
    });
  };

  return (
    <div className="process_modal feededit_modal">
      <div className="modal fade" id="login_event_modal">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header p-4 border-0">
              <div className="signin_hdng text-center">
                <h5 className="mt-0">Reviewing for</h5>
              </div>
              <button
                type="button"
                className="close rounded-circle body-bg d-flex align-items-center justify-content-center text-black"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={resetHandler}
              >
                <i className="material-symbols-outlined">close </i>
              </button>
            </div>
            <div className="modal-body p-4 pt-0">
              <SurveyComponentDetailsCard
                componentDetails={componentDetails}
                fromModal={true}
              />

              {/* <div className="pavon_event_card d-flex align-items-center bg-white rounded-10 overflow-hidden p-3  mb-3 ">
                <div className="pavon-event-pic">
                  <img src="assets/img/pavon-event.jpg" alt="" />
                </div>
                <div className="pavon-event-text px-3">
                  <h3 className="text-black fs-lg fw-medium mb-2">
                    Roca Negra
                  </h3>
                  <ul className="list-style-none">
                    <li className="d-flex gap-1 mb-1 text-gray fs-md align-items-center">
                      <i className="material-symbols-outlined fs-lg">
                        calendar_month
                      </i>
                      <span>From February 28 to March 3, 2024</span>
                    </li>
                    <li className="d-flex gap-1 mb-1 text-gray fs-md align-items-center">
                      <i className="material-symbols-outlined fs-lg">place</i>
                      <span>Madrid Hall, Spain</span>
                    </li>
                  </ul>
                  <p>
                    Dramaturgy and direction: Ignasi Vidal, Cast: Juanjo
                    Puigcorbé and{" "}
                  </p>
                </div>
              </div> */}

              <form onSubmit={(e) => e.preventDefault()}>
                <div className="form_wrapper">
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group mb-3">
                        <input
                          type="text"
                          className="form-control"
                          value={name}
                          onChange={(e) => {
                            setname(e.target.value);
                            setValidation((prevState) => ({
                              ...prevState,
                              nameWarning: false,
                            }));
                          }}
                          placeholder="* First Name"
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group mb-3">
                        <input
                          type="text"
                          className="form-control"
                          value={surname}
                          onChange={(e) => {
                            setsurname(e.target.value);
                            setValidation((prevState) => ({
                              ...prevState,
                              surnameWarning: false,
                            }));
                          }}
                          placeholder="* Last Name"
                        />
                      </div>
                    </div>

                    {validation.nameWarning && (
                      <div className="col-sm-6">
                        <div className="error-message mb-3">
                          <p className="d-flex align-items-center gap-1 text-danger">
                            <span className="material-symbols-outlined">
                              warning
                            </span>
                            <span>{t("Please enter first name")}!</span>
                          </p>
                        </div>
                      </div>
                    )}

                    {validation.surnameWarning && (
                      <div className="col-sm-6">
                        <div className="error-message mb-3">
                          <p className="d-flex align-items-center gap-1 text-danger">
                            <span className="material-symbols-outlined">
                              warning
                            </span>
                            <span>{t("Please enter last name")}!</span>
                          </p>
                        </div>{" "}
                      </div>
                    )}

                    <div className="col-sm-12">
                      <div className="form-group mb-3">
                        <input
                          type="email"
                          className="form-control"
                          value={email}
                          onChange={(e) => {
                            setemail(e.target.value);
                            setValidation((prevState) => ({
                              ...prevState,
                              emailWarning: false,
                            }));
                          }}
                          placeholder="* Your Email"
                        />
                        {validation.emailWarning && (
                          <div className="error-message mt-2">
                            <p className="d-flex align-items-center gap-1 text-danger">
                              <span className="material-symbols-outlined">
                                warning
                              </span>
                              <span>{t("Please enter email")}!</span>
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="form-group mb-3">
                        <input
                          type="number"
                          className="form-control"
                          value={mobile}
                          onChange={(e) => {
                            setmobile(e.target.value);
                            setValidation((prevState) => ({
                              ...prevState,
                              mobileWarning: false,
                            }));
                          }}
                          placeholder="Mobile Number"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="process_btns_outer d-flex align-items-center justify-content-between mt-3">
                  <button
                    data-bs-dismiss="modal"
                    className="btn btn-gray"
                    onClick={resetHandler}
                  >
                    Back
                  </button>
                  <button
                    className="btn btn-primary"
                    onClick={saveSurveyHandler}
                    disabled={isSaving ? true : false}
                    style={{
                      cursor: isSaving ? "not-allowed" : "pointer",
                    }}
                  >
                    Save
                    {isSaving && (
                      <div
                        className="mx-2 spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SurveyFeedbackModal;
