import React from "react";
import { Link } from "react-router-dom";

import * as url from "helper/UrlHelper";
import { assetImages } from "constants";

const UserSurveyHeader = ({
  ecosystemLogo = "",
  surveyInviteeName = "",
  surveyInviterImage = "",
}) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  return (
    <header id="header" className="pavon_header">
      <nav className="navbar navbar-expand-lg bg-white shadow-sm">
        <div className="container-fluid px-lg-5">
          {userInfo ? (
            <Link className="navbar-brand p-0 me-3 me-md-4 me-lg-5" to="#">
              {userInfo?.companylogopath === "" ? (
                ecosystemLogo === "" ? (
                  <>
                    <img
                      height="30"
                      width="150"
                      src={assetImages.blankLogo}
                      alt="pavon"
                      className="img-fluid d-none d-md-block"
                    />

                    <img
                      height="30"
                      width="150"
                      src={assetImages.blankLogo}
                      alt="pavon"
                      className="img-fluid d-block d-md-none"
                    />
                  </>
                ) : (
                  <>
                    <img
                      height="30"
                      width="150"
                      src={url.SERVER_URL + ecosystemLogo}
                      alt="pavon"
                      className="img-fluid d-none d-md-block"
                    />

                    <img
                      height="30"
                      width="150"
                      src={url.SERVER_URL + ecosystemLogo}
                      alt="pavon"
                      className="img-fluid d-block d-md-none"
                    />
                  </>
                )
              ) : (
                <>
                  <img
                    height="30"
                    width="150"
                    src={url.SERVER_URL + userInfo.companylogopath}
                    alt="pavon"
                    className="img-fluid d-none d-md-block"
                  />

                  <img
                    height="30"
                    width="150"
                    src={url.SERVER_URL + userInfo.companylogopath}
                    alt="pavon"
                    className="img-fluid d-block d-md-none"
                  />
                </>
              )}
            </Link>
          ) : (
            <Link className="navbar-brand p-0 me-3 me-md-4 me-lg-5" to="#">
              <img
                height="30"
                width="150"
                src={
                  ecosystemLogo === ""
                    ? assetImages.blankLogo
                    : url.SERVER_URL + ecosystemLogo
                }
                alt="pavon"
                className="img-fluid d-none d-md-block"
              />

              <img
                height="30"
                width="150"
                src={
                  ecosystemLogo === ""
                    ? assetImages.blankLogo
                    : url.SERVER_URL + ecosystemLogo
                }
                alt="pavon"
                className="img-fluid d-block d-md-none"
              />
            </Link>
          )}
          <ul className="navbar-nav flex-row align-items-center gap-3 ms-auto">
            <li className="nav-item dropdown">
              <Link
                className="nav-link dropdown-toggle p-0 h4 fw-semibold d-flex align-items-center gap-2 mb-0"
                to="#"
              >
                <div className="d-flex gap-1">
                  <span>Hello</span>
                  {userInfo ? (
                    <span className="name text-black">
                      {userInfo.name} {userInfo.surname}
                    </span>
                  ) : (
                    <span className="name text-black">{surveyInviteeName}</span>
                  )}
                </div>
                <div className="avatar rounded-circle overflow-hidden">
                  {userInfo ? (
                    <img
                      src={
                        userInfo?.photoimage
                          ? url.SERVER_URL + userInfo?.photoimage?.path
                          : assetImages?.defaultUser
                      }
                      alt="Guest"
                      className="w-100 h-100 object-fit-cover object-center"
                    />
                  ) : (
                    <img
                      src={
                        surveyInviterImage === ""
                          ? assetImages?.defaultUser
                          : url.SERVER_URL + surveyInviterImage
                      }
                      alt="Guest"
                      className="w-100 h-100 object-fit-cover object-center"
                    />
                  )}
                </div>
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default UserSurveyHeader;
