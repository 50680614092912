import React from "react";
import { Link } from "react-router-dom";

const ProjectManagementCommonHeader = ({ componentName = "" }) => {
  return (
    <div className="inner-menu-container bg-white shadow-sm">
      <div className="container-fluid px-lg-5">
        <ul className="nav secondary-menu mw-100 flex-nowrap gap-xl-5 flex-fill fs-sm fw-semibold text-center border-0 text-nowrap overflow-auto">
          <li className="nav-item flex-fill">
            <Link
              to="/admin/projectmanagement/dashboard"
              className={`d-block text-black px-2 px-sm-3 py-3 py-sm-4 position-relative ${
                componentName === "dashboard" ? "active" : ""
              } `}
            >
              Dashboard
            </Link>
          </li>
          <li className="nav-item flex-fill">
            <Link
              to="/admin/projectmanagement/list"
              className={`d-block text-black px-2 px-sm-3 py-3 py-sm-4 position-relative ${
                componentName === "projectlist" ? "active" : ""
              } `}
            >
              Project List
            </Link>
          </li>
          <li className="nav-item flex-fill">
            <Link
              to="/admin/projectmanagement/fullreport/graph"
              className={`d-block text-black px-2 px-sm-3 py-3 py-sm-4 position-relative ${
                componentName === "fullreport" ? "active" : ""
              } `}
            >
              Full Report
            </Link>
          </li>
          <li className="nav-item flex-fill">
            <Link
              to="/admin/projectmanagement/allmembers"
              className={`d-block text-black px-2 px-sm-3 py-3 py-sm-4 position-relative ${
                componentName === "allmembers" ? "active" : ""
              } `}
            >
              Members
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ProjectManagementCommonHeader;
