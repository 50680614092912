import Header from "components/Common/Header/Header";
import SurveyInviteBody from "components/SurveyComponents/SurveyInvite/SurveyInviteBody/SurveyInviteBody";

const SurveyInvite = () => {
  return (
    <main id="app">
      <Header moduleName="survey" />

      <SurveyInviteBody moduleName="invite" />
    </main>
  );
};

export default SurveyInvite;
