/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import { useTranslation } from "react-i18next";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData } from "utils/Gateway";

import { assetImages } from "constants";
import downloadFileHandler from "helper/DownloadFile";

const CrmLeadInformationLeftPanel = ({
  setleadName,
  setleadImage,
  setisValidUser,
  setShowAlert,
  setAlertMessage,
  setMessageType,
}) => {
  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();

  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const params = useParams();

  const [sourceName, setSourceName] = useState("");
  const [sourceUrl, setsourceUrl] = useState("");
  const [firstName, setfirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [emailAddress, setemailAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [company, setcompany] = useState("");
  const [position, setposition] = useState("");
  const [resumeTitle, setresumeTitle] = useState("");
  const [resumeSummary, setresumeSummary] = useState("");
  const [resumeDetails, setresumeDetails] = useState("");
  const [minSalary, setminSalary] = useState("");
  const [maxSalary, setmaxSalary] = useState("");
  const [paymentDuration, setpaymentDuration] = useState("");
  const [educations, setEducations] = useState([]);
  const [experiences, setExperiences] = useState([]);
  const [skillPoints, setSkillPoints] = useState([]);
  const [uploadedDocs, setUploadedDocs] = useState([]);
  const [uploadedVideos, setUploadedVideos] = useState([]);
  const [skills, setSkills] = useState([]);
  const [followers, setFollowers] = useState([]);
  const [customerMessage, setCustomerMessage] = useState("");

  const [isAuthenticatedUser, setisAuthenticatedUser] = useState(false);

  //function for get lead details
  const getLeadDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_LEAD_DETAILS +
        `/${params.id}?token=${token}`;

      console.log("url of lead details------>", requestUrl);

      const response = await getData(requestUrl);

      console.log("response of lead details------>", response);

      if (response.status) {
        if (
          response.data.moderator._id.toString() === userInfo._id.toString()
        ) {
          setisAuthenticatedUser(true);
          setisValidUser(true);
        } else {
          setisAuthenticatedUser(false);
          setisValidUser(false);
        }

        setSourceName(response.data.source);
        setsourceUrl(response.data.sourceurl);

        const respondedLeadName = response.data.name
          ? `${response.data.name ?? ""} ${response.data.surname ?? ""}`
          : `${response.data.email}`;

        setleadName(respondedLeadName);

        if (response.data?.photoimage) {
          setleadImage(response.data?.photoimage?.path);
        } else {
          setleadImage("");
        }

        setfirstName(response.data.name);
        setlastName(response.data.surname);
        setemailAddress(response.data.email);
        setPhoneNumber(response.data.phone);
        setFollowers(response.data.followerslist);

        if (response.data.dob) {
          if (response.data.dob.includes("-")) {
            let components = response.data.dob.split("-");
            let year = components[0];
            let month = components[1];
            let day = components[2];
            let newDate = day ?? "" + "-" + month ?? "" + "-" + year ?? "";
            setDateOfBirth(newDate);
          }
        } else {
          setDateOfBirth("");
        }

        setcompany(response.data.company);
        setposition(response.data.position);
        setCustomerMessage(response.data.customercomment);
        // response.data?.photoimage?.path
        //   ? setUploadedPreviewImage(response.data?.photoimage?.path)
        //   : setUploadedPreviewImage("");
        // response.data?.photoimage?._id
        //   ? setUploadedImageId(response.data?.photoimage?._id)
        //   : setUploadedImageId(null);
        setresumeTitle(response.data.resumetitle);
        setresumeSummary(response.data.resumesummary);
        setresumeDetails(response.data.resumedetails);
        setminSalary(response.data.minsalary);
        setmaxSalary(response.data.maxsalary);
        setpaymentDuration(response.data.paymentduration);
        setEducations(response.data.educationids);
        setExperiences(response.data.experienceids);
        setSkillPoints(response.data.skillpointids);
        setUploadedDocs(response.data.uploadeddocresume);
        setUploadedVideos(response.data.uploadedvideoresume);
        setSkills(response.data.skills);

        setMessageType("success");
      } else {
        setMessageType("error");
      }

      setAlertMessage(response.message);
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
    }

    setShowAlert(true);
  };

  useEffect(() => {
    if (params.id) {
      getLeadDetails();
    }
  }, [params.id]);

  return (
    <div className="p-4 border border-gray-300 rounded-10 shadow-sm">
      {/* ------- profile section start ------ */}
      <div className="profile-info p-4 border rounded-10 mb-4">
        <div className="row align-items-center">
          {/* ----- user basic section start ----- */}
          <div className="col-lg-8">
            <p className="name fw-bold mb-3">
              {firstName} {lastName}
            </p>
            <ul className="d-flex flex-wrap gap-3 fs-sm">
              <li>
                <Link to="#" className="d-flex align-items-center gap-1">
                  <span className="d-block material-symbols-outlined icon-sm">
                    email
                  </span>
                  <span className="d-block">{emailAddress}</span>
                </Link>
              </li>
              <li>
                <Link to="#" className="d-flex align-items-center gap-1">
                  <span className="d-block material-symbols-outlined icon-sm">
                    phone_in_talk
                  </span>
                  <span className="d-block">{phoneNumber}</span>
                </Link>
              </li>
              <li>
                <p className="d-flex align-items-center gap-1">
                  <span className="d-block material-symbols-outlined icon-sm">
                    cake
                  </span>
                  <span className="d-block">{dateOfBirth}</span>
                </p>
              </li>
              <li>
                <p className="d-flex align-items-center gap-1">
                  <span className="d-block material-symbols-outlined icon-sm">
                    business_center
                  </span>
                  <span className="d-block">
                    {position} <span className="text-gray">at</span> {company}
                  </span>
                </p>
              </li>
            </ul>
          </div>
          {/* ----- user basic section end ----- */}
          {/* ---- edit link section start ----- */}
          {userInfo.role.slug === "ADMIN" ||
          userInfo.role.slug === "SUPER_ADMIN" ||
          isAuthenticatedUser ? (
            <div className="col-lg-4 d-flex">
              <Link
                to={`/admin/crm/lead/save/${params.id}`}
                className="d-inline-flex align-items-center gap-2 px-4 btn btn-gray ms-auto"
              >
                <span className="d-block material-symbols-outlined icon-sm">
                  edit
                </span>
                <span className="d-block">{t("Edit")}</span>
              </Link>
            </div>
          ) : null}
          {/* ---- edit link section end ----- */}
        </div>
      </div>
      {/* ------- profile section end ------ */}
      {/* ----- source section start ----- */}
      <h3>{t("Source")}</h3>
      <p>{sourceUrl}</p>
      <p>{sourceName}</p>
      {/* ----- source section end ----- */}
      {/* ----- message section start ----- */}
      <h3>{t("Message")}</h3>
      <p>{customerMessage}</p>
      {/* ----- message section end ----- */}
      {/* ------- resume title section start ------ */}
      <h3>{t("Resume Title")}</h3>
      <p>{resumeTitle}</p>
      {/* ------- resume title section end ------ */}
      {/* ------- details section start ------ */}
      <h3>{t("Resume Summary")}</h3>
      <p>{resumeSummary}</p>
      {/* ------- details section end ------ */}
      {/* ------- company section start ------ */}
      <h3>{t("Resume Details")}</h3>
      <p dangerouslySetInnerHTML={{ __html: resumeDetails }}></p>
      {/* ------- company section end ------ */}
      {/* ------- pay rate section start ------ */}
      <h3>{t("Pay Rate")}</h3>
      <p>
        ${minSalary} - ${maxSalary} {paymentDuration}
      </p>
      {/* ------- pay rate section end ------ */}
      {/* ------- working hours section start ------ */}
      {/* <h3>Working Hours</h3>
      <p>48 hours (8:30am - 9:30pm)</p> */}
      {/* ------- working hours section end ------ */}
      {/* ------- skill points section start ------ */}
      <h3>{t("Skill Points")}</h3>
      {skillPoints.map((skillPoint, index) => {
        return (
          <p key={index}>
            {index + 1}. Skill name : {skillPoint?.skill?.name} , Point :{" "}
            {skillPoint?.points}
          </p>
        );
      })}
      {/* ------- skills points section end ------ */}
      {/* ------- education section start ------ */}
      <h3>{t("Education")}</h3>
      {educations.map((education, index) => {
        return (
          <p key={index}>
            {index + 1}. Completed {education?.degree} from{" "}
            {education?.institute} on {education?.year}
          </p>
        );
      })}
      {/* ------- education section end ------ */}
      {/* ------- experience section start ------ */}
      <h3>{t("Experience")}</h3>
      {experiences.map((experience, index) => {
        return (
          <p key={index}>
            {index + 1}. {experience?.position} at {experience?.company} <br />
            Working from {experience?.start} to {experience?.end}
          </p>
        );
      })}
      {/* ------- experience section end ------ */}
      {/* ------- skill section start ------ */}
      <h3>{t("Skills")}</h3>
      <ul className="tags d-flex dlex-wrap gap-2 fs-sm">
        {skills.map((skill, index) => {
          return (
            <li key={index} className="tag body-bg px-3 py-1 rounded-10">
              {skill.name}
            </li>
          );
        })}
      </ul>
      {/* ------- skill section end ------ */}
      {/* ------- attachments section start ------ */}
      <h3>{t("Attachments")}</h3>
      {/* ----- doc attachments section start ------ */}
      <ul className="documents d-flex flex-wrap gap-3 mb-4">
        {uploadedDocs.map((docFile, index) => {
          return (
            <li
              key={index}
              className="document d-flex align-items-center px-3 py-2 body-bg rounded-10"
            >
              <img
                src={
                  docFile.path.split(".").pop() == "pdf"
                    ? assetImages.PdfImg
                    : docFile.path.split(".").pop() == "xlsx"
                    ? assetImages.XlsImg
                    : assetImages.DocImg
                }
                alt={docFile.name}
                className="img-fluid me-2"
              />
              <span className="d-block fw-medium me-4">{docFile.name}</span>
              <Link to="#" onClick={() => downloadFileHandler(docFile)}>
                <span className="d-block material-symbols-outlined icon-lg">
                  download
                </span>
              </Link>
            </li>
          );
        })}
      </ul>
      {/* ----- doc attachments section end ------ */}
      {/* ----- video attachments section start ------ */}
      <div className="videos row">
        {uploadedVideos.map((video, index) => {
          return (
            <div className="col-md-6 col-lg-4" key={index}>
              <div className="video-container bg-white p-2 rounded-10 shadow-sm">
                <div
                  className="ratio ratio-16x9 rounded-8 bg-cover bg-center bg-norepeat"
                  // style={{
                  //   backgroundImage:
                  //     "url('assets/img/video-placeholder-1.jpg')",
                  // }}
                >
                  <video src={video.path} controls></video>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {/* ----- video attachments section end ------ */}
      {/* ------- attachments section end ------ */}
    </div>
  );
};
export default CrmLeadInformationLeftPanel;
