import { assetImages } from "constants";
import React from "react";

const ProjectGraph = () => {
  return (
    <div className="graph-container mb-4">
      <img
        src={assetImages.dashboardGraphImg}
        alt="Graph"
        className="img-fluid"
      />
    </div>
  );
};

export default ProjectGraph;
