import BreadCrumb from "components/Common/BreadCrumb/BreadCrumb";
import React from "react";
import { Link } from "react-router-dom";

const TicketsListHeader = ({ reloadList, changeStatusHandler }) => {
  const breadcrumbTxt = [{ title: "Tickets" }];
  // const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  return (
    <div className="filter-container py-3">
      <div className="row align-items-center">
        {/* ------- search section -------- */}
        <div className="col-md-5 col-lg-6 d-none d-md-block mb-3 mb-md-0">
          <BreadCrumb breadCrumbText={breadcrumbTxt} />
        </div>

        <div className="col-md-7 col-lg-6 d-flex justify-content-end gap-1 gap-sm-2">
          <Link
            onClick={reloadList}
            to="#"
            className="btn btn-gray d-flex align-items-center"
          >
            <span className="d-block material-symbols-outlined icon-md">
              refresh
            </span>
          </Link>

          <Link
            to="#"
            className="btn btn-gray d-flex align-items-center"
            data-bs-toggle="modal"
            data-bs-target="#importModal"
          >
            <span className="d-block material-symbols-outlined icon-md">
              upload
            </span>
            <span className="d-block">Import</span>
          </Link>

          <Link
            to="#"
            className="btn btn-gray d-flex align-items-center"
            data-bs-toggle="modal"
            data-bs-target="#csvUploadModal"
          >
            <span className="d-block material-symbols-outlined icon-md">
              upload
            </span>
            <span className="d-block">CSV Upload</span>
          </Link>

          {/* ------- action section ---------- */}
          <div className="dropdown flex-fill flex-grow-sm-0">
            {/* <button
              className="btn btn-gray text-start w-100 dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Action
            </button> */}

            <ul className="dropdown-menu w-100 bg-white fs-sm border-0 rounded-10 shadow-sm">
              {/* -------- add label -------- */}
              <li>
                <Link
                  to="#"
                  className="dropdown-item d-flex align-items-center gap-1"
                  data-bs-toggle="modal"
                  data-bs-target="#csvUploadModal"
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    label
                  </span>
                </Link>
              </li>

              {/* -------- delete ----------- */}
              <li>
                <Link
                  onClick={changeStatusHandler}
                  to="#"
                  className="dropdown-item d-flex align-items-center gap-1"
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    delete
                  </span>
                  <span className="d-block">Delete</span>
                </Link>
              </li>
            </ul>
          </div>

          {/* --------- filter ---------- */}
          <Link
            to="#"
            className="btn btn-gray d-flex align-items-center gap-1"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasTicketListFilter"
            aria-controls="offcanvasTicketListFilter"
          >
            <span className="d-block material-symbols-outlined icon-md">
              tune
            </span>
            <span className="d-block">Filter</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default TicketsListHeader;
