import React, { useEffect } from "react";
import Header from "components/Common/Header/Header";
import CrmNoteListBody from "components/CRMComponents/CrmNoteComponents/CrmNoteListBody";

const CrmNotes = () => {
  useEffect(() => {
    document.title = "CRM | Notes";
  }, []);

  return (
    <main id="app">
      {/* ---- header component ---- */}
      <Header moduleName="crm" />

      {/* --- body section --- */}
      <CrmNoteListBody />
    </main>
  );
};

export default CrmNotes;
