//function common header crm links
const crmCommonHeaderLinkLists = (t) => {
  // loggedin user details to get the role
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  // get the role and allow for admin or superadmin
  const currentUserRole = userInfo.role ? userInfo.role.slug : "USER";

  // get module access for roles other than admin and superadmin
  const moduleaccess = localStorage.getItem("moduleaccess");

  //console.log('currentUserRole', currentUserRole);

  const headerTabs = [];

  // lead
  if (
    currentUserRole === "ADMIN" ||
    currentUserRole === "SUPER_ADMIN" ||
    moduleaccess.includes("MOD_LEAD")
  ) {
    const leadTab = { title: t("Leads"), link: "/admin/crm/lead/list" };
    headerTabs.push(leadTab);
  }

  //ecoleads
  if (
    currentUserRole === "ADMIN" ||
    currentUserRole === "SUPER_ADMIN" ||
    moduleaccess.includes("MOD_ECOLEADS")
  ) {
    const ecoleadTab = {
      title: t("EcoLeads"),
      link: "/admin/crm/ecolead/list",
    };
    headerTabs.push(ecoleadTab);
  }

  // contact
  if (
    currentUserRole === "ADMIN" ||
    currentUserRole === "SUPER_ADMIN" ||
    moduleaccess.includes("MOD_CONTACT")
  ) {
    const contactTab = { title: t("Contact"), link: "/admin/crm/contact/list" };
    headerTabs.push(contactTab);
  }

  // conversation
  if (
    currentUserRole === "ADMIN" ||
    currentUserRole === "SUPER_ADMIN" ||
    moduleaccess.includes("MOD_CONVERSATION")
  ) {
    const conversationTab = {
      title: t("Conversation"),
      link: "/admin/crm/conversation/recieved",
    };
    headerTabs.push(conversationTab);
  }

  // list
  if (
    currentUserRole === "ADMIN" ||
    currentUserRole === "SUPER_ADMIN" ||
    moduleaccess.includes("MOD_LIST")
  ) {
    const listTab = { title: t("List"), link: "/admin/crm/list" };
    headerTabs.push(listTab);
  }

  // mail template
  if (
    currentUserRole === "ADMIN" ||
    currentUserRole === "SUPER_ADMIN" ||
    moduleaccess.includes("MOD_MAIL_TEMPLATE")
  ) {
    const templateTab = {
      title: t("Mail Templates"),
      link: "/admin/crm/mailtemplate/list",
    };
    headerTabs.push(templateTab);
  }

  // label
  if (
    currentUserRole === "ADMIN" ||
    currentUserRole === "SUPER_ADMIN" ||
    moduleaccess.includes("MOD_LABEL")
  ) {
    const labelTab = { title: t("Label"), link: "/admin/crm/label/list" };
    headerTabs.push(labelTab);
  }

  //process
  if (
    currentUserRole === "ADMIN" ||
    currentUserRole === "SUPER_ADMIN" ||
    moduleaccess.includes("MOD_PROCESS")
  ) {
    const processTab = {
      title: t("Activity Process"),
      link: "/admin/crm/process/list",
    };
    headerTabs.push(processTab);
  }

  // opportunities
  if (
    currentUserRole === "ADMIN" ||
    currentUserRole === "SUPER_ADMIN" ||
    moduleaccess.includes("MOD_OPPORTUNITY")
  ) {
    const opportunityTab = {
      title: t("Opportunities"),
      link: "/admin/crm/opportunity/list",
    };
    headerTabs.push(opportunityTab);
  }

  // notes
  const noteTab = {
    title: t("Notes"),
    link: "/admin/crm/note/list",
  };
  headerTabs.push(noteTab);

  return headerTabs;
};
export { crmCommonHeaderLinkLists };
