/* eslint-disable */
import { useEffect, useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";

/*import url and gateway methods */
import { getData, postData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

import SurveyFeedbackHeader from "../Header/SurveyFeedbackHeader";
import FeedbackLeftPanel from "../Panels/FeedbackLeftPanel";
import SurveyFeedbackModal from "../Modal/SurveyFeedbackModal";
import { assetImages } from "constants";

const SurveyFeedbackBody = () => {
  const params = useParams();
  const history = useHistory();

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const [name, setname] = useState("");
  const [code, setcode] = useState("");
  const [questionIds, setquestionIds] = useState([]);
  const [moduleName, setmoduleName] = useState("");
  const [moduleSlug, setmoduleSlug] = useState("");
  const [componentDetails, setComponentDetails] = useState({});
  const [moderatorName, setmoderatorName] = useState("");
  const [moderatorImage, setmoderatorImage] = useState("");
  const [ecosystemLogo, setecosystemLogo] = useState("");

  const [surveyError, setsurveyError] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");

  //function for get survey details
  const getSurveyDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_SURVEY_DETAILS + `/${params.id}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        seterrorMessage("");
        setname(response.data.name);
        setecosystemLogo(response.data.ecosystemlogo);
        setmoderatorName(response.data.moderatorname);
        setmoderatorImage(response.data.moderatorimage);
        setComponentDetails({
          componenttitle: response.data.componenttitle,
          componentimage: response.data.componentimage,
          componentsummary: response.data.componentsummary,
          componentlocation: response.data.componentlocation,
          componentdate: response.data.componentdate,
        });
      } else {
        setsurveyError(true);
        seterrorMessage(response.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for save
  const afterSaveSurvey = (surveyInviteId = "") => {
    seterrorMessage("");
    history.push(`/admin/survey/invite/${surveyInviteId}`);
  };

  useEffect(() => {
    if (params.id) {
      getSurveyDetails();
    }
  }, [params.id]);

  return (
    <>
      <SurveyFeedbackHeader ecosystemLogo={ecosystemLogo} />

      {surveyError ? (
        <div className="my_survey_outer d-flex align-items-center justify-content-center">
          <div className="survey_revw_innr">
            <div className="blank_graphics_info text-center">
              <div className="blank_graphics_pic mb-3">
                <img src={assetImages.leftBlank} alt="" />
              </div>
              <div className="blank_graphics_text">
                <h4 className="text-black fs-lg mb-3">Survey not available</h4>
                <p className="fs-xs text-black-gray">Reason : {errorMessage}</p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="my_survey_outer  d-flex flex-wrap">
          <FeedbackLeftPanel componentDetails={componentDetails} />

          <div className="right_survey bg-white px-5">
            <div className="overlay"></div>
            <div className="open_rvw_trigger bg-white p-3 d-flex justify-content-end">
              <Link
                className="d-inline-flex align-items-center gap-2 text-black fs-lg fw-semibold"
                to="#"
              >
                <i className="material-symbols-outlined fs-xl">filter_list</i>
                <span>Show Review</span>
              </Link>
            </div>
            <div className="right_rvw_inr d-flex align-items-center justify-content-center">
              <div className="medium_container">
                <div className="process_form_area">
                  <div className="process_hdng text-center">
                    <span className="d-block mb-3">
                      <img src="assets/img/hlw-wave.png" alt="" />
                    </span>
                    <h3>
                      Hello{" "}
                      {userInfo ? (
                        <span>
                          {userInfo.name} {userInfo.surname}
                        </span>
                      ) : (
                        <span>Guest</span>
                      )}
                    </h3>
                  </div>
                  <div className="survey_subheading text-center mb-4">
                    <h4 className="text-black fs-xl mb-3">
                      Start Survey on {name}
                    </h4>
                    <div className="kitchen_user_row d-flex align-items-center gap-3 justify-content-center">
                      <h6 className="fs-sm text-gray">Invited by</h6>
                      <div className="survey_user d-flex align-items-center gap-2 body-bg rounded-90 px-3 py-2 justify-content-center">
                        <span className="d-block survey_usr_pic overflow-hidden rounded-circle">
                          <img src={assetImages.defaultUser} alt="" />
                        </span>
                        <h5 className="fs-sm text-black mb-0">
                          {moderatorName}
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="text-center">
                    <Link
                      to="#"
                      data-bs-toggle="modal"
                      data-bs-target="#login_event_modal"
                      className="btn btn-primary"
                    >
                      Start Survey{" "}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <SurveyFeedbackModal
        componentDetails={componentDetails}
        afterSaveSurvey={afterSaveSurvey}
      />
    </>
  );
};

export default SurveyFeedbackBody;
