/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";
import BreadCrumb from "components/Common/BreadCrumb/BreadCrumb";
import { useTranslation } from "react-i18next";

const MyTaskListHeader = ({
  userInfo,
  updateBulkTaskHandler,
  setselectedTaskModerator = () => {},
  reloadList,
  updateScheduleStateHandler = () => {},
  downloadAsExcel = () => {},
}) => {
  /* ---- bread crumb text ---- */
  const breadcrumbText = [{ title: "My Task" }];

  const { t } = useTranslation();

  return (
    <div className="filter-container py-3">
      <div className="row align-items-center">
        <div className="col-md-5 col-lg-6 d-none d-md-block mb-3 mb-md-0">
          {/* --- breadcrumb start --- */}
          <BreadCrumb breadCrumbText={breadcrumbText} />
          {/* --- breadcrumb end --- */}
        </div>
        <div className="col-md-7 col-lg-6 d-flex justify-content-end gap-1 gap-sm-2">
          <Link
            onClick={reloadList}
            to="#"
            className="btn btn-gray d-flex align-items-center"
          >
            <span className="d-block material-symbols-outlined icon-md">
              refresh
            </span>
          </Link>
          <div className="dropdown flex-fill flex-grow-sm-0">
            <button
              className="btn btn-gray text-start w-100 dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {t("List View")}
            </button>
            <ul className="dropdown-menu w-100 bg-white fs-sm border-0 rounded-10 shadow-sm">
              <li>
                <Link
                  className="dropdown-item d-flex align-items-center gap-1"
                  to={`/admin/mytasklist`}
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    list
                  </span>
                  <span className="d-block">{t("List View")}</span>
                </Link>
              </li>
              <li>
                <Link
                  className="dropdown-item d-flex align-items-center gap-1"
                  to={`/admin/mytaskkanban`}
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    view_kanban
                  </span>
                  <span className="d-block">{t("Kanban View")}</span>
                </Link>
              </li>
              <li>
                <Link
                  className="dropdown-item d-flex align-items-center gap-1"
                  to={`/admin/mytaskgantt`}
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    insert_chart_outlined
                  </span>
                  <span className="d-block">{t("Gantt View")}</span>
                </Link>
              </li>
            </ul>
          </div>

          <div className="dropdown flex-fill flex-grow-sm-0">
            <button
              className="btn btn-gray text-start w-100 dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {t("Action")}
            </button>
            <ul className="dropdown-menu w-100 bg-white fs-sm border-0 rounded-10 shadow-sm">
              <li>
                <Link
                  className="dropdown-item d-flex align-items-center gap-1"
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#addFollowers"
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    person
                  </span>
                  <span className="d-block">{t("Add Followers")}</span>
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className="dropdown-item d-flex align-items-center gap-1"
                  data-bs-toggle="modal"
                  data-bs-target="#addLabelModal"
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    label
                  </span>
                  <span className="d-block">{t("Add Label")}</span>
                </Link>
              </li>
              <li>
                <Link
                  className="dropdown-item d-flex align-items-center gap-1"
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#createAlertModal"
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    alarm
                  </span>
                  <span className="d-block">{t("Create alert")}</span>
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className="dropdown-item d-flex align-items-center gap-1"
                  onClick={updateBulkTaskHandler}
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    progress_activity
                  </span>
                  <span className="d-block">{t("Update Task")}</span>
                </Link>
              </li>

              {/* <!-- ========== Start bulk task Section ========== --> */}
              <li>
                <Link
                  to="#"
                  className="dropdown-item d-flex align-items-center gap-1"
                  data-bs-toggle="modal"
                  data-bs-target="#addAssingTask"
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    task
                  </span>
                  <span className="d-block">{t("Assign Tasks")}</span>
                </Link>
              </li>
              {/* <!-- ========== End bulk task Section ========== --> */}

              {/* <!-- ========== Start massive delete task Section ========== --> */}
              <li>
                <Link
                  to="#"
                  className="dropdown-item d-flex align-items-center gap-1"
                  data-bs-toggle="modal"
                  data-bs-target="#projectTaskDeleteModal"
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    delete
                  </span>
                  <span className="d-block">{t("Delete Tasks")}</span>
                </Link>
              </li>
              {/* <!-- ========== End massive delete task Section ========== --> */}

              {/* <!-- ========== Start schedule update Section ========== --> */}
              <li>
                <Link
                  to="#"
                  className="dropdown-item d-flex align-items-center gap-1"
                  type="button"
                  // data-bs-toggle="offcanvas"
                  // data-bs-target="#UpdateTaskSchedule"
                  // aria-controls="UpdateTaskSchedule"
                  onClick={updateScheduleStateHandler}
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    schedule
                  </span>
                  <span className="d-block">{t("Update Schedule")}</span>
                </Link>
              </li>
              {/* <!-- ========== End schedule update Section ========== --> */}
              <li>
                <Link
                  className="dropdown-item d-flex align-items-center gap-1"
                  to="#"
                  onClick={downloadAsExcel}
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    download
                  </span>
                  <span className="d-block">{t("Download as Excel")}</span>
                </Link>
              </li>
            </ul>
          </div>
          <Link
            to="#"
            className="btn btn-gray d-flex align-items-center gap-1"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasTaskFilter"
            aria-controls="offcanvasTaskFilter"
          >
            <span className="d-block material-symbols-outlined icon-md">
              tune
            </span>
            <span className="d-block">{t("Filter")}</span>
          </Link>
          <Link
            to="#"
            className="btn btn-primary d-flex align-items-center gap-1"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#addTask_view"
            aria-controls="addTask_view"
            onClick={() => {
              setselectedTaskModerator(userInfo._id);
            }}
          >
            <span className="d-block material-symbols-outlined icon-md">
              add
            </span>
            <span className="d-block">{t("New Task")}</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MyTaskListHeader;
