import React from "react";

import * as url from "helper/UrlHelper";
import { assetImages } from "constants";

const SurveyComponentDetailsCard = ({
  componentDetails = {},
  moreGapInBottom = false,
}) => {
  return (
    <div
      className={`pavon_event_card d-flex align-items-center ${
        moreGapInBottom ? "bg-white rounded-10 overflow-hidden p-3  mb-3 " : ""
      }`}
    >
      <div className="pavon-event-pic">
        <img
          src={
            componentDetails.componentimage === ""
              ? assetImages.blankLogo
              : url.SERVER_URL + componentDetails.componentimage
          }
          alt=""
        />
      </div>
      <div className="pavon-event-text px-3">
        <h3 className="text-black fs-lg fw-medium mb-2">
          {componentDetails.componenttitle}
        </h3>
        <ul className="list-style-none">
          {componentDetails.componentdate &&
          componentDetails.componentdate !== "" ? (
            <li className="d-flex gap-1 mb-1 text-gray fs-md align-items-center">
              <i className="material-symbols-outlined fs-lg">calendar_month</i>
              <span>{componentDetails.componentdate}</span>
            </li>
          ) : null}
          <li className="d-flex gap-1 mb-1 text-gray fs-md align-items-center">
            <i className="material-symbols-outlined fs-lg">place</i>
            <span>{componentDetails.componentlocation}</span>
          </li>
        </ul>
        <p>{componentDetails.componentsummary}</p>
      </div>
    </div>
  );
};

export default SurveyComponentDetailsCard;
