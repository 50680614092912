/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { deleteData, getData, postData, putData } from "utils/Gateway";

const NoteSection = ({
  moduleName = "",
  leadId = null,
  contactId = null,
  invoiceId = null,
  taskId = null,
  setShowAlert,
  setAlertMessage,
  setMessageType,
}) => {
  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();

  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const params = useParams();

  const [noteTitle, setNoteTitle] = useState("");
  const [noteDetails, setNoteDetails] = useState("");
  const [isPrivate, setIsPrivate] = useState(false);

  const [noteList, setNoteList] = useState([]);
  const [noteIdForUpdate, setNoteIdForUpdate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // validation of invoice
  const [validation, setValidation] = useState({
    titleWarning: false,
    detailsWarning: false,
  });

  // function for validation
  const validationHandler = () => {
    let isValid = true;

    if (noteTitle === "") {
      setValidation((prevState) => ({ ...prevState, titleWarning: true }));
      isValid = false;
    }

    if (noteDetails === "") {
      setValidation((prevState) => ({ ...prevState, detailsWarning: true }));
      isValid = false;
    }

    return isValid;
  };

  //function for get notes
  const getAllNotes = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_NOTE +
        `?token=${token}&filternotetype=1`;

      if (leadId) {
        requestUrl += `&leadid=${leadId}`;
      }

      if (contactId) {
        requestUrl += `&contactid=${contactId}`;
      }

      if (invoiceId) {
        requestUrl += `&invoiceid=${invoiceId}`;
      }

      if (taskId) {
        requestUrl += `&taskid=${taskId}`;
      }

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        setNoteList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for update note
  const updateNoteStatus = (note) => {
    setNoteIdForUpdate(note._id);
    setNoteTitle(note.title);
    setNoteDetails(note.details);
    setIsPrivate(note.isprivate);
  };

  //function for save note
  const saveNoteHandler = async () => {
    if (validationHandler()) {
      try {
        setIsLoading(true);

        let noteData = {
          notetype: "1",
          title: noteTitle,
          details: noteDetails,
          isprivate: isPrivate,
        };

        if (leadId) {
          noteData.leadid = leadId;
        }

        if (contactId) {
          noteData.contactid = contactId;
        }

        if (invoiceId) {
          noteData.invoiceid = invoiceId;
        }

        if (taskId) {
          noteData.taskid = taskId;
        }

        let requestUrl =
          url.API_BASE_URL + url.API_ADD_NEW_NOTE + `?token=${token}`;

        const response = await postData(requestUrl, noteData);

        setIsLoading(false);

        console.log(response);
        if (response.status) {
          setMessageType("success");
          resetHandler();
          getAllNotes();
        } else {
          setMessageType("error");
        }

        setAlertMessage(response.message);
      } catch (error) {
        setAlertMessage(error.message);
        setMessageType("error");
      }
    }

    setShowAlert(true);
  };

  //function for reset note form
  const resetHandler = () => {
    setNoteTitle("");
    setNoteDetails("");
    setIsPrivate(false);
    setNoteIdForUpdate(null);
    setValidation({
      titleWarning: false,
      detailsWarning: false,
    });
  };

  //function for update note
  const updateNoteHandler = async () => {
    try {
      setIsLoading(true);

      let noteData = {
        title: noteTitle,
        details: noteDetails,
        isprivate: isPrivate,
      };

      let requestUrl =
        url.API_BASE_URL +
        url.API_UPDATE_NOTE +
        `/${noteIdForUpdate}` +
        `?token=${token}`;

      const response = await putData(requestUrl, noteData);

      if (response.status) {
        setMessageType("success");
        resetHandler();
        getAllNotes();
      } else {
        setMessageType("error");
      }

      setIsLoading(false);
      setAlertMessage(response.message);
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
    }

    setShowAlert(true);
  };

  //function for delete note
  const deleteNoteHandler = async (noteId) => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_DELETE_NOTE +
        `/${noteId}` +
        `?token=${token}`;

      if (leadId) {
        requestUrl += `&leadid=${leadId}`;
      }

      if (contactId) {
        requestUrl += `&contactid=${contactId}`;
      }

      if (invoiceId) {
        requestUrl += `&invoiceid=${invoiceId}`;
      }

      if (taskId) {
        requestUrl += `&taskid=${taskId}`;
      }

      const response = await deleteData(requestUrl);

      if (response.status) {
        setMessageType("success");
        getAllNotes();
      } else {
        setMessageType("error");
      }

      setAlertMessage(response.message);
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
    }

    setShowAlert(true);
  };

  useEffect(() => {
    if (leadId || contactId || invoiceId || taskId) {
      getAllNotes();
    }
  }, [leadId, contactId, invoiceId, taskId]);

  return (
    <div className="p-4 border border-gray-300 rounded-10 shadow-sm">
      <h3>{`${t("Notes")} ${moduleName}`}</h3>
      <div className="add-note d-flex flex-column p-2 body-bg rounded-10 mb-4">
        <input
          type="text"
          className="form-control border-0 shadow-none"
          placeholder={t("Title")}
          value={noteTitle}
          onChange={(e) => {
            setNoteTitle(e.target.value);
            setValidation((prevState) => ({
              ...prevState,
              titleWarning: false,
            }));
          }}
        />
        {validation.titleWarning && (
          <div className="error-message mt-2">
            <p className="d-flex align-items-center gap-1 text-danger">
              <span className="material-symbols-outlined">warning</span>
              <span>Please enter a title!</span>
            </p>
          </div>
        )}
        <textarea
          cols="30"
          rows="3"
          className="form-control border-0 shadow-none"
          placeholder={t("Details")}
          value={noteDetails}
          onChange={(e) => {
            setNoteDetails(e.target.value);
            setValidation((prevState) => ({
              ...prevState,
              detailsWarning: false,
            }));
          }}
        ></textarea>
        {validation.detailsWarning && (
          <div className="error-message mt-2">
            <p className="d-flex align-items-center gap-1 text-danger">
              <span className="material-symbols-outlined">warning</span>
              <span>Please enter details!</span>
            </p>
          </div>
        )}

        <div className="d-flex align-items-center justify-content-between">
          <div className="form-group d-flex align-items-center gap-2">
            <div className="form-check">
              <input
                className="form-check-input check-success border-primary"
                type="checkbox"
                checked={isPrivate}
                onChange={(e) => setIsPrivate(e.target.checked)}
              />
            </div>
            <span className="d-block text-secondary">
              {t("Mark as Private")}
            </span>
          </div>

          <div className="d-flex align-items-center gap-1">
            {noteIdForUpdate && (
              <button
                className="btn btn-secondary px-4 py-1"
                style={{ cursor: isLoading ? "not-allowed" : "pointer" }}
                disabled={isLoading ? true : false}
                onClick={resetHandler}
              >
                Reset
              </button>
            )}
            <button
              className="btn btn-primary px-4 py-1"
              style={{ cursor: isLoading ? "not-allowed" : "pointer" }}
              disabled={isLoading ? true : false}
              onClick={noteIdForUpdate ? updateNoteHandler : saveNoteHandler}
            >
              {noteIdForUpdate ? t("Update") : t("Save")}
              {isLoading && (
                <div
                  className="mx-2 spinner-border spinner-border-sm"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </button>
          </div>
        </div>
      </div>
      <ul className="note-list">
        {noteList.map((note, index) => {
          return (
            <li className="note mb-4" key={index}>
              <div className="title d-flex gap-2 align-items-center justify-content-between mb-2">
                <h3 className="mb-0">{note.title}</h3>
                {userInfo.role.slug === "ADMIN" ||
                userInfo.role.slug === "SUPER_ADMIN" ||
                note.moderator.toString() === userInfo._id.toString() ? (
                  <div className="dropdown">
                    <button
                      className="d-block p-0 bg-transparent text-gray border-0 hide-dropdown-arrow dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span className="d-block material-symbols-outlined">
                        more_vert
                      </span>
                    </button>
                    <ul className="dropdown-menu dropdown-menu-end bg-white fs-sm border-0 rounded-10 shadow-sm">
                      <li>
                        <Link
                          className="dropdown-item"
                          to="#"
                          onClick={() => {
                            updateNoteStatus(note);
                          }}
                        >
                          Edit
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          to="#"
                          onClick={() => {
                            deleteNoteHandler(note._id);
                          }}
                        >
                          Delete
                        </Link>
                      </li>
                    </ul>
                  </div>
                ) : null}
              </div>
              <div className="desc fs-sm">
                <p className="date mb-0">{note.notedate}</p>
                <p className="text-gray">{note.details}</p>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default NoteSection;
