/* eslint-disable */
import React, { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Material UI table
import { MaterialReactTable } from "material-react-table";

//Material UI components for rendring menu and others
import { MenuItem, Box, Tooltip, IconButton } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData, putData } from "utils/Gateway";

import AlertNotification from "components/Common/AlertNotification/AlertNotification";
import ProjectManagementCommonHeader from "components/ProjectManagementComponents/Common/Header/ProjectManagementCommonHeader";
import ProjectAllMemberHeader from "./Header/ProjectAllMemberHeader";
import { assetImages } from "constants";

const AllProjectMemberListBody = () => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");
  const { t, i18n } = useTranslation(); //for translation

  const [isLoading, setIsLoading] = useState(false);
  const [memberList, setMemberList] = useState([]);

  //optionally, you can manage the row selection state yourself
  const [rowSelection, setRowSelection] = useState({});
  const [selectedMemberIds, setSelectedMemberIds] = useState([]);
  const [selectedMemberId, setSelectedMemberId] = useState(null);

  //alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  //function for get all members
  const getAllProjectMembers = async () => {
    try {
      setIsLoading(true);

      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_TOTAL_PROJECT_MEMBERS +
        `?token=${token}`;

      const response = await getData(requestUrl);

      setIsLoading(false);

      console.log(response);

      if (response.status) {
        setMessageType("success");
        setMemberList(response.data);
      } else {
        setMessageType("error");
      }
      setAlertMessage(response.message);
    } catch (error) {
      setMessageType("error");
      setAlertMessage(error.message);
    }
    setShowAlert(true);
  };

  useEffect(() => {
    getAllProjectMembers();
  }, []);

  const columns = useMemo(
    () => [
      {
        accessorKey: "username",
        header: "Name",
        size: 300,
        Cell: ({ row }) => (
          <a
            href={`${url.FRONTEND_BASE_URL}/peoples/details/${row.original.userid}`}
            target="_blank"
            className="owner border-bottom-0 d-flex gap-1"
          >
            <img
              className="rounded-circle"
              height={35}
              width={35}
              src={
                row.original.userimage == ""
                  ? assetImages.defaultUser
                  : url.SERVER_URL + row.original.userimage
              }
              alt="Babcock"
            />
            <p>
              <span className="d-block">{row.original.username}</span>
              <span className="d-block">{row.original.useremail}</span>
            </p>
          </a>
        ),
      },
      {
        accessorKey: "projectcount",
        header: "No of Projects",
      },
      {
        accessorKey: "topprojectstring",
        header: "Top Projects",
        size: 300,
        Cell: ({ row }) => (
          <div className="d-flex flex-column gap-2">
            {row.original.topprojects.map((project, index) => {
              return (
                <p className="mb-0" key={index}>
                  {project.projectname}
                </p>
              );
            })}
            {row.original.topprojects && row.original.topprojects.length > 0 ? (
              <Link
                className="fs-sm text-primary"
                to={`/admin/projectmanagement/list/${row.original.userid}`}
              >
                See more
              </Link>
            ) : null}
          </div>
        ),
      },
      {
        accessorKey: "assignedtasks",
        header: "Assigned Tasks",
      },
      {
        accessorKey: "pendingtasks",
        header: "Pending Tasks",
      },

      {
        accessorKey: "completedtasks",
        header: "Completed Tasks",
      },
      {
        accessorKey: "delayedtasks",
        header: "Delayed Tasks",
      },
      {
        accessorKey: "totalloggedhours",
        header: "Logged Hours",
      },
    ],
    [i18n.language]
  );

  const onAlertClose = () => {
    // Set the state variable 'showAlert' to false to hide the alert.
    setShowAlert(false);

    // Clear the alert message by setting the state variable 'alertMessage' to an empty string.
    setAlertMessage("");

    // Clear the alert type by setting the state variable 'messageType' to an empty string.
    setMessageType("");
  };

  return (
    <section className="projects-wrapper bg-white pb-5">
      <ProjectManagementCommonHeader componentName="allmembers" />

      <div className="container-fluid px-lg-5">
        <ProjectAllMemberHeader reloadList={getAllProjectMembers} />

        {isLoading ? (
          <div className="placeholder-glow d-flex flex-column gap-4">
            <span className="placeholder placeholder-lg bg-secondary col-12"></span>
            <span className="placeholder placeholder-lg bg-secondary col-8"></span>
            <span className="placeholder placeholder-lg bg-secondary col-4"></span>
          </div>
        ) : (
          <div className="table-wrapper">
            <MaterialReactTable
              columns={columns} // map columns to be displayed with api data,
              data={memberList} // data from api to be displayed
              enableGrouping
              //   enableRowSelection // enable showing checkbox
              //   getRowId={(row) => row._id} // map which value to select with row checkbox
              //   onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
              //   state={{ rowSelection }} //pass our managed row selection state to the table to use
              //   enableRowActions // show the action column
              //   renderRowActionMenuItems={({ row }) => [
              //     <MenuItem key="view">
              //       <Link
              //         className="dropdown-item"
              //         to={`/admin/projectmanagement/details/${row.id}`}
              //       >
              //         {t("View Details")}
              //       </Link>
              //     </MenuItem>,
              //     <MenuItem key="copy">
              //       <Link
              //         className="dropdown-item"
              //         to={`/admin/projectmanagement/copyproject/${row.id}`}
              //       >
              //         {t("Copy Project")}
              //       </Link>
              //     </MenuItem>,
              //     <MenuItem key="member">
              //       <Link
              //         className="dropdown-item"
              //         to={`/admin/projectmanagement/members/${row.id}`}
              //       >
              //         {t("View Members")}
              //       </Link>
              //     </MenuItem>,
              //     <MenuItem key="task">
              //       <Link
              //         className="dropdown-item"
              //         to={`/admin/projectmanagement/tasksection/${row.id}`}
              //       >
              //         {t("View Task")}
              //       </Link>
              //     </MenuItem>,
              //     <MenuItem key="log">
              //       <Link
              //         className="dropdown-item"
              //         to={`/admin/projectmanagement/logs/${row.id}`}
              //       >
              //         {t("View Logs")}
              //       </Link>
              //     </MenuItem>,
              //   ]} // action columns menu items
              defaultColumn={{
                minSize: 20,
                maxSize: 300,
                size: 50, //make columns wider by default
              }}
              muiTableContainerProps={{
                sx: {
                  maxHeight: "60vh",
                },
              }}
              enableStickyHeader
            />
          </div>
        )}
      </div>

      {showAlert && (
        <AlertNotification
          showAlert={showAlert}
          message={alertMessage}
          alertType={messageType}
          onClose={onAlertClose}
        />
      )}
    </section>
  );
};

export default AllProjectMemberListBody;
