import Header from "components/Common/Header/Header";
import AllProjectMemberListBody from "components/ProjectManagementComponents/AllProjectsMemberList/AllProjectMemberListBody";
import React, { useEffect } from "react";

const AllProjectMemberList = () => {
  // -------- for title ------------
  useEffect(() => {
    document.title = "Project Management";
  }, []);

  return (
    <main id="app">
      {/* ---- common header ---- */}
      <Header moduleName="projects" />

      <AllProjectMemberListBody />
    </main>
  );
};

export default AllProjectMemberList;
