import ProjectManagementCommonHeader from "components/ProjectManagementComponents/Common/Header/ProjectManagementCommonHeader";
import ProjectCard from "components/ProjectManagementComponents/Common/Cards/ProjectCard";
import TaskCard from "components/ProjectManagementComponents/Common/Cards/TaskCard";
import InvoiceCard from "components/ProjectManagementComponents/Common/Cards/InvoiceCard";
import ProjectGraphHeader from "./Header/ProjectGraphHeader";
import { assetImages } from "constants";

const ProjectManagementGraphBody = () => {
  return (
    <section class="projects-wrapper bg-white pb-5">
      <ProjectManagementCommonHeader componentName="fullreport" />

      <div class="container-fluid px-lg-5">
        <ProjectGraphHeader />
        {/* <!-- ========== Start upper card Section ========== --> */}
        <div className="row mb-4">
          <ProjectCard />

          <TaskCard />

          <InvoiceCard />
        </div>
        {/* <!-- ========== End upper card Section ========== --> */}

        <div class="graph-container mb-4">
          <img src={assetImages.reportGraph} alt="Graph" class="img-fluid" />
        </div>
      </div>
    </section>
  );
};

export default ProjectManagementGraphBody;
