/* eslint-disable */
import React, { useEffect, useState, useRef } from "react";
import { useParams, Link } from "react-router-dom";

import { useTranslation } from "react-i18next";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData, postData, putData } from "utils/Gateway";

import { assetImages } from "constants"; //images
import { prjectManageSlug } from "Config/Config";

import TabsHeader from "components/Common/TabsHeader/TabsHeader";
import AddLabelModal from "components/Common/Modal/AddLabelModal";
import { projectDetailsHeaderLinks } from "helper/ProjectHelper/ProjectHelper";
import TaskManagementPopup from "components/Common/Popup/TaskManagementPopup/TaskManagementPopup";
import MyTaskFilterPopup from "components/Common/Popup/MyTaskFilterPopup";

import ProjectSectionHeader from "../ProjectSectionHeader/ProjectSectionHeader";
import AddSectionModal from "../Modals/AddSectionModal";
import DeleteSectionWarningModal from "../Modals/DeleteSectionWarningModal";
import DeleteTaskWarningModal from "components/Common/Modal/DeleteTaskWarningModal";
import ProjectSectionCard from "./ProjectSectionCard/ProjectSectionCard";
import AssignBulkTaskModal from "components/Common/Modal/AssignBulkTaskModal";
import SuccessBulkTaskModal from "components/Common/Modal/SuccessBulkTaskModal";
import AlertNotification from "components/Common/AlertNotification/AlertNotification";
import AddAlertModal from "components/Common/Modal/AddAlertModal";
import AddFollowerModal from "components/Common/Modal/AddFollowerModal";

const ProjectSectionBody = () => {
  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();

  const params = useParams();
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");

  const sectionGlowCount = [1, 2, 3, 4];
  const placeHolderCount = [1, 2, 3];

  const [title, settitle] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [sectionList, setSectionList] = useState([]);
  const [sectionOptions, setSectionOptions] = useState([]);
  const [memberList, setMemberList] = useState([]);
  const [isProjectModerator, setisProjectModerator] = useState(false);

  const [selectedSectionId, setSelectedSectionId] = useState(null);
  const [selectedSectionValue, setSelectedSectionValue] = useState(null);
  const [selectedTaskModerator, setselectedTaskModerator] = useState(null);

  const [selectedEditTaskId, setSelectedEditTaskId] = useState(null);

  const [selectedTaskIds, setselectedTaskIds] = useState([]);

  const [filterTaskDateRangeType, setFilterTaskDateRangeType] = useState("");
  const [filterTaskFromDate, setfilterTaskFromDate] = useState("");
  const [filterTaskToDate, setfilterTaskToDate] = useState("");
  const [filterTaskCustomIds, setfilterTaskCustomIds] = useState([]);
  const [filterTaskTitle, setfilterTaskTitle] = useState("");
  const [filterTaskProjects, setfilterTaskProjects] = useState([]);
  const [filterTaskAssignedToUsers, setfilterTaskAssignedToUsers] = useState(
    []
  );
  const [filterTaskAssignedByUsers, setfilterTaskAssignedByUsers] = useState(
    []
  );
  const [filterTaskStatus, setfilterTaskStatus] = useState([]);
  const [filterTaskPriorities, setfilterTaskPriorities] = useState([]);
  const [filterTaskLabels, setfilterTaskLabels] = useState([]);

  const [projectMemberStatus, setProjectMemberStatus] = useState("");
  const [projectModStatus, setprojectModStatus] = useState("");

  //accordion opened section id
  const [openSectionId, setOpenSectionId] = useState(null);

  const [bulkSavedTaskList, setBulkSavedTaskList] = useState([]);

  const [deleteType, setDeleteType] = useState("");

  const [reloadData, setReloadData] = useState(false);
  const [isFilterReset, setIsFilterReset] = useState(false);

  // -------- tabs header object --------------------
  const commonHeaderObject = projectDetailsHeaderLinks(params.id, t);

  //alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  // -------- breadcrumb text --------
  const breadCrumbText = [
    { title: t("Projects"), link: "/admin/projectmanagement/list" },
    { title: t("Details") },
  ];

  //function for get project details
  const getProjectDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_PROJECT_DETAILS +
        `/${params.id}?token=${token}`;

      // console.log(requestUrl);

      const response = await getData(requestUrl);

      // console.log(response);
      if (response.status && response.data) {
        const data = response.data;

        settitle(data.title);
        setselectedTaskModerator(data.moderator._id);

        let isManager = false;

        // Loop through the array
        data.projectmemberlist.forEach((member) => {
          if (
            member._id.toString() === userInfo._id.toString() &&
            member.roleslug === prjectManageSlug
          ) {
            isManager = true;
          }
        });

        let isModerator = false;

        if (
          data.moderator._id.toString() === userInfo._id.toString() ||
          isManager ||
          userInfo.role.slug === "ADMIN" ||
          userInfo.role.slug === "SUPER_ADMIN"
        ) {
          isModerator = true;
        } else {
          getMemberDetails();
        }

        setisProjectModerator(isModerator);
      } else {
        setAlertMessage(response.message);
        setMessageType("error");
        setShowAlert(true);
      }
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
      setShowAlert(true);
    }
  };

  //function for get details of event member
  const getMemberDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_PROJECT_MEMBER_DETAILS_QUERY +
        `?token=${token}&projectid=${params.id}`;

      const response = await getData(requestUrl);
      // console.log(response);

      if (response.status) {
        setProjectMemberStatus(response.data.memberstatus);
        setprojectModStatus(response.data.moderatorstatus);
      } else {
        console.log(response.message);
      }
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
      setShowAlert(true);
    }
  };

  //function for get all event members
  const getAllProjectMembers = async () => {
    try {
      setIsLoading(true);
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_PROJECT_MEMBER +
        `?token=${token}&project=${params.id}`;

      const response = await getData(requestUrl);

      // console.log(response);

      setIsLoading(false);
      if (response.status) {
        let approvedMembers = [];

        response.data.map((memberData) => {
          if (
            memberData.memberstatus == "1" &&
            memberData.moderatorstatus == "1"
          ) {
            approvedMembers.push({
              label: memberData.memebername,
              value: memberData.user._id,
            });
          }
        });

        setMemberList(approvedMembers);
      } else {
        setAlertMessage(response.message);
        setMessageType("error");
        setShowAlert(true);
      }
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
      setShowAlert(true);
    }
  };

  //get all section with task
  const getAllSections = async () => {
    try {
      setIsLoading(true);
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_PROJECT_SECTION +
        `?token=${token}&projectid=${
          params.id
        }&parentsectionid=${null}&userrolestring=${userInfo.role.slug}`;

      const response = await getData(requestUrl);

      setIsLoading(false);
      // console.log(response);

      if (response.status) {
        setMessageType("success");
        setSectionList(response.data);
      } else {
        setMessageType("error");
      }

      setAlertMessage(response.message);
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
    }

    setShowAlert(true);
  };

  //get all section list for react select
  const getAllSectionOptions = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_PROJECT_SECTION +
        `?token=${token}&projectid=${params.id}&userrolestring=${userInfo.role.slug}`;

      console.log(requestUrl);

      const response = await getData(requestUrl);

      console.log("response in options ---->", response);

      setSectionOptions(
        response.data.map((sectionData) => ({
          label: sectionData.title,
          value: sectionData._id,
          startdate: sectionData.startdate,
          enddate: sectionData.enddate,
        }))
      );
    } catch (error) {
      console.log(error.message);
    }
  };

  //change status for section
  const changeSectionStatusHandler = async () => {
    try {
      let sectionData = {
        status: "0",
      };

      let requestUrl =
        url.API_BASE_URL +
        url.API_UPDATE_PROJECT_SECTION +
        `/${selectedSectionId}?token=${token}`;

      const response = await putData(requestUrl, sectionData);

      // console.log(response);

      if (response.status) {
        setMessageType("success");
        setSelectedSectionId(null);
        setTimeout(() => {
          getAllSections();
        }, 2200);
      } else {
        setMessageType("error");
      }

      setAlertMessage(response.message);
    } catch (error) {
      setMessageType("error");
      setAlertMessage(error.message);
    }

    setShowAlert(true);
  };

  //change status for task
  const changeTaskStatusHandler = async () => {
    if (deleteType === "singletask") {
      try {
        let taskData = {
          status: "0",
        };

        let requestUrl =
          url.API_BASE_URL +
          url.API_DEACTIVATE_PROJECT_TASK +
          `/${selectedEditTaskId}?token=${token}`;

        console.log("Delete task url----->", requestUrl);

        const response = await putData(requestUrl, taskData);

        console.log(response);

        if (response.status) {
          setMessageType("success");
          setDeleteType("");
          setSelectedEditTaskId(null);

          setTimeout(() => {
            getAllSections();
          }, 1500);
        } else {
          setMessageType("error");
        }

        setAlertMessage(response.message);
      } catch (error) {
        setAlertMessage(error.message);
        setMessageType("error");
      }
      setShowAlert(true);
    } else if (deleteType === "bulk") {
      if (selectedTaskIds.length > 0) {
        let responseArr = [];

        for (let updatedTaskId of selectedTaskIds) {
          try {
            let requestUrl =
              url.API_BASE_URL +
              url.API_DEACTIVATE_PROJECT_TASK +
              `/${updatedTaskId}` +
              `?token=${token}`;

            const response = await putData(requestUrl);

            // console.log(response);
            if (response.status) {
              responseArr.push(true);
              setMessageType("success");
            } else {
              setMessageType("error");
            }

            setAlertMessage(response.message);
          } catch (error) {
            setAlertMessage(error.message);
            setMessageType("error");
          }

          setShowAlert(true);
        }
        if (responseArr.length > 0) {
          setTimeout(() => {
            setDeleteType("");
            getAllSections();
          }, 1500);
        }
      } else {
        setMessageType("error");
        setAlertMessage("Please select atleast one task to delete");
        setShowAlert(true);
      }
    } else {
    }
  };

  //cancel section warning
  const onCancelSectionWarning = () => {
    setSelectedSectionId(null);
  };

  //cancel task warning
  const onCancelTaskWarning = () => {
    setSelectedEditTaskId(null);
  };

  //refresh function for refresh icon
  const refreshRecords = () => {
    resetFilterData();
  };

  const afterPopupClose = () => {
    getAllSections();
  };

  //function for reset filter
  const resetFilterData = () => {
    setFilterTaskDateRangeType("");
    setfilterTaskFromDate("");
    setfilterTaskToDate("");
    setfilterTaskCustomIds([]);
    setfilterTaskTitle("");
    setfilterTaskLabels([]);
    setfilterTaskStatus([]);
    setfilterTaskPriorities([]);
    setfilterTaskProjects([]);
    setfilterTaskAssignedToUsers([]);
    setfilterTaskAssignedByUsers([]);
    setReloadData(true);
  };

  const afterModalClose = () => {
    setselectedTaskIds([]);
    const checkboxes = document.querySelectorAll('input[name="taskCheckBox"]');

    for (let i = 0; i < checkboxes.length; i++) {
      checkboxes[i].checked = false;
    }

    setTimeout(() => {
      getAllSections();
    }, 2500);
  };

  const afterTaskAssignModalHandler = (isGetAllList) => {
    setselectedTaskIds([]);
    const checkboxes = document.querySelectorAll('input[name="taskCheckBox"]');

    for (let i = 0; i < checkboxes.length; i++) {
      checkboxes[i].checked = false;
    }

    if (isGetAllList) {
      getAllSections();
    }
  };

  useEffect(() => {
    if (params.id) {
      getAllProjectMembers();
      getProjectDetails();
      getAllSections();
      getAllSectionOptions();
      // setOpenSectionId(null);
    }
  }, [params.id]);

  useEffect(() => {
    if (reloadData) {
      setIsFilterReset(true);
      getAllSections();
      setReloadData(false);
    }
  }, [reloadData]);

  const onAlertClose = () => {
    // Set the state variable 'showAlert' to false to hide the alert.
    setShowAlert(false);

    // Clear the alert message by setting the state variable 'alertMessage' to an empty string.
    setAlertMessage("");

    // Clear the alert type by setting the state variable 'messageType' to an empty string.
    setMessageType("");
  };

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    moduleAccess.includes("MOD_PROJECT")
  ) {
    return (
      <div id="content_wrapper">
        <section className="event-details-wrapper bg-white pb-5">
          {/* ------ common header -------- */}
          {params.id && (
            <TabsHeader
              commonHeaderObject={commonHeaderObject}
              activeOption={t("Tasks")}
            />
          )}
          <div className="container-fluid px-lg-5 pt-4 pt-md-0">
            {/* -------- filter area ------- */}
            <ProjectSectionHeader
              setDeleteType={setDeleteType}
              projectid={params.id}
              isProjectModerator={isProjectModerator}
              projectMemberStatus={projectMemberStatus}
              projectModStatus={projectModStatus}
              reloadList={() => {
                // setOpenSectionId(null);
                refreshRecords();
              }}
              breadCrumbText={breadCrumbText}
              title={title}
            />

            {/* -------- list area ---------- */}
            {isLoading ? (
              <div className="project-tasks-container">
                <div className="accordion" id="accordionPanelsStayOpenExample">
                  {sectionGlowCount.map((item, index) => {
                    return (
                      <div
                        className="accordion-item bg-transparent border-0"
                        key={index}
                      >
                        <h3 className="accordion-header d-flex align-items-center gap-3">
                          <button
                            className="accordion-button w-auto bg-transparent p-0 shadow-none"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#panelsStayOpen-collapseOne"
                            aria-expanded="true"
                            aria-controls="panelsStayOpen-collapseOne"
                          ></button>
                          <span
                            className="placeholder"
                            style={{ width: "5rem", height: "1.125rem" }}
                          ></span>
                          <span
                            className="placeholder rounded-90 ms-auto"
                            style={{ width: "5.875rem", height: "1.75rem" }}
                          ></span>
                          <div className="action d-flex align-items-center gap-3 ">
                            <span
                              className="placeholder rounded"
                              style={{ width: "20px", height: "1.5px" }}
                            ></span>

                            <span
                              className="placeholder rounded"
                              style={{ width: "20px", height: "1.5px" }}
                            ></span>
                          </div>
                        </h3>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : isProjectModerator ||
              (projectMemberStatus === "1" && projectModStatus === "1") ? (
              <div className="project-tasks-container">
                <div className="accordion" id="accordionPanelsStayOpenExample">
                  {sectionList.map((sectionData, index) => {
                    return (
                      <ProjectSectionCard
                        setDeleteType={setDeleteType}
                        key={index}
                        sectionData={sectionData}
                        sectionAccordionId={sectionData._id}
                        isProjectModerator={isProjectModerator}
                        //task and section update requirements
                        setSelectedSectionValue={setSelectedSectionValue}
                        setSelectedEditTaskId={setSelectedEditTaskId}
                        setSelectedSectionId={setSelectedSectionId}
                        //checkbox requiremnts
                        selectedTaskIds={selectedTaskIds}
                        setselectedTaskIds={setselectedTaskIds}
                        //filter requirements
                        filterTaskDateRangeType={filterTaskDateRangeType}
                        filterTaskFromDate={filterTaskFromDate}
                        filterTaskToDate={filterTaskToDate}
                        filterTaskCustomIds={filterTaskCustomIds}
                        filterTaskTitle={filterTaskTitle}
                        filterTaskLabels={filterTaskLabels}
                        filterTaskStatus={filterTaskStatus}
                        filterTaskPriorities={filterTaskPriorities}
                        filterTaskAssignedByUsers={filterTaskAssignedByUsers}
                        filterTaskAssignedToUsers={filterTaskAssignedToUsers}
                        // resetFilterData={resetFilterData}

                        openSectionId={openSectionId}
                        setOpenSectionId={setOpenSectionId}
                      />
                    );
                  })}
                </div>
              </div>
            ) : (
              <div className="project-tasks-container">
                <div className="challenges_empty text-center">
                  <div className="empty_pic mb-4">
                    {" "}
                    <img src={assetImages.emptyVector} alt="" />
                  </div>
                  <div className="empty_text">
                    <p className="fs-lg text-gray fw-semibold mb-4">
                      {t(
                        "Sorry....! You don't have privilege to see this content"
                      )}
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>
        {/* ========== popups and modal area ======== */}
        {/* ------ add section modal -------- */}
        <AddSectionModal
          sectionOptions={sectionOptions}
          projectId={params.id}
          onModalClose={() => {
            setTimeout(() => {
              getAllSections();
            }, 2200);
          }}
          selectedSectionId={selectedSectionId}
          setSelectedSectionId={setSelectedSectionId}
          selectedParentSectionValue={selectedSectionValue}
          setSelectedParentSectionValue={setSelectedSectionValue}
          setShowAlert={setShowAlert}
          setAlertMessage={setAlertMessage}
          setMessageType={setMessageType}
        />
        <DeleteSectionWarningModal
          onDelete={changeSectionStatusHandler}
          onCancelDelete={onCancelSectionWarning}
        />
        <DeleteTaskWarningModal
          onDelete={changeTaskStatusHandler}
          onCancelDelete={onCancelTaskWarning}
        />
        {/* -------- add task popup -------- */}
        <TaskManagementPopup
          selectedModuleSlug="MOD_PROJECT"
          moduleName="proejcttask"
          leadId={null}
          contactId={null}
          sectionId={selectedSectionId}
          projectId={params.id}
          selectedTaskId={selectedEditTaskId}
          selectedTaskModerator={selectedTaskModerator}
          isProjectModerator={isProjectModerator}
          projectValue={{ label: title, value: params.id }}
          selectedSectionValue={selectedSectionValue}
          leadValue={null}
          contactValue={null}
          onPopUpClose={() => {
            setSelectedEditTaskId(null);
            setSelectedSectionValue(null);
          }}
          getTaskList={() => {
            setTimeout(() => {
              getAllSections();
            }, 2200);
          }}
          setShowAlert={setShowAlert}
          setAlertMessage={setAlertMessage}
          setMessageType={setMessageType}
        />
        {/* ------- filter popup --------- */}
        <MyTaskFilterPopup
          isFilterReset={isFilterReset}
          setIsFilterReset={setIsFilterReset}
          filterTaskDateRangeType={filterTaskDateRangeType}
          setFilterTaskDateRangeType={setFilterTaskDateRangeType}
          filterTaskFromDate={filterTaskFromDate}
          setfilterTaskFromDate={setfilterTaskFromDate}
          filterTaskToDate={filterTaskToDate}
          setfilterTaskToDate={setfilterTaskToDate}
          filterTaskCustomIds={filterTaskCustomIds}
          setfilterTaskCustomIds={setfilterTaskCustomIds}
          filterTaskTitle={filterTaskTitle}
          setfilterTaskTitle={setfilterTaskTitle}
          filterTaskProjects={filterTaskProjects}
          setfilterTaskProjects={setfilterTaskProjects}
          filterTaskAssignedByUsers={filterTaskAssignedByUsers}
          setfilterTaskAssignedToUsers={setfilterTaskAssignedToUsers}
          filterTaskAssignedToUsers={filterTaskAssignedToUsers}
          setfilterTaskAssignedByUsers={setfilterTaskAssignedByUsers}
          filterTaskStatus={filterTaskStatus}
          setfilterTaskStatus={setfilterTaskStatus}
          filterTaskPriorities={filterTaskPriorities}
          setfilterTaskPriorities={setfilterTaskPriorities}
          filterTaskLabels={filterTaskLabels}
          setfilterTaskLabels={setfilterTaskLabels}
          isModerator={isProjectModerator}
          moduleName="projectsection"
        />
        <AddLabelModal
          moduleName="task"
          selectedIds={selectedTaskIds}
          afterTagModalClose={afterModalClose}
          setShowAlert={setShowAlert}
          setAlertMessage={setAlertMessage}
          setMessageType={setMessageType}
        />
        <AddAlertModal
          selectedIds={selectedTaskIds}
          afterModalClose={afterModalClose}
          moduleName={"task"}
        />
        <AssignBulkTaskModal
          moduleName={"proejcttask"}
          userInfo={userInfo}
          afterCloseModalHandler={afterTaskAssignModalHandler}
          selectedTasks={selectedTaskIds}
          setBulkSavedTaskList={setBulkSavedTaskList}
          setShowAlert={setShowAlert}
          setAlertMessage={setAlertMessage}
          setMessageType={setMessageType}
        />
        <SuccessBulkTaskModal
          bulkSavedTaskList={bulkSavedTaskList}
          afterModalClose={afterTaskAssignModalHandler}
        />
        <AddFollowerModal
          moduleName="task"
          setShowAlert={setShowAlert}
          setAlertMessage={setAlertMessage}
          setMessageType={setMessageType}
          selectedIds={selectedTaskIds}
          afterModalClose={afterModalClose}
        />
        {showAlert && (
          <AlertNotification
            showAlert={showAlert}
            message={alertMessage}
            alertType={messageType}
            onClose={onAlertClose}
          />
        )}
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {" "}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};

export default ProjectSectionBody;
