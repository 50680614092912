import React from "react";
import { Link } from "react-router-dom";

const KanbanCollapsedColumn = ({
  collapseHandler = () => {},
  columnData = {},
  viewAccessCount = 0,
}) => {
  return (
    <div className="dev_card_collapsed">
      <div className="challenges_collapse p-3 rounded-5 text-center">
        <div className="collapse_arws">
          <Link
            to="#"
            className="bg-gray-300 rounded-5 p-1 d-inline-flex"
            onClick={() => {
              collapseHandler(false, columnData.componentid);
            }}
          >
            <span className="material-symbols-outlined fs-lg">
              chevron_right
            </span>
          </Link>
        </div>
        <div className="roate_text">
          <h5 className="text-uppercase text-black fs-md fw-semibold d-flex align-items-center gap-4 mb-0">
            {columnData.processtitle} <span>{viewAccessCount}</span>
          </h5>
        </div>
      </div>
    </div>
  );
};

export default KanbanCollapsedColumn;
