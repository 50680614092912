/* eslint-disable */
import React, { useEffect, useState, useMemo } from "react";
import { Link, useHistory } from "react-router-dom";

// clipboardCopy for mail variables
import clipboardCopy from "clipboard-copy";

// Material UI components
import { MaterialReactTable } from "material-react-table";
import { MenuItem, Box } from "@mui/material";

/*import url and gateway methods */
import { getData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

import SurveyListFilterPopup from "../Popup/SurveyListFilterPopup";
import AddLabelModal from "components/Common/Modal/AddLabelModal";
import SuccessMessageModal from "../Modal/SuccessMessageModal";
import { assetImages } from "constants";
import { useTranslation } from "react-i18next";
import AlertNotification from "components/Common/AlertNotification/AlertNotification";
import DeleteWarningModal from "components/Common/Modal/DeleteWarningModal";
import SaveSurveyModal from "../Modal/SaveSurveyModal";
import SurveyListHeader from "components/SurveyComponents/Header/SurveyListHeader";
import UpdateSurveyModal from "../Modal/UpdateSurveyModal";

const SurveyListBody = () => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");

  const { t, i18n } = useTranslation(); //for translation

  const [tagList, setTagList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [surveyList, setSurveyList] = useState([]);

  //optionally, you can manage the row selection state yourself
  const [rowSelection, setRowSelection] = useState({});
  const [selectedSurveyIds, setSelectedSurveyIds] = useState([]);

  const [selectedSurveyId, setSelectedSurveyId] = useState(null);
  const [isEdit, setIsEdit] = useState(false);

  const [filterSurveyName, setFilterSurveyName] = useState("");
  const [filterSurveyCode, setFilterSurveyCode] = useState("");
  const [filterSurveyDateRange, setfilterSurveyDateRange] = useState("");
  const [filterSurveyTags, setfilterSurveyTags] = useState([]);

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  //function for get all tags
  const getAllTags = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_TAGS + `?token=${token}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        setTagList(response.data);
      }
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
      setShowAlert(true);
    }
  };

  /* --- function to navigate to survey question --- */
  const getAllSurvey = async () => {
    setRowSelection({});
    setSelectedSurveyIds([]);

    try {
      setIsLoading(true);
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_SURVEY + `?token=${token}`;

      if (filterSurveyName != "") {
        requestUrl = requestUrl + `&filtername=${filterSurveyName}`;
      }
      if (filterSurveyCode != "") {
        requestUrl = requestUrl + `&filtercode=${filterSurveyCode}`;
      }
      if (filterSurveyDateRange != "") {
        requestUrl = requestUrl + `&filterdates=${filterSurveyDateRange}`;
      }
      if (filterSurveyTags.length > 0) {
        requestUrl = requestUrl + `&filtertags=${filterSurveyTags}`;
      }

      const response = await getData(requestUrl);

      setIsLoading(false);

      // console.log(response);
      if (response.status) {
        setSurveyList(response.data);
        resetFilter();
        setMessageType("success");
      } else {
        setMessageType("error");
      }
      setAlertMessage(response.message);
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
    }

    setShowAlert(true);
  };

  //function for delete survey
  const deleteMassiveSurveyHandler = async () => {
    if (selectedSurveyIds.length > 0) {
      const surveyListToBeDelete = surveyList.filter((item) =>
        selectedSurveyIds.includes(item._id)
      );

      try {
        let surveyData = {
          surveylist: surveyListToBeDelete,
        };

        let requestURL =
          url.API_BASE_URL + url.API_DELETE_MASSIVE_SURVEY + `?token=${token}`;

        const response = await putData(requestURL, surveyData);

        console.log(response);

        if (response.status) {
          setMessageType("success");
          setTimeout(() => {
            getAllSurvey();
          }, 2500);
        } else {
          setMessageType("error");
        }

        setAlertMessage(response.message);
      } catch (error) {
        setAlertMessage(error.message);
        setMessageType("error");
      }

      setShowAlert(true);
    } else {
      setMessageType("error");
      setAlertMessage("Please select atleast one course");
      setShowAlert(true);
    }
  };

  //reset filter
  const resetFilter = () => {
    setFilterSurveyName("");
    setFilterSurveyCode("");
    setfilterSurveyDateRange("");
    setfilterSurveyTags([]);
  };

  //copy url function
  const copyUrlHandler = (surveyId = null) => {
    if (surveyId) {
      let copiedUrl = `${url.BACKEND_BASE_URL}/admin/survey/feedback/${surveyId}`;

      clipboardCopy(copiedUrl);

      setAlertMessage(t("Url copied successfully"));
      setMessageType("success");
      setShowAlert(true);
    }
  };

  useEffect(() => {
    if (
      filterSurveyName != "" ||
      filterSurveyCode != "" ||
      filterSurveyDateRange != "" ||
      filterSurveyTags.length > 0
    ) {
      getAllSurvey();
    }
  }, [
    filterSurveyName,
    filterSurveyCode,
    filterSurveyDateRange,
    filterSurveyTags,
  ]);

  useEffect(() => {
    getAllTags();
    getAllSurvey();
  }, []);

  /*
   * Material React Table Column and States -----------------------------------------------------------
   *---------------------------------------------------------------------------------------------------
   */
  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: t("Name"),
        size: 300,
        Cell: ({ row }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <div className="action border-bottom-0">
              <span>{row.original.name}</span>
            </div>
            <Link
              to="#"
              className="d-flex align-items-center"
              data-bs-toggle="modal"
              data-bs-target="#copyUpdateSurveyModal"
              onClick={() => {
                setSelectedSurveyId(row.original._id);
                setIsEdit(true);
              }}
            >
              <span className="material-symbols-outlined icon-sm">edit</span>
            </Link>
            <Link
              to="#"
              className="d-flex align-items-center"
              data-bs-toggle="modal"
              data-bs-target="#copyUpdateSurveyModal"
              onClick={() => {
                setSelectedSurveyId(row.original._id);
                setIsEdit(false);
              }}
            >
              <span className="material-symbols-outlined icon-sm">
                content_copy
              </span>
            </Link>
            <button
              className="btn btn-sm btn-outline-primary"
              onClick={() => {
                copyUrlHandler(row.original._id);
              }}
            >
              Copy Url
            </button>
          </Box>
        ),
      },
      {
        accessorKey: "code",
        header: t("Code"),
      },
      {
        accessorKey: "modulename",
        header: t("Module"),
      },
      {
        accessorKey: "componentname",
        header: t("Component"),
      },
      {
        accessorKey: "moderatorname",
        header: t("Moderator"),
      },
      {
        accessorKey: "responsecount",
        header: t("Responses"),
        // Cell: ({ row }) => (
        //   <div className="responces border-bottom-0">
        //     <div className="d-flex align-items-center gap-2">
        //       <p className="position-relative mb-0">
        //         <span className="notification-dot d-block position-absolute bg-primary border border border-white rounded-circle end-0 top-0"></span>
        //         <span className="d-block material-symbols-outlined icon-md">
        //           sms
        //         </span>
        //       </p>
        //       <p className="mb-0">{row.original.responsecount}</p>
        //     </div>
        //   </div>
        // ),
      },
      {
        accessorKey: "surveydate",
        header: t("Created Date"),
      },
      {
        accessorKey: "tagstring",
        header: t("Tags"),
        Cell: ({ row }) => (
          <div className="label border-bottom-0">
            {row.original.tagstring != "" && (
              <ul className="d-flex flex-wrap gap-2 fs-xs">
                {row.original.tagstring.split(" , ").map((tag, index) => {
                  return (
                    <li
                      key={index}
                      className="px-2 py-1 gradient-light rounded-5"
                    >
                      {tag}
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        ),
      },
    ],
    [i18n.language]
  );

  useEffect(() => {
    const selectedIdsArray = Object.keys(rowSelection).filter(
      (key) => rowSelection[key]
    );

    if (selectedIdsArray.length > 0) {
      const selectedSurveyIdArr = selectedIdsArray.map((id) => {
        const [surveyId] = id.split("|");
        return surveyId;
      });

      setSelectedSurveyIds(selectedSurveyIdArr);
    } else {
      setSelectedSurveyIds([]);
    }
  }, [rowSelection]);

  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    moduleAccess.includes("MOD_SURVEY")
  ) {
    return (
      <div id="content_wrapper">
        <section className="survey-wrapper bg-white pb-5">
          <div className="container-fluid px-lg-5">
            {/* --- filter section start --- */}
            <SurveyListHeader
              reloadList={getAllSurvey}
              deleteMassiveSurveyHandler={deleteMassiveSurveyHandler}
            />
            {/* --- filter section end --- */}

            {isLoading ? (
              <div className="placeholder-glow d-flex flex-column gap-4">
                <span className="placeholder placeholder-lg bg-secondary col-12"></span>
                <span className="placeholder placeholder-lg bg-secondary col-8"></span>
                <span className="placeholder placeholder-lg bg-secondary col-4"></span>
              </div>
            ) : (
              <div className="table-wrapper">
                <MaterialReactTable
                  columns={columns} // map columns to be displayed with api data,
                  data={surveyList} // data from api to be displayed
                  enableGrouping
                  enableRowSelection // enable showing checkbox
                  getRowId={(row) => `${row._id}|${row.moderatorid}`} // map which value to select with row checkbox
                  onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                  state={{ rowSelection }} //pass our managed row selection state to the table to use
                  enableRowActions // show the action column
                  renderRowActionMenuItems={({ row }) => {
                    const [surveyId, ownerId] = row.id.split("|"); // Extracts the _id part

                    const menuItems = [
                      <MenuItem key="#">
                        <Link
                          className="dropdown-item"
                          to={`/admin/survey/question/list/${surveyId}`}
                        >
                          {t("Save Question To Survey")}
                        </Link>
                      </MenuItem>,
                      <MenuItem key="#">
                        <Link
                          className="dropdown-item"
                          to={`/admin/survey/invite/list/${surveyId}`}
                        >
                          {t("Survey Invites")}
                        </Link>
                      </MenuItem>,
                      <MenuItem key="#">
                        <Link
                          className="dropdown-item"
                          to={`/admin/survey/response/list/${surveyId}`}
                        >
                          {t("Survey Responses")}
                        </Link>
                      </MenuItem>,
                    ];

                    if (
                      userInfo.role.slug === "ADMIN" ||
                      userInfo.role.slug === "SUPER_ADMIN" ||
                      ownerId.toString() === userInfo._id.toString()
                    ) {
                      return menuItems;
                    } else {
                      return [];
                    }
                  }} // action columns menu items
                  muiTableContainerProps={{
                    sx: {
                      maxHeight: "60vh",
                    },
                  }}
                  enableStickyHeader
                />
              </div>
            )}
          </div>
        </section>

        {/* save survey modal */}
        <SaveSurveyModal afterModalClose={getAllSurvey} />

        <UpdateSurveyModal
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          selectedSurveyId={selectedSurveyId}
          setSelectedSurveyId={setSelectedSurveyId}
          afterModalClose={getAllSurvey}
        />

        {/* succes modal */}
        <SuccessMessageModal afterModalClose={getAllSurvey} />

        {/* --- add label modal start --- */}
        <AddLabelModal
          moduleName="survey"
          selectedIds={selectedSurveyIds}
          afterTagModalClose={() => {
            setTimeout(() => {
              getAllSurvey();
            }, 2500);
          }}
          setShowAlert={setShowAlert}
          setAlertMessage={setAlertMessage}
          setMessageType={setMessageType}
        />
        {/* --- add label modal end --- */}

        {/* --- filter popup --- */}
        <SurveyListFilterPopup
          setFilterSurveyName={setFilterSurveyName}
          setFilterSurveyCode={setFilterSurveyCode}
          setfilterSurveyDateRange={setfilterSurveyDateRange}
          setfilterSurveyTags={setfilterSurveyTags}
          tagList={tagList}
        />

        {/* ----------- delete warning modal ------------ */}
        <DeleteWarningModal
          onCancelDelete={() => {
            setRowSelection({});
            setSelectedSurveyIds([]);
          }}
          onDelete={deleteMassiveSurveyHandler}
          moduleName={t("survey")}
        />

        {showAlert && (
          <AlertNotification
            showAlert={showAlert}
            message={alertMessage}
            alertType={messageType}
            onClose={onAlertClose}
          />
        )}
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {" "}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};

export default SurveyListBody;
