/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

/*import url and gateway methods */
import { getData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

//import images
import { assetImages } from "constants";

//import current date
import { getCurrentDateInString } from "helper/getcurrentdatestring";

//import membership options from json
import statusOption from "data/Prod/MembershipStatus.json";
import { useTranslation } from "react-i18next";

const SubscriberDetailsPopup = ({
  title = "",
  memberId,
  setMemberId,
  afterPopupClose,
  courseId,
}) => {
  const token = localStorage.getItem("token");
  const { t } = useTranslation(); //for translation

  const [courseSubsriberId, setCourseSubsriberId] = useState(null);
  const [subscriberImage, setSubscriberImage] = useState("");
  const [subscriberName, setSubscriberName] = useState("");
  const [subscriberEmail, setSubscriberEmail] = useState("");
  const [subscriberRole, setsubscriberRole] = useState("");
  const [roleValue, setRoleValue] = useState(null);
  const [roleData, setRoleData] = useState(null);
  const [subscriberStatusValue, setSubscriberStatusValue] = useState(null);
  const [statusData, setStatusData] = useState(null);
  const [courseStatus, setCourseStatus] = useState("0");
  const [requestDate, setrequestDate] = useState("");
  const [joinedDate, setjoinedDate] = useState("");
  const [completePercent, setcompletePercent] = useState(0);
  const [certificateList, setCertificateList] = useState([]);
  const [totalScore, settotalScore] = useState("");
  const [obtainedScore, setobtainedScore] = useState("");
  const [isCertified, setisCertified] = useState(false);
  const [certificateFeedback, setCertificateFeedback] = useState("-");

  const [isSaving, setIsSaving] = useState(false);

  //function for get details of event member
  const getSubscriberDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_CERTIFICATE_DETAILS +
        `/${memberId}?token=${token}`;

      const response = await getData(requestUrl);
      console.log(response);

      if (response.status) {
        setCourseSubsriberId(response.data.coursesubscriberid);
        setSubscriberImage(response.data.image);
        setSubscriberName(response.data.name);
        setSubscriberEmail(response.data.email);
        setsubscriberRole(response.data.rolestring);
        // roleSelectionHandler(response.data.roledata);
        setCourseStatus(response.data.memberstatus);
        setjoinedDate(response.data.joindate);
        setcompletePercent(response.data.completepercent);
        setCertificateList(response.data.certificatelist);
        settotalScore(response.data.subscribertotalscore);
        setobtainedScore(response.data.subscriberobtainedscore);
        setisCertified(response.data.subsribercertified);
        setrequestDate(response.data?.requestdate);
        statusOption.map((membership) => {
          if (response.data.moderatorstatus == membership.value) {
            statusSelectionHandler(membership);
          }
        });
        setCertificateFeedback(response.data.certificationcomment || "-");
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for select role
  const roleSelectionHandler = (val) => {
    setRoleValue(val);
    setRoleData(val.value);
  };

  //function for select memebership status
  const statusSelectionHandler = (val) => {
    setSubscriberStatusValue(val);
    setStatusData(val.value);
  };

  //function for update subscriber
  const updateSubscriberHandler = async () => {
    setIsSaving(true);
    try {
      let subscriberData = {
        role: roleData,
        moderatorstatus: statusData,
        joiningdate: statusData == "1" ? getCurrentDateInString() : "",
      };

      // console.log(memberData);

      let requestUrl =
        url.API_BASE_URL +
        url.API_UPDATE_COURSE_SUBSCRIBER +
        `/${memberId}?token=${token}&courseid=${courseId}&coursesubscriberid=${courseSubsriberId}&coursememberstatus=${courseStatus}`;

      const response = await putData(requestUrl, subscriberData);

      setIsSaving(false);
      console.log(response);

      if (response.status) {
        let offCanvasPopup = document.querySelector(
          "#offcanvasSubscriberDetails"
        );
        let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
        offCanvas.hide();

        closePopupHandler();
        afterPopupClose();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for after close popup
  const closePopupHandler = () => {
    setMemberId(null);
    setCourseSubsriberId(null);
    setSubscriberImage("");
    setSubscriberName("");
    setSubscriberEmail("");
    setsubscriberRole("");
    setRoleValue(null);
    setRoleData([]);
    setSubscriberStatusValue(null);
    setStatusData(null);
  };

  useEffect(() => {
    if (memberId) {
      getSubscriberDetails();
    }
  }, [memberId]);

  return (
    <div
      className="offcanvas lg offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="offcanvasSubscriberDetails"
      aria-labelledby="offcanvasSubscriberDetailsLabel"
    >
      <div className="offcanvas-header align-items-start gradient-light p-4">
        <div className="d-flex flex-column gap-2">
          <div className="d-flex align-items-center gap-3">
            <p className="fs-sm" style={{ minWidth: "100px" }}>
              Course
            </p>
            <h3 className="m-0">{title}</h3>
          </div>
          <div className="d-flex align-items-center gap-3 fs-sm">
            <p style={{ minWidth: "100px" }}>Subscriber</p>
            <div className="profile d-flex align-items-center gap-2">
              <div
                className="avatar rounded-circle overflow-hidden"
                style={{ width: "35px", height: "35px" }}
              >
                <img
                  src={
                    subscriberImage === ""
                      ? assetImages.defaultUser
                      : url.SERVER_URL + subscriberImage
                  }
                  alt="subscriber"
                  className="w-100 h-100 object-fit-cover object-center"
                />
              </div>
              <p className="fw-semibold mb-0">{subscriberName}</p>
            </div>
          </div>
          <div className="d-flex align-items-center gap-3 fs-sm">
            <p className="mb-0" style={{ minWidth: "100px" }}>
              Joined Date
            </p>
            <p className="d-flex align-items-center gap-1">
              <i className="material-symbols-outlined icon-lg">
                calendar_month
              </i>{" "}
              {joinedDate}
            </p>
          </div>
          <div className="d-flex align-items-center gap-3 fs-sm">
            <p style={{ minWidth: "100px" }}>Completed</p>
            <div className="d-flex align-items-center gap-2">
              <p>{completePercent}%</p>
              <div
                className="progress"
                role="progressbar"
                aria-valuenow="100"
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ width: "90px", height: "8px" }}
              >
                <div
                  className="progress-bar"
                  style={{ width: `${completePercent}%` }}
                ></div>
              </div>
            </div>
          </div>
        </div>
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={closePopupHandler}
        ></button>
      </div>
      <div className="offcanvas-body d-flex flex-column p-4">
        {/* ----- member edit form section start ----- */}
        <form onSubmit={(e) => e.preventDefault()} className="pt-4 my-4">
          <div className="form-group mb-3 mb-sm-4">
            <label htmlFor="status" className="d-block fs-sm fw-semibold mb-2">
              {t("Change Status")}
            </label>

            <Select
              className="w-100"
              placeholder={t("Select status")}
              options={statusOption}
              value={subscriberStatusValue}
              onChange={(val) => statusSelectionHandler(val)}
            />
          </div>

          <div className="action d-flex justify-content-between gap-3">
            <Link
              to="#"
              className="btn btn-outline-primary"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              {t("Close")}
            </Link>
            <button
              type="button"
              className="btn btn-primary"
              onClick={updateSubscriberHandler}
              disabled={isSaving ? true : false}
              style={{
                cursor: isSaving ? "not-allowed" : "pointer",
              }}
            >
              {t("Save")}
              {isSaving && (
                <div
                  className="mx-2 spinner-border spinner-border-sm"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </button>
          </div>
        </form>
        {/* ----- member edit form section end ----- */}

        <div className="table-wrapper mb-4">
          <div className="table-responsive">
            <table className="table align-middle fs-sm text-nowrap border-0 mb-0">
              <thead>
                <tr>
                  <th className="lesson bg-transparent fs-md fw-semibold border-0">
                    Lesson
                  </th>
                  <th className="finish-date bg-transparent fs-md fw-semibold border-0">
                    Finish Date
                  </th>
                  <th className="total-task bg-transparent fs-md fw-semibold border-0">
                    Total Task
                  </th>
                  <th className="accepted bg-transparent fs-md fw-semibold border-0">
                    Accepted
                  </th>
                  <th className="total-score bg-transparent fs-md fw-semibold border-0">
                    Total Score
                  </th>
                  <th className="obtain-score bg-transparent fs-md fw-semibold border-0">
                    Obtain Score
                  </th>
                </tr>
              </thead>
              <tbody>
                {certificateList.map((certificate, index) => {
                  return (
                    <tr key={index}>
                      <td className="lesson border-bottom-0">
                        <p className="fw-semibold">{certificate.title}</p>
                      </td>
                      <td className="finish-date border-bottom-0">
                        <p className="d-flex align-items-center gap-2">
                          <span className="d-block material-symbols-outlined icon-md">
                            calendar_month
                          </span>
                          <span className="d-block">
                            {certificate.lessoncompletedate}
                          </span>
                        </p>
                      </td>
                      <td className="total-task border-bottom-0">
                        <p>{certificate.totaltaskcount}</p>
                      </td>
                      <td className="accepted border-bottom-0">
                        <p>{certificate.acceptedtaskcount}</p>
                      </td>
                      <td className="total-score border-bottom-0">
                        <p>{certificate.totalscore}</p>
                      </td>
                      <td className="obtain-score border-bottom-0">
                        <p>{certificate.obtainedscore}</p>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-lg-4">
            <div className="px-3 py-4 body-bg fs-sm text-center rounded-10">
              <p className="mb-2">Subscriber Score</p>
              <h3 className="text-primary m-0">
                {obtainedScore} out of {totalScore}
              </h3>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="px-3 py-4 body-bg fs-sm text-center rounded-10">
              <p className="mb-2">Certification Approval</p>
              {isCertified ? (
                <h3 className="text-success m-0 d-flex align-items-center justify-content-center gap-2">
                  <span className="d-block material-symbols-outlined icon-fill ">
                    check_circle
                  </span>
                  Approved !
                </h3>
              ) : (
                <h3 className="text-primary m-0">-</h3>
              )}
            </div>
          </div>
          <div className="col-lg-4">
            <div className="px-3 py-4 body-bg fs-sm text-center rounded-10">
              <p className="mb-2">Certification Feedback</p>

              <h3 className="text-primary m-0">{certificateFeedback}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriberDetailsPopup;
