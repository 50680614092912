import React from "react";

const ProjectCard = () => {
  return (
    <div className="col-lg-4 col-md-6 mb-4 mb-lg-0">
      <div className="project h-100 bg-gray-200 p-3 p-lg-4 d-flex gap-3 rounded-10 shadow-sm">
        <div className="info flex-fill">
          <p className="fw-semibold mb-3">Project</p>
          <div className="d-flex align-items-end gap-1 mb-1">
            <h2 className="fw-bold lh-1 mb-0">400</h2>
            <h3 className="text-gray fw-bold lh-1 mb-1">/680</h3>
          </div>
          <p className="fs-xs d-flex align-items-center">
            <span className="d-block material-symbols-outlined text-success icon-md me-1">
              trending_up
            </span>
            <span className="d-block text-success">10%</span>
            <span className="d-block text-gray">
              &nbsp;Increase from Last Month
            </span>
          </p>
        </div>
        <div
          className="icon flex-shrink-0 d-flex align-items-center justify-content-center rounded-18"
          style={{
            width: "3.75rem",
            height: "3.75rem",
            backgroundColor: "rgb(130 128 255 / 20%)",
            color: "#8280FF",
          }}
        >
          <span
            className="d-block material-symbols-outlined icon-fill"
            style={{ fontSize: "2rem" }}
          >
            rocket_launch
          </span>
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;
