/* eslint-disable */
import React, { useEffect, useContext, useState } from "react";
import { Link, useParams } from "react-router-dom";

/*import url and gateway methods */
import { postData, putData, uploadMultipleFile, getData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

//import translation function for language translation
import { useTranslation } from "react-i18next";

import TaskStatusOptions from "data/Prod/TaskStatus.json";
import TaskPriority from "data/Prod/TaskPriority.json";

import SaveTaskTab from "./Tabs/SaveTaskTab";
import ViewTaskTab from "./Tabs/ViewTaskTab";
import LogTab from "./Tabs/LogTab";
import AlertNotification from "../AlertNotification/AlertNotification";
import NoteSection from "../NoteComponent/NoteSection";
import TaskModules from "data/Prod/TaskModuleListOptions.json";

const SaveTaskBody = () => {
  const params = useParams();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const token = localStorage.getItem("token"); // token

  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();

  const [showTaskTab, setshowTaskTab] = useState(false);
  const [showLogTab, setshowLogTab] = useState(false);

  const [isModerator, setIsModerator] = useState(false);

  const [taskDetailsObj, setTaskDetailsObj] = useState({});

  //alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  //* Switch between task and log tab based on the tabName parameter */
  const switchTabs = (tabName) => {
    setshowTaskTab(false);
    setshowLogTab(false);
    if (tabName == "task") {
      setshowTaskTab(true);
    }
    if (tabName == "log") {
      setshowLogTab(true);
    }
  };

  const getTaskDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_TASK_DETAILS +
        `/${params.id}?token=${token}`;

      const response = await getData(requestUrl);

      console.log("response in view task details", response);

      if (response.status && response.data) {
        if (
          userInfo.role.slug === "ADMIN" ||
          userInfo.role.slug === "SUPER_ADMIN" ||
          userInfo._id.toString() === response.data.moderator.toString()
        ) {
          setIsModerator(true);
        } else {
          setIsModerator(false);
        }

        setTaskDetailsObj(response.data);
        setMessageType("success");
      } else {
        setMessageType("error");
      }
      setAlertMessage(response.message);
    } catch (error) {
      setMessageType("error");
      setAlertMessage(error.message);
    }
    setShowAlert(true);
  };

  const onAlertClose = () => {
    // Set the state variable 'showAlert' to false to hide the alert.
    setShowAlert(false);

    // Clear the alert message by setting the state variable 'alertMessage' to an empty string.
    setAlertMessage("");

    // Clear the alert type by setting the state variable 'messageType' to an empty string.
    setMessageType("");
  };

  useEffect(() => {
    switchTabs("task");
  }, []);

  useEffect(() => {
    if (params.id) {
      getTaskDetails();
    }
  }, [params.id]);

  return (
    <div id="content_wrapper">
      <section className="crm-wrapper bg-white pb-5 pt-3">
        <div className="container-fluid px-lg-5">
          <div className="row">
            <div className="col-lg-8 mb-4 mb-lg-0">
              <div className="create-lead-container p-3 p-md-4 border border-gray-300 rounded-10 shadow-sm">
                <ul className="nav nav-tabs w-100 flex-nowrap fs-sm lh-1 fw-semibold text-center border-0 text-nowrap overflow-auto mt-3">
                  {/* task tab title */}
                  <li className="nav-item flex-fill" role="presentation">
                    <Link
                      to="#"
                      className={`text-black d-block px-3 py-3 position-relative ${
                        showTaskTab && "active"
                      }`}
                      onClick={() => {
                        switchTabs("task");
                      }}
                    >
                      {t("Tasks Details")}
                    </Link>
                  </li>
                  {/* log tab title */}

                  {params.id && (
                    <li className="nav-item flex-fill" role="presentation">
                      <Link
                        to="#"
                        className={`text-black d-block px-3 py-3 position-relative ${
                          showLogTab && "active"
                        }`}
                        onClick={() => {
                          switchTabs("log");
                        }}
                      >
                        {t("Logs")}
                      </Link>
                    </li>
                  )}
                </ul>

                <div className="tab-content h-100" id="myTabContent">
                  {/* task view tab */}
                  {showTaskTab ? (
                    isModerator ? (
                      <SaveTaskTab
                        moduleName={"taskdetails"}
                        taskDetailsObj={taskDetailsObj}
                        setShowAlert={setShowAlert}
                        setAlertMessage={setAlertMessage}
                        setMessageType={setMessageType}
                      />
                    ) : (
                      <ViewTaskTab taskDetailsObj={taskDetailsObj} />
                    )
                  ) : null}
                  {/* log tab */}
                  {showLogTab && (
                    <LogTab
                      taskContactId={taskDetailsObj?.taskcontactid}
                      taskLeadId={taskDetailsObj?.taskleadid}
                      taskProjectId={taskDetailsObj?.taskprojectid}
                      taskHours={taskDetailsObj?.hours}
                      taskStartDate={taskDetailsObj?.startdate}
                      taskEndDate={taskDetailsObj?.enddate}
                      taskPercentResponse={taskDetailsObj?.taskpercent}
                      taskStatusValueResponse={() => {
                        let statusValue = null;
                        TaskStatusOptions.map((statusData) => {
                          if (statusData.label === taskDetailsObj?.taskstatus) {
                            statusValue = statusData;
                          }
                        });
                        return statusValue;
                      }}
                      taskStatusDataResponse={taskDetailsObj?.taskstatus}
                      setShowAlert={setShowAlert}
                      setAlertMessage={setAlertMessage}
                      setMessageType={setMessageType}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <NoteSection
                taskId={params.id}
                setShowAlert={setShowAlert}
                setAlertMessage={setAlertMessage}
                setMessageType={setMessageType}
              />
            </div>
          </div>
        </div>
      </section>

      {showAlert && (
        <AlertNotification
          showAlert={showAlert}
          message={alertMessage}
          alertType={messageType}
          onClose={onAlertClose}
        />
      )}
    </div>
  );
};

export default SaveTaskBody;
