import { Link } from "react-router-dom";
import BreadCrumb from "components/Common/BreadCrumb/BreadCrumb";

const ProjectAllMemberHeader = ({ reloadList = () => {} }) => {
  const breadCrumbText = [{ title: "Members" }];

  return (
    <div className="filter-container py-3">
      <div className="row align-items-center">
        <div className="col-md-5 col-lg-6 d-none d-md-block mb-3 mb-md-0">
          <BreadCrumb breadCrumbText={breadCrumbText} bottom={true} />
        </div>
        <div className="col-md-7 col-lg-6 d-flex justify-content-end gap-1 gap-sm-2">
          <Link
            className="btn btn-gray d-flex align-items-center"
            to="#"
            onClick={reloadList}
          >
            <span className="d-block material-symbols-outlined icon-md">
              refresh
            </span>
          </Link>

          {/* <Link
            to="#"
            className="btn btn-gray d-flex align-items-center gap-1"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasFilter"
            aria-controls="offcanvasFilter"
          >
            <span className="d-block material-symbols-outlined icon-md">
              tune
            </span>
            <span className="d-block">Filter</span>
          </Link> */}
        </div>
      </div>
    </div>
  );
};

export default ProjectAllMemberHeader;
