import Header from "components/Common/Header/Header";
import ProjectManagementGraphBody from "components/ProjectManagementComponents/FullReport/GraphView/ProjectManagementGraphBody";
import React, { useEffect } from "react";

const ProjectGraphView = () => {
  useEffect(() => {
    document.title = "Project Management";
  }, []);

  return (
    <main id="app">
      {/* ---- common header ---- */}
      <Header moduleName="projects" />

      <ProjectManagementGraphBody />
    </main>
  );
};

export default ProjectGraphView;
