/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

import { getData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

const TicketsListFilterPopup = ({
  setfilterStartDate,
  setfilterEndDate,
  setFilterEvent,
  setFilterCompanyIds,
  setFilterName,
  setFilterEmail,
  setFilterLocation,
  setFilterEntrycode
}) => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const [companyInput, setcompanyInput] = useState("");
  const [companyList, setCompanyList] = useState([]);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [eventName, setEventName] = useState("");

  const [companyValue, setCompanyValue] = useState(null);
  const [companyIds, setCompanyIds] = useState([]);

  const [leadName, setLeadName] = useState("");
  const [leadEmail, setLeadEmail] = useState("");
  const [locationName, setLocationName] = useState("");

  const [entryCode, setEntryCode] = useState("");

  const [errorMessage, seterrorMessage] = useState("");

  /* hook for validation */
  const [validation, setValidation] = useState({
    dateWarning: false,
  });

  //function for get all companies
  const getCompanyList = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_COMPANY +
        `?token=${token}&roleslug=${
          userInfo.role ? userInfo.role.slug : ""
        }&filtername=${companyInput}`;

      console.log("url of company------>", requestUrl);

      const response = await getData(requestUrl);

      console.log("response in company------>", response);

      if (response.status) {
        setCompanyList(
          response.data.map((item) => ({
            label: item.name,
            value: item._id,
          }))
        );
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //select company
  const companySelectionHandler = (val) => {
    setCompanyValue(val);
    setCompanyIds(val.map((item) => item.value));
  };

  /* function to validate form */
  const validate = () => {
    let isValid = true;

    if (startDate !== "" && endDate === "") {
      setValidation((prevState) => ({ ...prevState, dateWarning: true }));
      isValid = false;
    }

    return isValid;
  };

  //function for assign search data for filter and close pop up
  const searchHandler = () => {
    if (validate()) {
      seterrorMessage("");

      if (startDate !== "" && endDate !== "") {
        setfilterStartDate(startDate);
        setfilterEndDate(endDate);
      }
      setFilterEvent(eventName);
      setFilterCompanyIds(companyIds);

      setFilterName(leadName);
      setFilterEmail(leadEmail);
      setFilterLocation(locationName);

      setFilterEntrycode(entryCode);

      // close the offcanvas of filter
      let offCanvasPopup = document.querySelector("#offcanvasTicketListFilter");
      let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
      offCanvas.hide();
      resetHandler();
    } else {
      seterrorMessage("Please fill the required fields");
    }
  };

  //function for reset search
  const resetHandler = () => {
    setStartDate("");
    setEndDate("");
    setEventName("");
    setCompanyValue(null);
    setCompanyIds([]);

    setLeadName("");
    setLeadEmail("");
    setLocationName("");
  };

  useEffect(() => {
    if (companyInput.length > 1) {
      getCompanyList();
    }
  }, [companyInput]);

  const companyOptionsToShow = companyList.length > 0 ? companyList : [];

  return (
    <div
      className="offcanvas offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="offcanvasTicketListFilter"
      aria-labelledby="offcanvasCompanyListFilterLabel"
    >
      <div className="offcanvas-header justify-content-end p-4 pb-0">
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div className="offcanvas-body p-4">
        <form onSubmit={(e) => e.preventDefault()}>
          {/* date range */}
          <div className="form-group mb-4">
            <label htmlFor="Name" className="d-block fs-sm fw-semibold mb-2">
              <h3>Dates</h3>{" "}
            </label>
            <div className="d-flex gap-2">
              <input
                type="date"
                className="form-control fs-sm shadow-none"
                placeholder="Start date"
                value={startDate}
                onChange={(e) => {
                  setStartDate(e.target.value);
                  setValidation((prevState) => ({
                    ...prevState,
                    dateWarning: false,
                  }));
                  seterrorMessage("");
                }}
              />
              <input
                type="date"
                className="form-control fs-sm shadow-none"
                placeholder="End date"
                value={endDate}
                onChange={(e) => {
                  setEndDate(e.target.value);
                  setValidation((prevState) => ({
                    ...prevState,
                    dateWarning: false,
                  }));
                  seterrorMessage("");
                }}
              />
            </div>

            {validation.dateWarning && (
              <div className="error-message mt-3">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>Please enter end date!</span>
                </p>
              </div>
            )}
          </div>

          {/* Event */}
          <div className="form-group mb-4">
            <label htmlFor="Phone" className="d-block fs-sm fw-semibold mb-2">
              <h3>Event</h3>{" "}
            </label>
            <input
              type="text"
              className="form-control fs-sm shadow-none"
              placeholder="Event Name"
              value={eventName}
              onChange={(e) => setEventName(e.target.value)}
            />
          </div>

          {/* buyer name */}
          <div className="form-group mb-4">
            <label htmlFor="Name" className="d-block fs-sm fw-semibold mb-2">
              <h3>Entry Code</h3>{" "}
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="customer entry code"
              value={entryCode}
              onChange={(e) => setEntryCode(e.target.value)}
            />
          </div>

          {/* Company */}
          <div className="form-group mb-4">
            <label
              htmlFor="Position"
              className="d-block fs-sm fw-semibold mb-2"
            >
              <h3>Organization</h3>{" "}
            </label>
            <Select
              placeholder="Type Company or Organization name"
              isMulti
              options={companyOptionsToShow}
              value={companyValue}
              onChange={(val) => {
                companySelectionHandler(val);
              }}
              onInputChange={(val) => {
                setcompanyInput(val);
              }}
            />
          </div>

          {/* buyer name */}
          <div className="form-group mb-4">
            <label htmlFor="Name" className="d-block fs-sm fw-semibold mb-2">
              <h3>Client Name</h3>{" "}
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="Name"
              value={leadName}
              onChange={(e) => setLeadName(e.target.value)}
            />
          </div>

          {/* buyer email */}
          <div className="form-group mb-4">
            <label htmlFor="email" className="d-block fs-sm fw-semibold mb-2">
              <h3>Client Email</h3>{" "}
            </label>
            <input
              type="text"
              className="form-control fs-sm shadow-none"
              placeholder="Email"
              value={leadEmail}
              onChange={(e) => setLeadEmail(e.target.value)}
            />
          </div>

          {/* Location */}
          <div className="form-group mb-4">
            <label
              htmlFor="Position"
              className="d-block fs-sm fw-semibold mb-2"
            >
              <h3>Location</h3>{" "}
            </label>
            <input
              type="text"
              className="form-control fs-sm shadow-none"
              placeholder="Location"
              value={locationName}
              onChange={(e) => setLocationName(e.target.value)}
            />
          </div>

          {errorMessage === "" ? null : (
            <div className="error-message mt-3">
              <p className="d-flex align-items-center gap-1 text-danger">
                <span className="material-symbols-outlined">warning</span>
                <span>{errorMessage}!</span>
              </p>
            </div>
          )}

          {/* ------ search button start ----- */}
          <button
            type="button"
            className="btn btn-primary fs-sm fw-normal w-100 mt-4"
            onClick={searchHandler}
          >
            Search
          </button>
          {/* ------ search button end ----- */}
        </form>
      </div>
    </div>
  );
};

export default TicketsListFilterPopup;
