/* eslint-disable */
import React, { useEffect, useState, useRef } from "react";
import { Link, useParams, useHistory } from "react-router-dom";

import { useTranslation } from "react-i18next";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import {
  postData,
  getData,
  putData,
  uploadSingleFile,
  uploadMultipleFile,
  deleteData,
} from "utils/Gateway";

import paymentDuaritonOption from "data/Prod/PaymentDuration.json";
import ecoleadsshowoptions from "data/Prod/EcoleadsShowWithOptions.json";

import AddEditEducationModal from "components/Common/Modal/AddEditEducationModal";
import AddEditExperienceModal from "components/Common/Modal/AddEditExperienceModal";
import AddEditSkillPointModal from "components/Common/Modal/AddEditSkillPointModal";
import AfterSaveLeadModal from "../Modal/AfterSaveLeadModal";
import BreadCrumb from "components/Common/BreadCrumb/BreadCrumb";
import TabsHeader from "components/Common/TabsHeader/TabsHeader";
import { crmCommonHeaderLinkLists } from "helper/CrmHelper/CrmHelper";
import AddEditCetificateModal from "components/Common/Modal/AddEditCetificateModal";

import { getAllCategories } from "helper/CategoryHelper/CategoryHelper";

import SubscribeOptions from "data/Prod/SubscribeOptions.json";

import AddEditPortfolioLinkModal from "components/Common/Modal/AddEditPortfolioLinkModal";

import AlertNotification from "components/Common/AlertNotification/AlertNotification";
import DeleteWarningModal from "components/Common/Modal/DeleteWarningModal";
import AboutAccordion from "./AccordionComponents/AboutAccordion";
import AddressAccordion from "./AccordionComponents/AddressAccordion";
import BioDataAccordion from "./AccordionComponents/BioDataAccordion";
import EducationAccordion from "./AccordionComponents/EducationAccordion";
import ExperienceAccordion from "./AccordionComponents/ExperienceAccordion";
import SkillPointAccordion from "./AccordionComponents/SkillPointAccordion";
import TechnicalCertficateAccordion from "./AccordionComponents/TechnicalCertficateAccordion";
import PortfolioAccordion from "./AccordionComponents/PortfolioAccordion";
import BussinessAccordion from "./AccordionComponents/BussinessAccordion";
import EcoleadAccordion from "./AccordionComponents/EcoleadAccordion";

const SaveCrmLeadListBody = () => {
  const todayValue = new Date().toISOString().split("T")[0];

  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();

  const fullURL = window.location.href;
  const params = useParams();
  const history = useHistory();

  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const ecosystemSlug = localStorage.getItem("ecosystemslug");

  const imageFileInputRef = useRef(null);
  const docFileInputRef = useRef(null);
  const videoFileInputRef = useRef(null);

  // common header object
  const commonHeaderObject = crmCommonHeaderLinkLists(t);

  /* ---- bread crumb text ---- */
  const breadcrumbText = [
    { title: "CRM", link: "/admin/crm/lead/list" },
    { title: t("LeadsSave") },
  ];

  const [companyList, setCompanyList] = useState([]);
  const [allSkills, setAllSkills] = useState([]);
  const [sectorList, setSectorList] = useState([]);
  const [meetingTypeList, setmeetingTypeList] = useState([]);
  const [previousRelationList, setPreviousRelationList] = useState([]);
  const [possibleServiceList, setPossibleServiceList] = useState([]);
  const [companyInput, setcompanyInput] = useState("");
  const [companySearchList, setCompanySearchList] = useState([]);
  const [followersInput, setFollowersInput] = useState("");
  const [followersOptions, setFollowersOptions] = useState([]);

  const [docFileUploading, setDocFileUploading] = useState(false);
  const [uploadedDocFiles, setUploadedDocFiles] = useState([]);
  const [uploadedDocFileIds, setUploadedDocFileIds] = useState([]);
  const [videoUploading, setVideoUploading] = useState(false);
  const [uploadedVideoFiles, setUploadedVideoFiles] = useState([]);
  const [uploadedVideoFileIds, setUploadedVideoFileIds] = useState([]);

  /* ----- education block requirement start ------*/
  const [educationData, setEducationData] = useState({
    degree: "",
    year: "",
    grade: "",
    institute: "",
  });
  const [educationListBlock, setEducationListBlock] = useState([]);
  const [educationIndex, setEducationIndex] = useState(null);
  /* ----- education block requirement end ------*/

  /* ----- experience block requirement start ------*/
  const [experienceData, setExperienceData] = useState({
    companyid: null,
    company: "",
    position: "",
    start: "",
    end: "",
    ctc: "",
    responsibilities: "",
  });
  const [experienceListBlock, setExperienceListBlock] = useState([]);
  const [experienceIndex, setExperienceIndex] = useState(null);
  /* ----- experience block requirement end ------*/

  /* ----- skill point block requirement start ------*/
  const [skillPointData, setSkillPointData] = useState({
    skill: null,
    skillvalue: null,
    skillname: "",
    points: "",
    experienceyear: "",
  });
  const [skillPointListBlock, setSkillPointListBlock] = useState([]);
  const [skillPointIndex, setSkillPointIndex] = useState(null);
  /* ----- skill point block requirement end ------*/

  /* ----- Certificates block requirement start ------*/
  const [technicalExperienceData, settechnicalExperienceData] = useState({
    name: "",
    companyvalue: null,
    companyname: "",
    start: "",
    end: "",
    description: "",
    grade: "",
  });
  const [technicalExpBlock, settechnicalExpBlock] = useState([]);
  const [technicalExpIndex, settechnicalExpIndex] = useState(null);
  /* ----- Certificates block requirement end ------*/

  /* ----- portfoilio requiremnet start -----*/
  const [portfolioData, setPortfolioData] = useState("");
  const [portfolioBlock, setPortfolioBlock] = useState([]);
  const [portfolioIndex, setPortfolioIndex] = useState(null);
  /* ----- portfoilio requiremnet end -----*/

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [companyValue, setCompanyValue] = useState(null);
  const [company, setcompany] = useState("");
  const [position, setposition] = useState("");
  const [followrsValue, setFollowrsValue] = useState(null);
  const [selectedFollowers, setSelectedFollowers] = useState([]);
  const [resumeTitle, setresumeTitle] = useState("");
  const [resumeSummary, setresumeSummary] = useState("");
  const [resumeDetails, setresumeDetails] = useState("");
  const [minSalary, setminSalary] = useState("");
  const [maxSalary, setmaxSalary] = useState("");
  const [paymentDuration, setpaymentDuration] = useState(null);
  const [uploadedPreviewImage, setUploadedPreviewImage] = useState("");
  const [uploadedImageId, setUploadedImageId] = useState(null); // use state for uploaded image id
  const [isUploading, setIsUploading] = useState(false);
  const [customerComment, setCustomerComment] = useState("");
  const [ownerComment, setOwnerComment] = useState("");
  const [mentorInput, setMentorInput] = useState("");
  const [mentorOptions, setMentorOptions] = useState([]);
  const [mentorValue, setMentorValue] = useState(null);
  const [mentorId, setMentorId] = useState(null);
  const [mentorEmail, setMentorEmail] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [city, setCity] = useState("");
  const [stateName, setStateName] = useState("");
  const [country, setCountry] = useState("");
  const [address, setAddress] = useState("");
  const [skillValue, setSkillValue] = useState(null);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [sectorValue, setsectorValue] = useState(null);
  const [selectedSectors, setselectedSectors] = useState([]);
  const [billing, setbilling] = useState("");
  const [countOfEmployees, setcountOfEmployees] = useState("");
  const [shareLeadsValue, setshareLeadsValue] = useState(null);
  const [shareLeadWith, setshareLeadWith] = useState("");
  const [responsibility, setresponsibility] = useState("");
  const [opportunityTitle, setopportunityTitle] = useState("");
  const [meetingTypeValue, setmeetingTypeValue] = useState(null);
  const [selectedMeetingTypes, setselectedMeetingTypes] = useState([]);
  const [relationshipValue, setrelationshipValue] = useState(null);
  const [selectedRelationShips, setselectedRelationShips] = useState([]);
  const [possibleServiceValue, setpossibleServiceValue] = useState(null);
  const [selectedServices, setselectedServices] = useState([]);
  const [searching, setsearching] = useState("");
  const [interviewCost, setinterviewCost] = useState("");
  const [comments, setcomments] = useState("");
  const [interest, setInterest] = useState("");
  const [linkdin, setLinkdin] = useState("");
  const [website, setWebsite] = useState("");
  const [leadHobbiesValue, setLeadHobbiesValue] = useState(null);
  const [leadSelectedHobbies, setLeadSelectedHobbies] = useState([]);
  const [subscribeMailValue, setsubscribeMailValue] = useState(null);
  const [subscribeMailStatus, setSubscribeMailStatus] = useState("0");
  const [subscribeAcceptOn, setSubscribeAcceptOn] = useState("");
  const [subscribeDeninedOn, setSubscribeDeninedOn] = useState("");
  const [lastTransaction, setlastTransaction] = useState("");
  const [transactionModule, settransactionModule] = useState("");
  const [transactionElement, settransactionElement] = useState("");
  const [transactionAmount, settransactionAmount] = useState("");
  const [transactionDate, settransactionDate] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [savedLeadId, setSavedLeadId] = useState(null); //for redirect into modal

  const [isAuthenticatedUser, setisAuthenticatedUser] = useState(false);

  /* hooks for validation */
  const [validation, setValidation] = useState({
    firstNameWarning: false,
    emailAddressWarning: false,
    notValidMail: false,
    companyWarning: false,
    searchingWarning: false,
    costWarning: false,
    commentWarning: false,
    responsibilityWarning: false,
    meetingTypeWarning: false,
    relationshipWarning: false,
    serviceTypeWarning: false,
    opportunityTitleWarning: false,
  });

  //alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  const loadingCircle = [1, 2, 3, 4, 5, 6];

  const onFollowersInputChange = (val) => {
    setFollowersInput(val);
  };

  //function for get followers list
  const getFollowersList = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_USERLIST_OPTIONS +
        `?token=${token}&userstringinput=${followersInput}`;

      const response = await getData(requestUrl);

      // console.log(response);

      if (response.status) {
        if (response.data.length > 0) {
          setFollowersOptions(response.data);
        }
      } else {
        setMessageType("error");
        setAlertMessage(response.message);
        setShowAlert(true);
      }
    } catch (error) {
      setMessageType("error");
      setAlertMessage(error.message);
      setShowAlert(true);
    }
  };

  //function for get user details
  const getMentorList = async () => {
    try {
      const requestUrl =
        `${url.API_BASE_URL}${url.API_GET_USERLIST_OPTIONS}` +
        `?token=${token}&userstringinput=${mentorInput}`;

      const response = await getData(requestUrl);

      if (response.status && response.data.length > 0) {
        setMentorOptions(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all companies
  const getCompanySearchList = async () => {
    try {
      const requestUrl =
        `${url.API_BASE_URL}${url.API_GET_ALL_COMPANY}` +
        `?token=${token}&roleslug=${userInfo.role.name}&filtername=${companyInput}`;

      const response = await getData(requestUrl);
      if (response.status) {
        const formattedData = response.data.map((item) => ({
          label: item.name,
          value: item.name,
        }));

        setCompanySearchList(formattedData);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //get all skills
  const getAllSkills = async () => {
    try {
      const requestUrl = `${url.API_BASE_URL}${url.API_GET_CATEGORIES}?token=${token}&slug=skills&ecosystemslug=${ecosystemSlug}`;
      const response = await getData(requestUrl);

      if (response.status) {
        setAllSkills(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //get all sectors
  const getAllSector = async () => {
    try {
      const requestUrl = `${url.API_BASE_URL}${url.API_GET_CATEGORIES}?token=${token}&slug=SECTOR&ecosystemslug=${ecosystemSlug}`;
      const response = await getData(requestUrl);

      if (response.status) {
        setSectorList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //get all meeting types
  const getAllMeetingTypes = async () => {
    try {
      const requestUrl = `${url.API_BASE_URL}${url.API_GET_CATEGORIES}?token=${token}&slug=MEETING&ecosystemslug=${ecosystemSlug}`;
      const response = await getData(requestUrl);

      if (response.status) {
        setmeetingTypeList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //get all previous relationship
  const getAllPreviousRelationship = async () => {
    try {
      const requestUrl = `${url.API_BASE_URL}${url.API_GET_CATEGORIES}?token=${token}&slug=RELATIONSHIP&ecosystemslug=${ecosystemSlug}`;
      const response = await getData(requestUrl);

      if (response.status) {
        setPreviousRelationList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //get all posible services
  const getAllPosibleServices = async () => {
    try {
      const requestUrl = `${url.API_BASE_URL}${url.API_GET_CATEGORIES}?token=${token}&slug=POSSIBLE_SERVICES&ecosystemslug=${ecosystemSlug}`;
      const response = await getData(requestUrl);

      if (response.status) {
        setPossibleServiceList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //select company
  const companySelectionHandler = (val) => {
    val
      ? (setCompanyValue(val), setcompany(val.value))
      : (setCompanyValue(null), setcompany(""));
  };

  //select sector
  const sectorSelectionHandler = (val) => {
    setsectorValue(val);
    setselectedSectors(val.map((item) => item.value));
  };

  //select share with
  const shareSelectionHandler = (val) => {
    if (val) {
      setshareLeadsValue(val);
      setshareLeadWith(val.value);
    } else {
      setshareLeadsValue(null);
      setshareLeadWith("");
    }
  };

  const meetingTypeSelectionHandler = (val) => {
    setmeetingTypeValue(val);
    setselectedMeetingTypes(val.map((item) => item.value));
  };

  const relationshipSelectionHandler = (val) => {
    setrelationshipValue(val);
    setselectedRelationShips(val.map((item) => item.value));
  };

  const serviceSelectionHandler = (val) => {
    setpossibleServiceValue(val);
    setselectedServices(val.map((item) => item.value));
  };

  //function for get user input
  const mentorInputHandler = (val) => {
    setMentorInput(val);
  };

  //function user Selection
  const mentorSelectionHandler = (val) => {
    val
      ? (setMentorValue(val), setMentorId(val._id), setMentorEmail(val.email))
      : (setMentorValue(null), setMentorId(null), setMentorEmail(""));
  };

  //function for get all company list
  const getAllCompanyList = async () => {
    try {
      const requestUrl = `${url.API_BASE_URL}${url.API_GET_ALL_COMPANY_LIST}?token=${token}&roleslug=${userInfo.role.name}`;
      const response = await getData(requestUrl);

      if (response.status) {
        setCompanyList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //select followers
  const followersSelectionHandler = (val) => {
    if (val) {
      setFollowrsValue(val);
      setSelectedFollowers(val.map((item) => item.value));
    } else {
      setFollowrsValue(null);
      setSelectedFollowers([]);
      setFollowersInput("");
      setFollowersOptions([]);
    }
  };

  //function for open image imput
  const openImageFileInput = () => {
    imageFileInputRef.current.click();
  };

  //function for image upload
  const imageUploadHandler = async (e) => {
    const file = e.target.files[0];
    setIsUploading(true);

    try {
      const requestUrl = `${url.API_BASE_URL}${url.API_SINGLE_FILE_UPLOAD}?token=${token}`;
      const response = await uploadSingleFile(requestUrl, file);

      if (response.status) {
        const { data } = response;
        setIsUploading(false);
        setUploadedPreviewImage(`${url.SERVER_URL}${data.path}`);
        setUploadedImageId(data._id);
        resetLeadImageFile();
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  //function for close image file
  const closeLeadImageFileHandler = () => {
    setUploadedPreviewImage("");
    setUploadedImageId(null);
    resetLeadImageFile();
  };

  // function for clear file value
  const resetLeadImageFile = () => {
    const file = document.getElementById("uploadedLeadImageFile");
    if (file) {
      file.value = null;
    }
  };

  //function for open doc imput
  const openDocFileInput = () => {
    docFileInputRef.current.click();
  };

  //function for upload doc files
  const docResumeUploadHandler = async (e) => {
    try {
      setDocFileUploading(true);
      const docFiles = e.target.files;
      const requestUrl =
        url.API_BASE_URL + url.API_MULTIPLE_FILE_UPLOAD + `?token=${token}`;
      const response = await uploadMultipleFile(requestUrl, docFiles);

      if (response.status) {
        // Process response data for display and IDs
        const uploadedFileValues = response.data.map(({ name, path, _id }) => ({
          name,
          path: url.SERVER_URL + path,
          _id,
        }));

        // Update uploaded files and file IDs
        setUploadedDocFiles(uploadedDocFiles.concat(uploadedFileValues));
        setUploadedDocFileIds(
          uploadedDocFileIds.concat(response.data.map(({ _id }) => ({ _id })))
        );
      }

      setDocFileUploading(false);
      resetDocInput();
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for delete file from upload stack
  const closeDocFileHandler = (index) => {
    const closedFile = [...uploadedDocFiles];
    const removedFileId = [...uploadedDocFileIds];
    closedFile.splice(index, 1);
    removedFileId.splice(index, 1);
    setUploadedDocFiles(closedFile);
    setUploadedDocFileIds(removedFileId);
    resetDocInput();
  };

  //function for reset doc inputs
  const resetDocInput = () => {
    const file = document.getElementById("uploadDocResume");
    if (file) {
      file.value = null;
    } else {
      return;
    }
  };

  //function for open video input
  const openVideoFileInput = () => {
    videoFileInputRef.current.click();
  };

  //function for upload video files
  const videoResumeUploadHandler = async (e) => {
    try {
      setVideoUploading(true);
      const docFiles = e.target.files;
      let requestUrl =
        url.API_BASE_URL + url.API_MULTIPLE_FILE_UPLOAD + `?token=${token}`;
      const response = await uploadMultipleFile(requestUrl, docFiles);
      if (response.status) {
        /*----- assign uploaded files with path for display start ------*/
        const uploadedFileValues = response.data.map(
          ({ name, path, _id, ...other }) => ({
            name,
            path: url.SERVER_URL + path,
            _id,
          })
        );
        //check old file exist or not if exist then new file will concat with old file
        if (uploadedVideoFiles.length == 0) {
          setUploadedVideoFiles(uploadedFileValues);
        } else {
          let fileArr = uploadedVideoFiles.concat(uploadedFileValues);
          setUploadedVideoFiles(fileArr);
        }
        /*----- assign uploaded files with path for display end ------*/
        setVideoUploading(false);
        /*----- assign uploaded file ids start ------*/
        const fileIds = response.data.map(({ _id, ...other }) => ({ _id }));
        //check old file ids are exist or not.If exist then new id will concat with old id
        if (uploadedVideoFileIds.length == 0) {
          setUploadedVideoFileIds(fileIds);
        } else {
          let fileArr = uploadedVideoFileIds.concat(fileIds);
          setUploadedVideoFileIds(fileArr);
        }
        /*----- assign uploaded file ids end ------*/
      }
      resetVideoInput();
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for delete file from upload stack
  const closeVideoFileHandler = (index) => {
    const closedFile = [...uploadedVideoFiles];
    const removedFileId = [...uploadedVideoFileIds];
    closedFile.splice(index, 1);
    removedFileId.splice(index, 1);
    setUploadedVideoFiles(closedFile);
    setUploadedVideoFileIds(removedFileId);
    resetVideoInput();
  };

  //function for reset video inputs
  const resetVideoInput = () => {
    const file = document.getElementById("uploadVideoResume");
    if (file) {
      file.value = null;
    } else {
      return;
    }
  };

  //function for edit eduction block
  const updateEductionBlock = (education, index) => {
    setEducationIndex(index);
    setEducationData(education);
  };

  //function for delete eduction block
  const onDeleteEducationBlock = (index) => {
    const filteredEducationBlock = [...educationListBlock];
    filteredEducationBlock.splice(index, 1);
    setEducationListBlock(filteredEducationBlock);
  };

  //function for view education blocks
  const getEducationBlock = (educations) => {
    if (educations) {
      const educationListBlock = educations.map(
        ({ _id, degree, grade, year, institute }) => ({
          _id,
          degree,
          grade,
          year,
          institute,
        })
      );

      setEducationListBlock(educationListBlock);
    } else {
      setEducationListBlock([]);
    }
  };

  //function for edit experience block
  const updateExperienceBlock = (experience, index) => {
    setExperienceIndex(index);
    setExperienceData(experience);
  };

  //function for delete experience block
  const onDeleteExperienceBlock = (index) => {
    const filteredExperienceBlock = [...experienceListBlock];
    filteredExperienceBlock.splice(index, 1);
    setExperienceListBlock(filteredExperienceBlock);
  };

  //function for view experience blocks
  const getExperienceBlock = (experiences) => {
    if (experiences) {
      const experienceArr = experiences.map((experience) => {
        return {
          _id: experience?._id,
          companyid: experience.companyid,
          company: experience?.company,
          end: experience?.end,
          position: experience?.position,
          start: experience?.start,
          ctc: experience?.ctc,
          responsibilities: experience?.responsibilities,
        };
      });
      setExperienceListBlock(experienceArr);
    } else {
      setExperienceListBlock([]);
    }
  };

  //update skillpoint block
  const updateSkillPointBlock = (skillpoint, index) => {
    setSkillPointIndex(index);
    setSkillPointData(skillpoint);
  };

  //function for delete skillpoint block
  const onDeleteSkillPointBlock = (index) => {
    const filteredSkillPointBlock = [...skillPointListBlock];
    filteredSkillPointBlock.splice(index, 1);
    setSkillPointListBlock(filteredSkillPointBlock);
  };

  //function for view skillpoint blocks
  const getSkillPointsBlock = (skillPoints = []) => {
    if (skillPoints) {
      const skillPintArr = skillPoints.map((skillPoint) => {
        return {
          _id: skillPoint?._id,
          skill: skillPoint?.skill,
          skillvalue: skillPoint?.skillvalue,
          skillname: skillPoint?.skillname,
          points: skillPoint?.points,
          experienceyear: skillPoint?.experienceyear,
        };
      });
      setSkillPointListBlock(skillPintArr);
    } else {
      setSkillPointListBlock([]);
    }
  };

  //function for edit technical experence block
  const updateTechnicalExpBlock = (techExp, index) => {
    settechnicalExperienceData(techExp);
    settechnicalExpIndex(index);
  };

  //delete tech exp block
  const onDeleteTechExpBlock = (index) => {
    const filteredTechExpBlock = [...technicalExpBlock];
    filteredTechExpBlock.splice(index, 1);
    settechnicalExpBlock(filteredTechExpBlock);
  };

  //function for view tech exp blocks
  const getTechExpBlock = (techExp) => {
    if (techExp) {
      const techExpArr = techExp.map((techExpData) => {
        const companyvalue = {
          label: techExpData?.companyname,
          value: techExpData?.companyname,
        };
        return {
          _id: techExpData?._id,
          name: techExpData?.name,
          companyname: techExpData?.companyname,
          companyvalue,
          start: techExpData?.start,
          end: techExpData?.end,
          description: techExpData?.description,
          grade: techExpData?.grade,
        };
      });
      settechnicalExpBlock(techExpArr);
    } else {
      settechnicalExpBlock([]);
    }
  };

  //function for edit eduction block
  const updatePortfolioBlock = (portfolio, index) => {
    setPortfolioIndex(index);
    setPortfolioData(portfolio);
  };

  //function for delete eduction block
  const onDeletePortfolioBlock = (index) => {
    const filteredPortfolioBlock = [...portfolioBlock];
    filteredPortfolioBlock.splice(index, 1);
    setPortfolioBlock(filteredPortfolioBlock);
  };

  /* function to fetch location from zip code */
  const getLocationFromZip = async (e) => {
    e.preventDefault();
    if (zipCode !== "") {
      try {
        const url = `https://maps.googleapis.com/maps/api/geocode/json?components=postal_code:${zipCode}&region=es&key=AIzaSyDt4dbtdHCHLj8grTzTVxpztvqUGDkvkaI`;
        const response = await getData(url);
        if (response.status === "OK") {
          const results = response.results;
          if (results.length > 0) {
            results[0]?.address_components.map((item) => {
              // console.log(`item`, JSON.stringify(item));
              if (item.types[0] === "country") {
                setCountry(item?.long_name);
              }
              if (item.types[0] === "locality") {
                setCity(item?.long_name);
              }
              if (item.types[0] === "administrative_area_level_1") {
                setStateName(item?.long_name);
              }
            });
            // setMapCoordinate(
            //   `https://maps.google.com/maps?q=${results[0]?.geometry?.location?.lat},${results[0]?.geometry?.location?.lng}`
            // );
          } else {
            console.log("No results found.");
          }
        } else {
          console.log("Request failed with status:", response.status);
        }
      } catch (error) {
        console.error("Error:", error.message);
      }
    }
  };

  //function for select skills
  const skillSelectionHandler = (val) => {
    setSkillValue(val);
    const skillArr = val.map((skill) => {
      return skill.value;
    });
    setSelectedSkills(skillArr);
  };

  //** function for select hobbies */
  const hobbiesSelectionHandler = (val) => {
    setLeadHobbiesValue(val);
    const hobbiesArr = val.map((item) => item.value);
    setLeadSelectedHobbies(hobbiesArr);
  };

  //function for select subscribe
  const subscribeSelectionHandler = (val) => {
    if (val) {
      setsubscribeMailValue(val);
      setSubscribeMailStatus(val.value);
      if (val.value === "0") {
        setSubscribeDeninedOn(todayValue);
      } else if (val.value === "1") {
        setSubscribeAcceptOn(todayValue);
      } else {
      }
    }
  };

  const handleEditorChange = (newContent, editor) => {
    setresumeDetails(newContent);
  };

  //function for get lead details
  const getLeadDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_LEAD_DETAILS +
        `/${params.id}?token=${token}`;
      console.log("url of lead details------>", requestUrl);

      const response = await getData(requestUrl);

      console.log("response of lead details------>", response);

      if (response.status) {
        if (
          response.data.moderator._id.toString() === userInfo._id.toString()
        ) {
          setisAuthenticatedUser(true);
        }
        //assign firstname
        response.data?.name
          ? setFirstName(response.data?.name)
          : setFirstName("");
        //assign lastname
        response.data?.surname
          ? setLastName(response.data?.surname)
          : setLastName("");
        //assign email
        response.data?.email
          ? setEmailAddress(response.data?.email)
          : setEmailAddress("");
        //assign phone
        response.data?.phone
          ? setPhoneNumber(response.data?.phone)
          : setPhoneNumber("");
        //assign dob
        response.data?.dob
          ? setDateOfBirth(response.data?.dob)
          : setDateOfBirth("");

        if (response.data?.company) {
          const companyResponse = response.data?.company
            ? {
                label: response.data.company,
                value: response.data.company,
              }
            : null;
          companySelectionHandler(companyResponse);
        }

        //assign followers
        if (response.data?.followersarr) {
          followersSelectionHandler(response.data.followersarr);
        }

        //assign position
        response.data?.position
          ? setposition(response.data?.position)
          : setposition("");

        /*------ assign uploaded image start -------*/
        response.data?.photoimage
          ? setUploadedPreviewImage(
              url.SERVER_URL + response.data?.photoimage?.path
            )
          : setUploadedPreviewImage("");

        response.data?.photoimage?._id
          ? setUploadedImageId(response.data?.photoimage?._id)
          : setUploadedImageId(null);
        /*------ assign uploaded image end -------*/

        //assign resume title
        response.data?.resumetitle
          ? setresumeTitle(response.data?.resumetitle)
          : setresumeTitle("");
        //assign resume summary
        response.data?.resumesummary
          ? setresumeSummary(response.data?.resumesummary)
          : setresumeSummary("");
        //assign resume details
        response.data?.resumedetails
          ? setresumeDetails(response.data?.resumedetails)
          : setresumeDetails("");
        //assign min salary
        response.data?.minsalary
          ? setminSalary(response.data?.minsalary)
          : setminSalary("");
        //assign max salary
        response.data?.maxsalary
          ? setmaxSalary(response.data.maxsalary)
          : setmaxSalary("");

        //** assign hobbies */
        const hobbiesArr = response.data?.hobbies ?? [];
        const hobbiesData = hobbiesArr.map((item) => ({
          label: item,
          value: item,
        }));
        hobbiesSelectionHandler(hobbiesData);

        /*------- assign doc resume start ------*/
        if (response.data.uploadeddocresume.length > 0) {
          /*----- assign files with path for display start ------*/
          const uploadedFileValues = response.data?.uploadeddocresume.map(
            ({ name, path, _id, ...other }) => ({
              name,
              path: url.SERVER_URL + path,
              _id,
            })
          );
          setUploadedDocFiles(uploadedFileValues);
          /*----- assign files with path for display end ------*/
          /*----- assign file ids start ------*/
          const fileIds = response.data?.uploadeddocresume.map(
            ({ _id, ...other }) => ({ _id })
          );
          setUploadedDocFileIds(fileIds);
          /*----- assign file ids end ------*/
        }
        /*------- assign doc resume end ------*/
        /*------- assign video resume start ------*/
        if (response.data.uploadedvideoresume.length > 0) {
          /*----- assign files with path for display start ------*/
          const uploadedFileValues = response.data.uploadedvideoresume.map(
            ({ name, path, _id, ...other }) => ({
              name,
              path: url.SERVER_URL + path,
              _id,
            })
          );
          setUploadedVideoFiles(uploadedFileValues);
          /*----- assign files with path for display end ------*/
          /*----- assign file ids start ------*/
          const fileIds = response.data.uploadedvideoresume.map(
            ({ _id, ...other }) => ({ _id })
          );
          setUploadedVideoFileIds(fileIds);
          /*----- assign file ids end ------*/
        }
        /*------- assign video resume end ------*/
        /*------ assign payment duration start -------*/
        for (let duration of paymentDuaritonOption) {
          if (duration.value === response.data.paymentduration) {
            setpaymentDuration(duration);
          }
        }
        /*------ assign payment duration end -------*/
        //assign ownercomment
        response.data?.ownercomment
          ? setOwnerComment(response.data?.ownercomment)
          : setOwnerComment("");
        //assign customercomment
        response.data?.customercomment
          ? setCustomerComment(response.data?.customercomment)
          : setCustomerComment("");
        //assign zipcode
        response.data?.zipcode
          ? setZipCode(response.data?.zipcode)
          : setZipCode("");
        //assign city
        response.data?.city ? setCity(response.data?.city) : setCity("");
        //assign state
        response.data?.state
          ? setStateName(response.data?.state)
          : setStateName("");
        //assign country
        response.data?.country
          ? setCountry(response.data?.country)
          : setCountry("");
        //assign address
        response.data?.location
          ? setAddress(response.data?.location)
          : setAddress("");
        /*------ assign skills start ------*/
        if (response.data?.skills?.length > 0) {
          const skillData = response.data.skills.map(
            ({ name: label, _id: value, ...other }) => ({
              label,
              value,
            })
          );
          skillSelectionHandler(skillData);
        }
        /*------ assign skills start ------*/
        //assign mentor
        mentorSelectionHandler(response.data.mentorvalue);
        //assign interest
        response.data?.interest
          ? setInterest(response.data?.interest)
          : setInterest("");
        //assign linkdin
        response.data?.linkdinlink
          ? setLinkdin(response.data?.linkdinlink)
          : setLinkdin("");
        //assign website
        response.data?.website
          ? setWebsite(response.data?.website)
          : setWebsite("");
        /*------ assing education block start -----*/
        if (response.data.educationids.length > 0) {
          getEducationBlock(response.data.educationids);
        }
        /*------ assing education block end -----*/
        /*------ assing experience block start -----*/
        if (response.data.experienceids.length > 0) {
          getExperienceBlock(response.data.experienceids);
        }
        /*------ assing experience block end -----*/
        /*------ assing skillpoints block start -----*/

        if (response.data?.skillpointidsextended?.length > 0) {
          getSkillPointsBlock(response.data?.skillpointidsextended);
        }
        /*------ assing skillpoints block end -----*/
        getTechExpBlock(response.data.certifications);
        setPortfolioBlock(response.data?.portfoliolink || []);

        for (let subOption of SubscribeOptions) {
          if (subOption.value === response.data.subscribecommercialmail) {
            setsubscribeMailValue(subOption);
            setSubscribeMailStatus(subOption.value);
          }
        }
        setSubscribeAcceptOn(response.data.subscribeaccepton);
        setSubscribeDeninedOn(response.data.subscribedeniedon);

        if (response.data.sectorlistarr) {
          sectorSelectionHandler(response.data.sectorlistarr);
        }

        if (response.data?.billing) {
          setbilling(response.data?.billing);
        }

        if (response.data?.employees) {
          setcountOfEmployees(response.data?.employees);
        }

        if (response.data?.costofinterview) {
          setinterviewCost(response.data?.costofinterview);
        }

        if (response.data?.comments) {
          setcomments(response.data?.comments);
        }

        if (response.data?.searching) {
          setsearching(response.data?.searching);
        }

        if (response.data?.shareleads) {
          for (let shareWith of ecoleadsshowoptions) {
            if (shareWith.value === response.data?.shareleads) {
              shareSelectionHandler(shareWith);
            }
          }
        }

        if (response.data.transactionid) {
          setlastTransaction(response.data.transactionid);
        }

        if (response.data.module) {
          settransactionModule(response.data.module);
        }

        if (response.data.element) {
          settransactionElement(response.data.element);
        }

        if (response.data.transactiondate) {
          settransactionDate(response.data.transactiondate);
        }

        if (response.data.transactionamount) {
          settransactionAmount(response.data.transactionamount);
        }

        setMessageType("success");
        if (response.data.responsibility) {
          setresponsibility(response.data?.responsibility);
        }
        if (response.data?.meetingtypearr) {
          meetingTypeSelectionHandler(response.data?.meetingtypearr);
        }

        if (response.data?.previousrelationshiparr) {
          relationshipSelectionHandler(response.data?.previousrelationshiparr);
        }
        if (response.data?.possibleservicearr) {
          serviceSelectionHandler(response.data?.possibleservicearr);
        }

        if (response.data?.opportunitytitle) {
          setopportunityTitle(response.data?.opportunitytitle);
        }

        if (response.data) setMessageType("success");
      } else {
        setMessageType("error");
      }
      setAlertMessage(`lead details response : ${response.message}`);
    } catch (error) {
      setAlertMessage(`lead details error : ${error.message}`);
      setMessageType("error");
    }

    setShowAlert(true);
  };

  /** Function for validation */
  const validationHandler = () => {
    let isValid = true;

    if (firstName === "") {
      setValidation((prevState) => ({ ...prevState, firstNameWarning: true }));
      isValid = false;
    }

    if (emailAddress === "") {
      setValidation((prevState) => ({
        ...prevState,
        emailAddressWarning: true,
      }));
      isValid = false;
    }

    if (emailAddress !== "") {
      const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (!emailAddress.match(mailformat)) {
        setValidation((prevState) => ({
          ...prevState,
          notValidMail: true,
        }));
        isValid = false;
      }
    }

    if (shareLeadWith === "1" || shareLeadWith === "2") {
      if (searching === "") {
        setValidation((prevState) => ({
          ...prevState,
          searchingWarning: true,
        }));
        isValid = false;
      }

      if (interviewCost === "") {
        setValidation((prevState) => ({
          ...prevState,
          costWarning: true,
        }));
        isValid = false;
      }

      if (comments === "") {
        setValidation((prevState) => ({
          ...prevState,
          commentWarning: true,
        }));
        isValid = false;
      }

      if (responsibility === "") {
        setValidation((prevState) => ({
          ...prevState,
          responsibilityWarning: true,
        }));
        isValid = false;
      }

      if (meetingTypeValue === null) {
        setValidation((prevState) => ({
          ...prevState,
          meetingTypeWarning: true,
        }));
        isValid = false;
      }

      if (relationshipValue === null) {
        setValidation((prevState) => ({
          ...prevState,
          relationshipWarning: true,
        }));
        isValid = false;
      }

      if (possibleServiceValue === null) {
        setValidation((prevState) => ({
          ...prevState,
          serviceTypeWarning: true,
        }));
        isValid = false;
      }

      if (opportunityTitle === "") {
        setValidation((prevState) => ({
          ...prevState,
          opportunityTitleWarning: true,
        }));
        isValid = false;
      }
    }

    return isValid;
  };

  //function for save/update lead
  const saveUpdateLeadHandler = async () => {
    if (validationHandler()) {
      try {
        setIsSending(true);

        let leadData = {
          moderator: userInfo?._id,
          mentorid: mentorId ? mentorId : null,
          mentoremail: mentorEmail,
          name: firstName,
          surname: lastName,
          email: emailAddress,
          phone: phoneNumber,
          company: company,
          position: position,
          dob: dateOfBirth,
          photoimage: uploadedImageId,
          resumetitle: resumeTitle,
          resumesummary: resumeSummary,
          resumedetails: resumeDetails,
          minsalary: minSalary,
          maxsalary: maxSalary,
          paymentduration: paymentDuration?.value,
          uploadeddocresume: uploadedDocFileIds,
          uploadedvideoresume: uploadedVideoFileIds,
          customercomment: customerComment,
          ownercomment: ownerComment,
          educations: educationListBlock,
          experiences: experienceListBlock,
          skillpointids: skillPointListBlock,
          certifications: technicalExpBlock,
          portfoliolink: portfolioBlock,
          zipcode: zipCode,
          city,
          state: stateName,
          country,
          location: address,
          skills: selectedSkills,
          interest,
          linkdinlink: linkdin,
          website,
          followers: selectedFollowers,
          source: `${ecosystemSlug} Backend`,
          sourceurl: fullURL,
          hobbies: leadSelectedHobbies,
          subscribecommercialmail: subscribeMailStatus,
          subscribeaccepton: subscribeAcceptOn,
          subscribedeniedon: subscribeDeninedOn,
          sector: selectedSectors,
          billing,
          employees: countOfEmployees,
          shareleads: shareLeadWith,
          searching,
          costofinterview: interviewCost,
          comments,
          transactionid: lastTransaction,
          module: transactionModule,
          element: transactionElement,
          transactiondate: transactionDate,
          transactionamount: transactionAmount,
          opportunitytitle: opportunityTitle,
          responsibility,
          meetingtypeids: selectedMeetingTypes,
          previousrelationshipids: selectedRelationShips,
          possibleservices: selectedServices,
        };

        console.log("Lead data------->", leadData);

        let requestUrl = url.API_BASE_URL;

        let response = {};

        if (params.id) {
          requestUrl =
            requestUrl +
            url.API_UPDATE_LEAD +
            `/${params.id}` +
            `?token=${token}`;

          console.log("url of send lead------->", requestUrl);

          response = await putData(requestUrl, leadData);
        } else {
          requestUrl = requestUrl + url.API_CREATE_LEAD + `?token=${token}`;

          console.log("url of send lead------->", requestUrl);

          response = await postData(requestUrl, leadData);
        }

        setIsSending(false);

        console.log("response of save lead------>", response);

        if (response.status) {
          setSavedLeadId(response.data._id);
          setMessageType("success");
          const bootstrapModal = new bootstrap.Modal(
            document.getElementById("afterSaveLeadModal")
          );
          bootstrapModal.show();
        } else {
          setMessageType("error");
        }
        setAlertMessage(response.message);
      } catch (error) {
        setAlertMessage(error.message);
        setMessageType("error");
      }
      setShowAlert(true);
    } else {
      window.scrollTo(0, 0);
    }
  };

  //function for reset handler
  const resetLeadFormHandler = () => {
    setFollowersInput("");
    setFollowersOptions([]);
    setMentorInput("");
    setMentorOptions([]);
    setMentorValue(null);
    setMentorId(null);
    setMentorEmail("");
    setValidation({
      firstNameWarning: false,
      emailAddressWarning: false,
      notValidMail: false,
      companyWarning: false,
    });
    setFirstName("");
    setLastName("");
    setEmailAddress("");
    setPhoneNumber("");
    setDateOfBirth("");
    setcompany("");
    setposition("");
    setUploadedPreviewImage("");
    setUploadedImageId(null);
    resetLeadImageFile();
    setresumeTitle("");
    setresumeSummary("");
    setresumeDetails("");
    setminSalary("");
    setmaxSalary("");
    setpaymentDuration(null);
    setUploadedDocFiles([]);
    setUploadedDocFileIds([]);
    resetDocInput();
    setUploadedVideoFiles([]);
    setUploadedVideoFileIds([]);
    resetVideoInput();
    setCustomerComment("");
    setOwnerComment("");
    setEducationData({
      degree: "",
      year: "",
      grade: "",
      institute: "",
    });
    setEducationListBlock([]);
    setEducationIndex(null);
    setExperienceData({
      companyid: null,
      company: "",
      position: "",
      start: "",
      end: "",
    });
    setExperienceListBlock([]);
    setExperienceIndex(null);
    setSkillPointData({
      skill: null,
      skillvalue: null,
      skillname: "",
      points: "",
      experienceyear: "",
    });
    setSkillPointListBlock([]);
    setSkillPointIndex(null);
    settechnicalExperienceData({
      name: "",
      companyvalue: null,
      companyname: "",
      start: "",
      end: "",
      description: "",
      grade: "",
    });
    settechnicalExpBlock([]);
    settechnicalExpIndex(null);
    setZipCode("");
    setCity("");
    setStateName("");
    setCountry("");
    setAddress("");
    setSkillValue(null);
    setSelectedSkills([]);
    setInterest("");
    setLinkdin("");
    setWebsite("");

    for (let subOption of SubscribeOptions) {
      if (subOption.value === "0") {
        setsubscribeMailValue(subOption);
        setSubscribeMailStatus(subOption.value);
      }
    }

    setsectorValue(null);
    setselectedSectors([]);
    setcountOfEmployees("");
    setsearching("");
    setinterviewCost("");
    setcomments("");

    for (let shareWith of ecoleadsshowoptions) {
      if (shareWith.value === "0") {
        shareSelectionHandler(shareWith);
      }
    }

    setSavedLeadId(null);
  };

  //function for aftermodal close
  const afterSaveLeadModalClose = (pageName) => {
    if (pageName == "details") {
      history.push(`/admin/crm/lead/information/${savedLeadId}`);
    } else {
      history.push("/admin/crm/lead/list");
    }
    resetLeadFormHandler();
  };

  /**
   * Closes the alert by updating the state variables.
   *
   * @return {void} No return value.
   */
  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  useEffect(() => {
    if (params.id) {
      getLeadDetails();
    }
  }, [params.id]);

  useEffect(() => {
    getAllSkills();
    getAllSector();
    getAllCompanyList();
    getAllMeetingTypes();
    getAllPreviousRelationship();
    getAllPosibleServices();
    for (let subOption of SubscribeOptions) {
      if (subOption.value === "0") {
        setsubscribeMailValue(subOption);
        setSubscribeMailStatus(subOption.value);
      }
    }
  }, []);

  useEffect(() => {
    if (mentorInput.length > 3) {
      getMentorList();
    }
  }, [mentorInput]);

  useEffect(() => {
    if (companyInput.length > 1) {
      getCompanySearchList();
    }
  }, [companyInput]);

  useEffect(() => {
    if (followersInput.length > 0) {
      getFollowersList();
    }
  }, [followersInput]);

  const companyOptionsToShow =
    companySearchList.length > 0 ? companySearchList : [];

  const mentorOptionsToShow = mentorOptions.length > 0 ? mentorOptions : [];

  const followersOptionsToShow =
    followersOptions.length > 0 ? followersOptions : [];

  return (
    <div id="content_wrapper">
      <section className="crm-wrapper bg-white pb-5">
        <TabsHeader
          commonHeaderObject={commonHeaderObject}
          activeOption={t("Leads")}
        />
        <div className="container-fluid px-lg-5">
          <BreadCrumb breadCrumbText={breadcrumbText} bottom={true} />
          <div className="create-lead-container p-3 p-md-4 border border-gray-300 rounded-10 shadow-sm">
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="accordion" id="accordionPanelsStayOpenExample">
                <AboutAccordion
                  validation={validation}
                  setValidation={setValidation}
                  firstName={firstName}
                  setFirstName={setFirstName}
                  lastName={lastName}
                  setLastName={setLastName}
                  emailAddress={emailAddress}
                  setEmailAddress={setEmailAddress}
                  website={website}
                  setWebsite={setWebsite}
                  phoneNumber={phoneNumber}
                  setPhoneNumber={setPhoneNumber}
                  dateOfBirth={dateOfBirth}
                  setDateOfBirth={setDateOfBirth}
                  companyOptionsToShow={companyOptionsToShow}
                  companyValue={companyValue}
                  companySelectionHandler={companySelectionHandler}
                  setcompanyInput={setcompanyInput}
                  position={position}
                  setposition={setposition}
                  linkdin={linkdin}
                  setLinkdin={setLinkdin}
                  mentorOptionsToShow={mentorOptionsToShow}
                  mentorValue={mentorValue}
                  mentorSelectionHandler={mentorSelectionHandler}
                  mentorInputHandler={mentorInputHandler}
                  followersOptionsToShow={followersOptionsToShow}
                  followrsValue={followrsValue}
                  followersSelectionHandler={followersSelectionHandler}
                  onFollowersInputChange={onFollowersInputChange}
                  openImageFileInput={openImageFileInput}
                  imageFileInputRef={imageFileInputRef}
                  imageUploadHandler={imageUploadHandler}
                  isUploading={isUploading}
                  closeLeadImageFileHandler={closeLeadImageFileHandler}
                  uploadedPreviewImage={uploadedPreviewImage}
                  interest={interest}
                  setInterest={setInterest}
                  leadHobbiesValue={leadHobbiesValue}
                  hobbiesSelectionHandler={hobbiesSelectionHandler}
                  SubscribeOptions={SubscribeOptions}
                  subscribeMailValue={subscribeMailValue}
                  subscribeSelectionHandler={subscribeSelectionHandler}
                  subscribeAcceptOn={subscribeAcceptOn}
                  setSubscribeAcceptOn={setSubscribeAcceptOn}
                  subscribeDeninedOn={subscribeDeninedOn}
                  setSubscribeDeninedOn={setSubscribeDeninedOn}
                />

                <AddressAccordion
                  zipCode={zipCode}
                  setZipCode={setZipCode}
                  getLocationFromZip={getLocationFromZip}
                  city={city}
                  setCity={setCity}
                  stateName={stateName}
                  setStateName={setStateName}
                  country={country}
                  setCountry={setCountry}
                  address={address}
                  setAddress={setAddress}
                />

                {/* ------ biodata accordion section start ------ */}
                <BioDataAccordion
                  resumeTitle={resumeTitle}
                  setresumeTitle={setresumeTitle}
                  resumeSummary={resumeSummary}
                  setresumeSummary={setresumeSummary}
                  resumeDetails={resumeDetails}
                  setresumeDetails={setresumeDetails}
                  minSalary={minSalary}
                  setminSalary={setminSalary}
                  maxSalary={maxSalary}
                  setmaxSalary={setmaxSalary}
                  paymentDuration={paymentDuration}
                  setpaymentDuration={setpaymentDuration}
                  openDocFileInput={openDocFileInput}
                  docFileInputRef={docFileInputRef}
                  docResumeUploadHandler={docResumeUploadHandler}
                  docFileUploading={docFileUploading}
                  loadingCircle={loadingCircle}
                  uploadedDocFiles={uploadedDocFiles}
                  closeDocFileHandler={closeDocFileHandler}
                  openVideoFileInput={openVideoFileInput}
                  videoFileInputRef={videoFileInputRef}
                  videoResumeUploadHandler={videoResumeUploadHandler}
                  videoUploading={videoUploading}
                  uploadedVideoFiles={uploadedVideoFiles}
                  closeVideoFileHandler={closeVideoFileHandler}
                  ownerComment={ownerComment}
                  setOwnerComment={setOwnerComment}
                  customerComment={customerComment}
                  setCustomerComment={setCustomerComment}
                />
                {/* ------ biodata accordion section end ------ */}

                {/* ------ education accordion section start ------ */}
                <EducationAccordion
                  educationListBlock={educationListBlock}
                  updateEductionBlock={updateEductionBlock}
                  onDeleteEducationBlock={onDeleteEducationBlock}
                  setEducationIndex={setEducationIndex}
                />
                {/* ------ education accordion section end ------ */}

                {/* ------ experience accordion section start ------ */}
                <ExperienceAccordion
                  experienceListBlock={experienceListBlock}
                  updateExperienceBlock={updateExperienceBlock}
                  onDeleteExperienceBlock={onDeleteExperienceBlock}
                  setExperienceIndex={setExperienceIndex}
                />
                {/* ------ experience accordion section end ------ */}

                {/* ------ skillpoint accordion section start ------ */}
                <SkillPointAccordion
                  skillPointListBlock={skillPointListBlock}
                  updateSkillPointBlock={updateSkillPointBlock}
                  onDeleteSkillPointBlock={onDeleteSkillPointBlock}
                  setSkillPointIndex={setSkillPointIndex}
                />
                {/* ------ skillpoint accordion section end ------ */}

                {/* technical certificate */}
                <TechnicalCertficateAccordion
                  technicalExpBlock={technicalExpBlock}
                  updateTechnicalExpBlock={updateTechnicalExpBlock}
                  onDeleteTechExpBlock={onDeleteTechExpBlock}
                  settechnicalExpIndex={settechnicalExpIndex}
                />

                {/* <!-- ========== Start portfolio Section ========== --> */}
                <PortfolioAccordion
                  portfolioBlock={portfolioBlock}
                  updatePortfolioBlock={updatePortfolioBlock}
                  onDeletePortfolioBlock={onDeletePortfolioBlock}
                  setPortfolioIndex={setPortfolioIndex}
                  allSkills={allSkills}
                  skillValue={skillValue}
                  skillSelectionHandler={skillSelectionHandler}
                />
                {/* <!-- ========== End portfolio Section ========== --> */}

                {/* ------ business info accordion section start ------ */}
                <BussinessAccordion
                  sectorList={sectorList}
                  sectorValue={sectorValue}
                  sectorSelectionHandler={sectorSelectionHandler}
                  billing={billing}
                  setbilling={setbilling}
                  countOfEmployees={countOfEmployees}
                  setcountOfEmployees={setcountOfEmployees}
                  lastTransaction={lastTransaction}
                  setlastTransaction={setlastTransaction}
                  transactionModule={transactionModule}
                  settransactionModule={settransactionModule}
                  transactionAmount={transactionAmount}
                  settransactionAmount={settransactionAmount}
                  transactionDate={transactionDate}
                  settransactionDate={settransactionDate}
                  transactionElement={transactionElement}
                  settransactionElement={settransactionElement}
                />
                {/* ------ business info accordion section end ------ */}

                {/* <!-- ========== Start ecoleads Section ========== --> */}
                <EcoleadAccordion
                  validation={validation}
                  setValidation={setValidation}
                  ecoleadsshowoptions={ecoleadsshowoptions}
                  shareLeadsValue={shareLeadsValue}
                  shareSelectionHandler={shareSelectionHandler}
                  searching={searching}
                  setsearching={setsearching}
                  interviewCost={interviewCost}
                  setinterviewCost={setinterviewCost}
                  comments={comments}
                  setcomments={setcomments}
                  opportunityTitle={opportunityTitle}
                  setopportunityTitle={setopportunityTitle}
                  responsibility={responsibility}
                  setresponsibility={setresponsibility}
                  meetingTypeList={meetingTypeList}
                  meetingTypeValue={meetingTypeValue}
                  meetingTypeSelectionHandler={meetingTypeSelectionHandler}
                  previousRelationList={previousRelationList}
                  relationshipValue={relationshipValue}
                  relationshipSelectionHandler={relationshipSelectionHandler}
                  possibleServiceList={possibleServiceList}
                  possibleServiceValue={possibleServiceValue}
                  serviceSelectionHandler={serviceSelectionHandler}
                />
                {/* <!-- ========== End ecoleads Section ========== --> */}
              </div>
              {/* ------ close and save button section start ------ */}
              <div className="action d-flex flex-wrap align-items-center gap-2 mt-4">
                <Link
                  to="/admin/crm/lead/list"
                  className="btn btn-outline-primary"
                  onClick={resetLeadFormHandler}
                >
                  {t("Close")}
                </Link>
                {params.id ? (
                  userInfo.role.slug === "ADMIN" ||
                  userInfo.role.slug === "SUPER_ADMIN" ||
                  isAuthenticatedUser ? (
                    <button
                      type="submit"
                      className="btn btn-primary"
                      style={{
                        cursor: isSending ? "not-allowed" : "pointer",
                      }}
                      disabled={isSending ? true : false}
                      onClick={saveUpdateLeadHandler}
                    >
                      {t("Save Now")}
                      {isSending && (
                        <div
                          className="mx-2 spinner-border spinner-border-sm"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      )}
                    </button>
                  ) : null
                ) : (
                  <button
                    type="submit"
                    className="btn btn-primary"
                    style={{
                      cursor: isSending ? "not-allowed" : "pointer",
                    }}
                    disabled={isSending ? true : false}
                    onClick={saveUpdateLeadHandler}
                  >
                    Save Now
                    {isSending && (
                      <div
                        className="mx-2 spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    )}
                  </button>
                )}
              </div>
              {/* ------ close and save button section end ------ */}
            </form>
          </div>
        </div>
      </section>
      {/* ------ add/edit education modal ----- */}
      <AddEditEducationModal
        educationIndex={educationIndex}
        setEducationIndex={setEducationIndex}
        educationData={educationData}
        setEducationData={setEducationData}
        educationListBlock={educationListBlock}
        setEducationListBlock={setEducationListBlock}
      />
      {/* ------ add/edit experience modal ----- */}
      <AddEditExperienceModal
        companyList={companyList}
        experienceData={experienceData}
        setExperienceData={setExperienceData}
        experienceListBlock={experienceListBlock}
        setExperienceListBlock={setExperienceListBlock}
        experienceIndex={experienceIndex}
        setExperienceIndex={setExperienceIndex}
      />
      {/* ------ add/edit skillpoint modal ----- */}
      <AddEditSkillPointModal
        setSkillPointData={setSkillPointData}
        setSkillPointIndex={setSkillPointIndex}
        setSkillPointListBlock={setSkillPointListBlock}
        skillPointData={skillPointData}
        skillPointIndex={skillPointIndex}
        skillPointListBlock={skillPointListBlock}
      />

      <AddEditPortfolioLinkModal
        portfolioIndex={portfolioIndex}
        setPortfolioIndex={setPortfolioIndex}
        portfolioData={portfolioData}
        setPortfolioData={setPortfolioData}
        portfolioBlock={portfolioBlock}
        setPortfolioBlock={setPortfolioBlock}
      />

      {/* ------ success message modal ------ */}
      <AfterSaveLeadModal
        savedLeadId={savedLeadId}
        afterSaveLeadModalClose={afterSaveLeadModalClose}
      />
      <AddEditCetificateModal
        technicalExpIndex={technicalExpIndex}
        settechnicalExpIndex={settechnicalExpIndex}
        technicalExperienceData={technicalExperienceData}
        settechnicalExperienceData={settechnicalExperienceData}
        technicalExpBlock={technicalExpBlock}
        settechnicalExpBlock={settechnicalExpBlock}
      />

      {showAlert && (
        <AlertNotification
          showAlert={showAlert}
          message={alertMessage}
          alertType={messageType}
          onClose={onAlertClose}
        />
      )}
    </div>
  );
};
export default SaveCrmLeadListBody;
