import { useEffect } from "react";
import Header from "components/Common/Header/Header";
import SurveyQuestionBody from "components/SurveyComponents/SurveyQuestion/SurveyQuestionBody/SurveyQuestionBody";

const SurveyQuestion = () => {
  useEffect(() => {
    document.title = "Survey | Question";
  }, []);

  return (
    <>
      <main id="app">
        {/* ---- header start ---- */}
        <Header moduleName="survey" />
        {/* ---- header end ---- */}

        {/* --- body section start ---- */}
        <SurveyQuestionBody moduleName="question" />
        {/* --- body section end ---- */}
      </main>
    </>
  );
};

export default SurveyQuestion;
