import { assetImages } from "constants";
import React from "react";

const ProjectChart = () => {
  return (
    <div className="graph-container">
      <img
        src={assetImages.dashboardChartImg}
        alt="Graph"
        className="img-fluid"
      />
    </div>
  );
};

export default ProjectChart;
