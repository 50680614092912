/* eslint-disable */
import React, { useEffect, useContext, useState } from "react";
import { Link } from "react-router-dom";

import { assetImages } from "constants";

/*import url and gateway methods */
import { postData, putData, uploadMultipleFile, getData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

import downloadFileHandler from "helper/DownloadFile";
import { getDateFormatInSpanish } from "helper/Common/CommonHelper";

import TaskStatusOptions from "data/Prod/TaskStatus.json";
import TaskPriority from "data/Prod/TaskPriority.json";

const ViewTaskTab = ({
  taskDetailsObj = {},
  assignedUserName = "",
  assignedUserImage = "",
  taskFollowers = [],
  projectName = "",
  taskCustomId,
  taskTitle = "",
  details = "",
  startDate = "",
  endDate = "",
  hours = "",
  price = "",
  priority = "",
  taskStatus = "",
  uploadedDocFiles = [],
}) => {
  return (
    <div className="p-4">
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="form-group mb-4">
          {/* <!-- ========== Start task id and title Section ========== --> */}
          <div className="user_task_hdng mb-4">
            <h2>
              {taskDetailsObj.customid ? (
                <span className="badge bg-dark me-3">
                  {taskDetailsObj?.customid}
                </span>
              ) : null}
              {taskDetailsObj?.title}
            </h2>{" "}
          </div>
          {/* <!-- ========== End task id and title Section ========== --> */}

          {/* <!-- ========== Start other information Section ========== --> */}
          <div className="task_info_innr">
            {/* <!-- ========== Start task assigned user Section ========== --> */}
            <div className="task_info_row d-flex align-items-center gap-1 mb-2 py-2">
              <label className="fw-semibold">Assigned to</label>
              <div className="sign_prsn_outer d-flex align-items-center gap-2 flex-wrap">
                {taskDetailsObj?.assignedusername ? (
                  taskDetailsObj?.assignedusername === "" ? null : (
                    <div className="aign_usr_item bg-gray-300 rounded-8 px-2 py-1 d-flex align-items-center gap-1">
                      <span className="rounded-circle d-block usr_img">
                        <img
                          height={22}
                          className="rounded-circle"
                          src={
                            taskDetailsObj?.assigneduserimage == ""
                              ? assetImages.defaultUser
                              : url.SERVER_URL +
                                taskDetailsObj?.assigneduserimage
                          }
                          alt=""
                        />
                      </span>
                      <span className="d-block">
                        {taskDetailsObj?.assignedusername}
                      </span>
                    </div>
                  )
                ) : null}
              </div>
            </div>
            {/* <!-- ========== End task assigned user Section ========== --> */}

            {/* <!-- ========== Start task followers Section ========== --> */}
            <div className="task_info_row d-flex align-items-center gap-1 mb-2 py-2">
              <label className="fw-semibold">Follower</label>
              {taskDetailsObj?.followersarr ? (
                taskDetailsObj?.followersarr?.length === 0 ? null : (
                  <div className="sign_prsn_outer d-flex align-items-center gap-2 flex-wrap">
                    {taskDetailsObj?.followersarr.map((follower, index) => {
                      return (
                        <div
                          className="aign_usr_item bg-gray-300 rounded-8 px-2 py-1 d-flex align-items-center gap-1"
                          key={index}
                        >
                          <span className="rounded-circle d-block usr_img">
                            <img
                              height={22}
                              className="rounded-circle"
                              src={
                                follower.imagepath == ""
                                  ? assetImages.defaultUser
                                  : url.SERVER_URL + follower.imagepath
                              }
                              alt=""
                            />
                          </span>
                          <span className="d-block">{follower.label}</span>
                        </div>
                      );
                    })}
                  </div>
                )
              ) : null}
            </div>
            {/* <!-- ========== End task followers Section ========== --> */}

            <div className="task_info_row d-flex align-items-center gap-1 mb-2 py-2">
              <label className="fw-semibold">Project</label>
              <h5 className="mb-0 text-black fs-md">
                {taskDetailsObj?.projectname}
              </h5>
            </div>
            <div className="task_info_row d-flex align-items-center gap-1 mb-2 py-2">
              <label className="fw-semibold">Status</label>
              <h5 className="d-flex align-items-center gap-1">
                <i className="material-symbols-outlined text-green fs-lg">
                  check_circle
                </i>
                <span className="text-black fs-md">
                  {TaskStatusOptions.map((statusData) => {
                    if (statusData.label === taskDetailsObj?.taskstatus) {
                      return statusData.label;
                    }
                  })}
                </span>
              </h5>
            </div>
            <div className="task_info_row d-flex align-items-center gap-1 mb-2 py-2">
              <label className="fw-semibold">Time(Hrs)</label>
              <h5 className="d-flex align-items-center gap-1">
                <i className="material-symbols-outlined text-orange fs-lg">
                  schedule
                </i>
                <span className="mb-0 text-black fs-md">
                  {taskDetailsObj?.hours} Hrs
                </span>
              </h5>
            </div>
            <div className="task_info_row d-flex align-items-center gap-1 mb-2 py-2">
              <label className="fw-semibold">Deadline</label>
              <h5 className="d-flex align-items-center gap-1">
                <i className="material-symbols-outlined fs-lg text-gray">
                  calendar_today
                </i>
                <span className="mb-0 text-black fs-md">
                  {taskDetailsObj?.startdate} To {taskDetailsObj?.enddate}
                </span>
              </h5>
            </div>
            <div className="task_info_row d-flex align-items-center gap-1 mb-2 py-2">
              <label className="fw-semibold">Priority</label>
              <div className="piority_status d-inline-flex align-items-center py-1 px-2 rounded-10 bg-primary text-white fs-xs">
                {TaskPriority.map((priorityData) => {
                  if (priorityData.value === taskDetailsObj?.priority) {
                    return priorityData.label;
                  }
                })}
              </div>
            </div>
            <div className="task_info_row d-flex align-items-center gap-1 py-2">
              <label className="fw-semibold">Price (Euro)</label>
              <h5 className="d-flex align-items-center gap-1">
                <i className="material-symbols-outlined text-green fs-lg">
                  euro
                </i>
                <span className="mb-0 text-black fs-md">
                  {taskDetailsObj?.price}
                </span>
              </h5>
            </div>
          </div>
          {/* <!-- ========== End other information Section ========== --> */}
        </div>

        {/* <!-- ========== Start task details Section ========== --> */}
        <div className="form-group mb-4">
          <label htmlFor="Details" className="d-block fs-sm fw-semibold mb-2">
            Details
          </label>
          <div className="details_para">
            <p
              dangerouslySetInnerHTML={{ __html: taskDetailsObj?.description }}
            ></p>
          </div>
        </div>
        {/* <!-- ========== End task details Section ========== --> */}

        {/* <!-- ========== Start uploaded file Section ========== --> */}
        <div className="form-group mb-4">
          <label
            htmlFor="attachments"
            className="d-block fs-sm fw-semibold mb-2"
          >
            Attachments
          </label>
          <ul className="upload-files d-flex flex-wrap gap-3 fs-sm">
            {taskDetailsObj?.uploadedfiles
              ? taskDetailsObj?.uploadedfiles.map((file, index) => {
                  return (
                    <li
                      className="file d-flex gap-3 align-items-center px-3 py-2 border border-gray rounded-3"
                      key={index}
                    >
                      <p className="fw-medium">{file.name}</p>
                      <Link
                        to="#"
                        onClick={() =>
                          downloadFileHandler({
                            name: file.name,
                            path: url.SERVER_URL + file.path,
                            _id: file._id,
                          })
                        }
                      >
                        <span className="d-block material-symbols-outlined">
                          download
                        </span>
                      </Link>
                    </li>
                  );
                })
              : null}
          </ul>
        </div>
        {/* <!-- ========== End uploaded file Section ========== --> */}
      </form>
    </div>
  );
};

export default ViewTaskTab;
