import React from "react";
import { assetImages } from "constants";
import SurveyComponentDetailsCard from "components/Common/SurveyComponentDetailsCard/SurveyComponentDetailsCard";

const FeedbackLeftPanel = ({ componentDetails = {} }) => {
  return (
    <div className="left_survey">
      <div className="survey_topbx p-4 shadow-sm">
        <SurveyComponentDetailsCard componentDetails={componentDetails} />
      </div>

      <div className="survey_bttmbx p-4">
        <div className="survey_revw_innr mt-5">
          <div className="blank_graphics_info text-center">
            <div className="blank_graphics_pic mb-3">
              <img src={assetImages.leftBlank} alt="" />
            </div>
            <div className="blank_graphics_text">
              <h4 className="text-black fs-lg mb-3">No survey done yet</h4>
              <p className="fs-xs text-black-gray">
                It's been long waiting for your first points
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeedbackLeftPanel;
