/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Select from "react-select";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData, postData, putData } from "utils/Gateway";

import { assetImages } from "constants";

//import dnds
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { getCurrentDateInString } from "helper/getcurrentdatestring";

import SaveColumnModal from "components/Common/Modal/SaveColumnModal";
import SaveActivityProcessPopup from "components/Common/Popup/ActivityProcessPopup/SaveActivityProcessPopup";
import AddProcessFromTemplateModal from "components/Common/Modal/AddProcessFromTemplateModal";
import SaveInvoicePopup from "components/Common/Popup/SaveInvoicePopup";
import InvoiceListFIlterPopup from "components/Common/Popup/InvoiceListFIlterPopup";
import OpportunityKanbanHeader from "../Header/OpportunityKanbanHeader";

import { crmCommonHeaderLinkLists } from "helper/CrmHelper/CrmHelper";
import TabsHeader from "components/Common/TabsHeader/TabsHeader";
import AlertNotification from "components/Common/AlertNotification/AlertNotification";
import { formatNumber } from "helper/Common/CommonHelper";
import KanbanBoard from "components/Common/OpportunityKanbanComponents/KanbanBoard/KanbanBoard";
import AddNewKanbanOpportunityPopup from "components/Common/Popup/AddNewKanbanOpportunityPopup";

const OpportunityKanbanBody = () => {
  const moduleSlug = "MOD_OPPORTUNITY";
  const moduleName = "opportunity";

  const { t } = useTranslation();

  // custom style for select process
  const customProcessStyle = {
    // options style
    option: (provided, state) => ({
      ...provided,
      color: "#666666",
      fontSize: "0.875rem",
      fontWeight: "normal",
      display: "block",
      minHeight: "1.2em",
      // whiteSpace: "nowrap",
    }),

    // Value style
    control: (styles, state) => ({
      ...styles,
      fontSize: "0.875rem",
      borderRadius: "0.5rem",
      minWidth: 296,
      border: state.isFocused ? "1px solid #D4D4D4" : "1px solid #D4D4D4",
      // This line disable the blue border
      boxShadow: state.isFocused ? 0 : 0,
      "&:hover": {
        border: state.isFocused ? "1px solid #D4D4D4" : "1px solid #D4D4D4",
      },
    }),

    // placeholder style
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        fontSize: "0.875rem",
      };
    },

    // Indicator style
    // dropdownIndicator: (base, state) => {
    //   let changes = { color: "#505050" };
    //   return Object.assign(base, changes);
    // },
  };

  const commonHeaderObject = crmCommonHeaderLinkLists(t);

  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");

  const [showEmptyProcessMessage, setShowEmptyProcessMessage] = useState(false);
  const [processListLoading, setprocessListLoading] = useState(false);
  const [processOwnerValue, setprocessOwnerValue] = useState(null);
  const [isProcessOwner, setisProcessOwner] = useState(false);
  const [moderatorName, setModeratorName] = useState("");
  const [processList, setProcessList] = useState([]);

  const [kanbanData, setKanbanData] = useState([]);

  const [processDetailsLoading, setProcessDetailsLoading] = useState(false);

  //selected process
  const [selectedProcessvalue, setSelectedProcessvalue] = useState(null);
  const [selectedProcessId, setSelectedProcessId] = useState(null);

  // KPI's to show in kanban selected process
  const [followersCount, setFollowersCount] = useState(0);
  const [cardItemCount, setCardItemCount] = useState(0);
  const [cardValueCount, setCardValueCount] = useState(0);

  const [selectedInvoiceId, setSelectedInvoiceId] = useState(null);

  const [selectedComponentTagId, setSelectedComponentTagId] = useState(null);

  //filter
  const [filterStartDate, setfilterStartDate] = useState("");
  const [filterEndDate, setfilterEndDate] = useState("");
  const [filterStartingPrice, setfilterStartingPrice] = useState("");
  const [filterEndingPrice, setfilterEndingPrice] = useState("");
  const [filterTags, setfilterTags] = useState([]);
  const [filterPromisePercent, setfilterPromisePercent] = useState("");
  const [filterTitle, setfilterTitle] = useState("");
  const [filterOwners, setfilterOwners] = useState([]);
  const [filterFollowers, setfilterFollowers] = useState([]);

  const [reloadData, setReloadData] = useState(false);
  const [isFilterReset, setIsFilterReset] = useState(false);

  const [isNoDefaultFilter, setIsNoDefaultFilter] = useState(false);

  //alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  //get all process
  const getAllProcess = async () => {
    try {
      setprocessListLoading(true);
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_ALL_PROCESS +
        `?token=${token}&ismod=${true}&istemplate=${false}&moduleslug=${moduleSlug}`;

      const response = await getData(requestURL);

      setprocessListLoading(false);

      console.log("response user all process----->", response);

      if (response.status) {
        if (response.data.length === 0) {
          setShowEmptyProcessMessage(true);
        } else {
          setShowEmptyProcessMessage(false);
        }

        const processOptions = response.data.map((item) => ({
          label: `${item.title} ${item.isowner ? "(Owned)" : ""}`,
          value: item._id,
        }));

        if (response.lastsavedprocessid) {
          proecessSelectionHandler(
            processOptions.find(
              (item) => item.value === response.lastsavedprocessid
            )
          );
        } else {
          proecessSelectionHandler(processOptions[processOptions.length - 1]);
        }

        setProcessList(processOptions);
      } else {
        setMessageType("error");
        setAlertMessage(response.message);
        setShowAlert(true);
      }
    } catch (error) {
      setMessageType("error");
      setAlertMessage(error.message);
      setShowAlert(true);
    }
  };

  //select process
  const proecessSelectionHandler = (val) => {
    if (val) {
      setSelectedProcessvalue(val);
      getProcessDetails(val.value);
      setSelectedProcessId(val.value);
    } else {
      setSelectedProcessvalue(null);
      getProcessDetails(null);
      setSelectedProcessId(null);
    }
  };

  //get process details
  const getProcessDetails = async (selectedProcessId) => {
    if (selectedProcessId) {
      setProcessDetailsLoading(true);

      setCardItemCount(0);
      setCardValueCount(0);

      try {
        let requestUrl =
          url.API_BASE_URL +
          url.API_GET_PROCESS_DETAILS +
          `/${selectedProcessId}?token=${token}&moduleslug=${moduleSlug}`;

        if (filterStartDate !== "" && filterEndDate !== "") {
          requestUrl += `&filterfromdate=${filterStartDate}&filtertodate=${filterEndDate}`;
        }

        if (filterStartingPrice !== "" && filterEndingPrice !== "") {
          requestUrl += `&filterstartprice=${filterStartingPrice}&filterendprice=${filterEndingPrice}`;
        }

        if (filterTitle != "") {
          requestUrl = requestUrl + `&filtertitle=${filterTitle}`;
        }

        if (filterPromisePercent !== "") {
          requestUrl = requestUrl + `&filterpercent=${filterPromisePercent}`;
        }

        if (filterOwners.length > 0) {
          requestUrl = requestUrl + `&filterowners=${filterOwners}`;
        }

        if (filterFollowers.length > 0) {
          requestUrl = requestUrl + `&filterfollowers=${filterFollowers}`;
        }

        const response = await getData(requestUrl);

        setProcessDetailsLoading(false);

        console.log("Process Details Response >> ", response);

        await saveProcessHistoryHandler(selectedProcessId);

        if (response.status && response.data) {
          setMessageType("success");
          setisProcessOwner(response.data.isuserprocessowner);
          setKanbanData(response.data.processlabels);

          setFollowersCount(
            response.data.followers ? response.data.followers.length : 0
          );
          setModeratorName(response.data?.moderatorname);

          setprocessOwnerValue([response.data?.processownervalue]);
        } else {
          setMessageType("error");
        }
        setAlertMessage(response.message);
      } catch (error) {
        setMessageType("error");
        setAlertMessage(error.message);
      }

      setShowAlert(true);
    } else {
      setKanbanData([]);
    }
  };

  //save process history
  const saveProcessHistoryHandler = async (processId) => {
    try {
      let historyData = {
        activityprocessid: processId,
        moduleslug: moduleSlug,
      };
      let requestURL =
        url.API_BASE_URL + url.API_SAVE_PROCESS_HISTORY + `?token=${token}`;

      await postData(requestURL, historyData);
    } catch (error) {
      console.log(error.message);
    }
  };

  //collapse column function
  const collapseHandler = (isCollapsed, componentId) => {
    const updatedKanbanData = kanbanData.map((kanban) => {
      if (kanban.componentid === componentId) {
        return { ...kanban, iscollapsed: isCollapsed };
      }
      return kanban;
    });

    setKanbanData(updatedKanbanData);
  };

  //after Add New  Column
  const afterAddNewColumn = () => {
    if (selectedProcessId) {
      getProcessDetails(selectedProcessId);
    }
  };

  //delete task from board
  const deleteOpportunityHandler = async (invoice, tagId) => {
    try {
      let invoiceData = {
        invoiceid: invoice._id,
        componentorderitemid: invoice.componentorderitemid,
        tagid: tagId,
      };

      let requestURL =
        url.API_BASE_URL + url.API_REMOVE_CARD_ITEM_KANBAN + `?token=${token}`;

      const response = await putData(requestURL, invoiceData);

      // console.log(response);

      if (response.status) {
        setMessageType("success");
        getProcessDetails(selectedProcessId);
      } else {
        setMessageType("error");
      }

      setAlertMessage(response.message);
    } catch (error) {
      setMessageType("error");
      setAlertMessage(error.message);
    }
    setShowAlert(true);
  };

  //create chat
  const createContactChatHandler = async (
    userId = null,
    componentTitle = ""
  ) => {
    if (userId && userId.toString() !== userInfo._id.toString()) {
      try {
        let requestURL =
          url.API_BASE_URL +
          url.API_SEARCH_CONTACT +
          `?token=${token}&member=${userId}`;

        const response = await getData(requestURL);

        console.log(response);

        if (response.status) {
          const externalUrl = `${url.FRONTEND_BASE_URL}/conversation/contact/${response.data._id}/MOD_CONTACT/${moduleName}/${componentTitle}`; // Replace with your external URL
          // const externalUrl = `${url.FRONTEND_BASE_URL}/conversation/contact/${response.data._id}/MOD_CONTACT`; // Replace with your external URL

          window.open(externalUrl, "_blank", "noopener,noreferrer");
        }
      } catch (error) {
        console.log(error.message);
      }
    } else {
    }
  };

  //drag and drop operation
  const onDragEnd = async (result) => {
    // console.log("onDragEnd", result);
    const { destination, source, draggableId, type } = result;

    //if no destination or no drag and drop return.
    if (!destination) {
      return;
    }

    //update kanban table
    let requestURL =
      url.API_BASE_URL +
      url.API_UPDATE_KANBAN +
      `?token=${token}&changetype=${type}`;

    //check the drag and drop type is column
    if (type === "column") {
      // Reordering columns
      const newColumnOrder = Array.from(kanbanData);
      newColumnOrder.splice(source.index, 1)[0]; // Remove from source index
      newColumnOrder.splice(
        destination.index,
        0,
        kanbanData.find((column) => column.componentid === draggableId)
      ); // Insert at destination index

      // Update componentorder field based on new positions
      newColumnOrder.forEach((column, index) => {
        column.componentorder = index + 1; // Adding 1 because componentorder starts from 1
      });

      //update local state
      setKanbanData(newColumnOrder);

      //send data to server for update changes
      const updateKanbanData = {
        source: null,
        destination: null,
        processid: selectedProcessId,
        processlist: newColumnOrder,
        draggableid: draggableId,
        updatedcolumn: null,
      };

      try {
        const response = await putData(requestURL, updateKanbanData);

        if (response.status) {
          setMessageType("success");
        } else {
          setMessageType("error");
        }
        setAlertMessage(response.message);
      } catch (error) {
        setMessageType("error");
        setAlertMessage(error.message);
      }
    }

    if (type === "opportunity") {
      // Moving task within a column or to another column
      const sourceColumn = kanbanData.find(
        (column) => column.tagid.toString() === source.droppableId.toString()
      );

      const destinationColumn = kanbanData.find(
        (column) =>
          column.tagid.toString() === destination.droppableId.toString()
      );

      // If the task is moved within the same column
      if (
        source.droppableId.toString() === destination.droppableId.toString()
      ) {
        const newSourceColumnInvoices = sourceColumn.invoicelist;

        const updatedInvoiceData = sourceColumn.invoicelist.filter(
          (item) => item._id.toString() === draggableId.toString()
        );

        newSourceColumnInvoices.splice(source.index, 1); // Remove from source index

        newSourceColumnInvoices.splice(
          destination.index,
          0,
          updatedInvoiceData[0]
        ); // Insert at destination index

        newSourceColumnInvoices.forEach((invoice, index) => {
          invoice.order = index + 1;
        });

        const updatedColumn = {
          ...sourceColumn,
          invoicelist: newSourceColumnInvoices,
        };

        const newColumnInvoices = kanbanData.map((column) => {
          return column.componentid.toString() ===
            sourceColumn.componentid.toString()
            ? updatedColumn
            : column;
        });

        setKanbanData(newColumnInvoices);

        //send data to server for update changes
        const updateKanbanData = {
          source: source.droppableId,
          destination: destination.droppableId,
          processlist: newColumnInvoices,
          updatedsourcecolumn: updatedColumn,
          processid: selectedProcessId,
          draggableid: draggableId,
        };

        try {
          const response = await putData(requestURL, updateKanbanData);

          if (response.status) {
            setMessageType("success");
          } else {
            setMessageType("error");
          }
          setAlertMessage(response.message);
        } catch (error) {
          setMessageType("error");
          setAlertMessage(error.message);
        }
      } else {
        // If the invoice is moved to another column
        const sourceInvoiceList = sourceColumn.invoicelist;
        const destinationInvoiceList = destinationColumn.invoicelist;

        const updatedInvoiceData = sourceColumn.invoicelist.filter(
          (item) => item._id.toString() === draggableId.toString()
        );

        sourceInvoiceList.splice(source.index, 1); // Remove from source index

        destinationInvoiceList.splice(
          destination.index,
          0,
          updatedInvoiceData[0]
        ); // Insert at destination index

        sourceInvoiceList.forEach((invoice, index) => {
          invoice.order = index + 1;
        });

        const updatedSourceColumn = {
          ...sourceColumn,
          invoicelist: sourceInvoiceList,
        };

        //add tag of destination component id which is tag id.
        destinationInvoiceList.forEach((invoiceData, index) => {
          if (invoiceData.tags.includes(source.droppableId)) {
            invoiceData.tags.splice(source.droppableId, 1);
            invoiceData.tags.push(destination.droppableId);
          }
          invoiceData.order = index + 1;
        });

        const updatedDestinationColumn = {
          ...destinationColumn,
          invoicelist: destinationInvoiceList,
        };

        const newColumnInvoices = kanbanData.map((column) => {
          if (
            column.componentid.toString() ===
            sourceColumn.componentid.toString()
          ) {
            return updatedSourceColumn;
          }
          if (
            column.componentid.toString() ===
            destinationColumn.componentid.toString()
          ) {
            return updatedDestinationColumn;
          }
          return column;
        });

        setKanbanData(newColumnInvoices);

        //send data to server for update changes
        const updateKanbanData = {
          source: source.droppableId,
          destination: destination.droppableId,
          processlist: newColumnInvoices,
          updatedsourcecolumn: updatedSourceColumn,
          updateddestinationcolumn: updatedDestinationColumn,
          processid: selectedProcessId,
          draggableid: draggableId,
        };

        try {
          const response = await putData(requestURL, updateKanbanData);

          if (response.status) {
            setMessageType("success");
          } else {
            setMessageType("error");
          }
          setAlertMessage(response.message);
        } catch (error) {
          setMessageType("error");
          setAlertMessage(error.message);
        }
      }
    }

    setShowAlert(true);

    setTimeout(() => {
      getProcessDetails(selectedProcessId);
    }, 1000);
  };

  //function for close process template modal
  const afterProcessModalClose = () => {
    getAllProcess();
  };

  const resetFilterData = () => {
    setfilterStartDate("");
    setfilterEndDate("");
    setfilterStartingPrice("");
    setfilterEndingPrice("");
    setfilterTags([]);
    setfilterPromisePercent("");
    setfilterTitle("");
    setfilterOwners([]);
    setfilterFollowers([]);
    setReloadData(true);
  };

  useEffect(() => {
    getAllProcess();
  }, []);

  useEffect(() => {
    if (
      filterStartDate !== "" ||
      filterEndDate !== "" ||
      filterStartingPrice !== "" ||
      filterEndingPrice !== "" ||
      filterTags.length > 0 ||
      filterOwners.length > 0 ||
      filterFollowers.length > 0 ||
      filterPromisePercent !== "" ||
      filterTitle !== ""
    ) {
      getProcessDetails(selectedProcessId);
    }
  }, [
    filterStartDate,
    filterEndDate,
    filterStartingPrice,
    filterEndingPrice,
    filterOwners,
    filterFollowers,
    filterTags,
    filterPromisePercent,
    filterTitle,
  ]);

  useEffect(() => {
    if (reloadData) {
      setIsFilterReset(true);
      getProcessDetails(selectedProcessId);
      setReloadData(false);
    }
  }, [reloadData]);

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    (moduleAccess.includes("MOD_CRM") &&
      moduleAccess.includes("MOD_OPPORTUNITY"))
  ) {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <TabsHeader
            commonHeaderObject={commonHeaderObject}
            activeOption={t("Opportunities")}
          />
          <div className="container-fluid px-lg-5">
            <OpportunityKanbanHeader reloadList={resetFilterData} />

            {processListLoading ? (
              <div className="placeholder-glow d-flex flex-column gap-4">
                <span className="placeholder placeholder-lg bg-secondary col-12"></span>
                <span className="placeholder placeholder-lg bg-secondary col-8"></span>
                <span className="placeholder placeholder-lg bg-secondary col-4"></span>
              </div>
            ) : showEmptyProcessMessage ? (
              <div className="challenges_empty text-center">
                <div className="empty_pic mb-4">
                  {" "}
                  <img src={assetImages.emptyVector} alt="" />
                </div>
                <div className="empty_text">
                  <p className="fs-lg text-gray fw-semibold mb-4">
                    You have not created any process yet please create one to
                    start
                  </p>
                  <div className="d-flex align-items-center justify-content-center gap-2">
                    <Link
                      to="#"
                      className="btn btn-outline-primary d-flex align-items-center gap-1"
                      data-bs-toggle="modal"
                      data-bs-target="#kanbanExample"
                      role="button"
                      aria-controls="saveProcess"
                    >
                      <span className="d-block material-symbols-outlined icon-md">
                        add
                      </span>
                      <span className="d-block">
                        Add Process From Templates
                      </span>
                    </Link>
                    <Link
                      to="#activityProcessOffCanvas"
                      data-bs-toggle="offcanvas"
                      role="button"
                      aria-controls="saveProcess"
                      className="btn btn-primary d-inline-flex align-items-center gap-1"
                    >
                      <span className="d-block material-symbols-outlined icon-md">
                        add
                      </span>
                      <span className="d-block">Add Process</span>
                    </Link>
                  </div>
                </div>
              </div>
            ) : (
              <div className="project-tasks-container">
                <div className="challenges-process-row d-flex align-items-center justify-content-between gap-3 mb-1">
                  <div className="left-process-select d-flex align-items-center gap-3">
                    <Select
                      // className="form-select fs-sm shadow-none"
                      isClearable
                      placeholder="Select process"
                      options={processList}
                      value={selectedProcessvalue}
                      onChange={(val) => proecessSelectionHandler(val)}
                      styles={customProcessStyle}
                    />

                    <div className="d-flex align-items-center gap-2">
                      <span>
                        <b>Moderator:</b> {moderatorName}{" "}
                      </span>
                      <span>
                        <b>Team:</b> {followersCount}{" "}
                      </span>
                      <span>
                        <b>Op:</b> {cardItemCount}{" "}
                      </span>
                      <div className="d-flex align-items-center gap-1">
                        <b>Total:</b> {formatNumber(cardValueCount)}{" "}
                        <span className="d-block material-symbols-outlined icon-sm">
                          euro
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="right-process-btn d-flex align-items-center gap-2">
                    <Link
                      to="#"
                      className="btn btn-outline-primary d-flex align-items-center gap-1"
                      data-bs-toggle="modal"
                      data-bs-target="#kanbanExample"
                      role="button"
                      aria-controls="saveProcess"
                    >
                      <span className="d-block material-symbols-outlined icon-md">
                        add
                      </span>
                      <span className="d-block">
                        Add Process From Templates
                      </span>
                    </Link>

                    <Link
                      to="#activityProcessOffCanvas"
                      className="btn btn-primary d-flex align-items-center gap-1"
                      data-bs-toggle="offcanvas"
                      role="button"
                      aria-controls="saveProcess"
                    >
                      <span className="d-block material-symbols-outlined icon-md">
                        add
                      </span>
                      <span className="d-block">Add Process</span>
                    </Link>
                  </div>
                </div>

                {processDetailsLoading ? (
                  <div className="board_outer">
                    <div className="project-tasks-container d-flex overflow-x-auto pb-3 grid-view gap-2">
                      {[1, 2, 3, 4].map((item, index) => {
                        return (
                          <div
                            className="col-lg-3 d-flex flex-column gap-2"
                            key={index}
                          >
                            <div className="card-title gradient-light p-3 rounded-10 d-flex justify-content-between align-items-center mb-2">
                              <h3 className="lh-1 mb-0">
                                <span
                                  className="d-block placeholder text-gray"
                                  style={{ width: "15rem", height: "0.5rem" }}
                                ></span>
                              </h3>
                            </div>

                            <div className="single-card p-3 border border-gray-300 rounded-10 mb-2">
                              <div className="title d-flex align-items-center gap-3 justify-content-between">
                                <p className="fs-md fw-semibold">
                                  <span
                                    className="d-block placeholder text-gray"
                                    style={{ width: "15rem", height: "0.5rem" }}
                                  ></span>
                                </p>
                                <div className="d-flex ms-auto">
                                  <span
                                    className="d-block placeholder text-gray"
                                    style={{
                                      width: "0.1rem",
                                      height: "0.5rem",
                                    }}
                                  ></span>
                                </div>
                              </div>
                              <p className="fs-sm text-gray d-flex align-items-center gap-1 my-3">
                                <span className="d-block material-symbols-outlined icon-sm">
                                  calendar_month
                                </span>
                                <span
                                  className="d-block placeholder"
                                  style={{ width: "5rem", height: "0.5rem" }}
                                ></span>
                                <span
                                  className="d-block placeholder ms-2"
                                  style={{ width: "5rem", height: "0.5rem" }}
                                ></span>
                              </p>
                              <div className="d-flex align-items-center gap-3">
                                <div className="profile d-flex align-items-center gap-2">
                                  <div
                                    className="avatar rounded-circle overflow-hidden"
                                    style={{
                                      width: "22px",
                                      height: "22px",
                                    }}
                                  >
                                    <img
                                      src={assetImages.defaultUser}
                                      alt="User"
                                      className="w-100 h-100 object-fit-cover object-center"
                                    />
                                  </div>
                                  <p className="fs-sm">
                                    <span
                                      className="d-block placeholder text-gray"
                                      style={{
                                        width: "5rem",
                                        height: "0.5rem",
                                      }}
                                    ></span>
                                  </p>
                                </div>
                                <p className="d-flex align-items-center gap-1">
                                  <span className="d-block material-symbols-outlined icon-sm">
                                    schedule
                                  </span>
                                  <span
                                    className="d-block fs-sm placeholder text-gray"
                                    style={{ width: "5rem", height: "0.5rem" }}
                                  ></span>
                                </p>
                                <span
                                  className="d-inline-block fs-xs lh-1 text-white px-2 py-1 bg-primary rounded-90 placeholder"
                                  style={{ width: "5rem", height: "1rem" }}
                                ></span>
                              </div>
                            </div>
                            <div className="single-card p-3 border border-gray-300 rounded-10 mb-2">
                              <div className="title d-flex align-items-center gap-3 justify-content-between">
                                <p className="fs-md fw-semibold">
                                  <span
                                    className="d-block placeholder text-gray"
                                    style={{ width: "15rem", height: "0.5rem" }}
                                  ></span>
                                </p>
                                <div className="d-flex ms-auto">
                                  <span
                                    className="d-block placeholder text-gray"
                                    style={{
                                      width: "0.1rem",
                                      height: "0.5rem",
                                    }}
                                  ></span>
                                </div>
                              </div>
                              <p className="fs-sm text-gray d-flex align-items-center gap-1 my-3">
                                <span className="d-block material-symbols-outlined icon-sm">
                                  calendar_month
                                </span>
                                <span
                                  className="d-block placeholder"
                                  style={{ width: "5rem", height: "0.5rem" }}
                                ></span>
                                <span
                                  className="d-block placeholder ms-2"
                                  style={{ width: "5rem", height: "0.5rem" }}
                                ></span>
                              </p>
                              <div className="d-flex align-items-center gap-3">
                                <div className="profile d-flex align-items-center gap-2">
                                  <div
                                    className="avatar rounded-circle overflow-hidden"
                                    style={{
                                      width: "22px",
                                      height: "22px",
                                    }}
                                  >
                                    <img
                                      src={assetImages.defaultUser}
                                      alt="User"
                                      className="w-100 h-100 object-fit-cover object-center"
                                    />
                                  </div>
                                  <p className="fs-sm">
                                    <span
                                      className="d-block placeholder text-gray"
                                      style={{
                                        width: "5rem",
                                        height: "0.5rem",
                                      }}
                                    ></span>
                                  </p>
                                </div>
                                <p className="d-flex align-items-center gap-1">
                                  <span className="d-block material-symbols-outlined icon-sm">
                                    schedule
                                  </span>
                                  <span
                                    className="d-block fs-sm placeholder text-gray"
                                    style={{ width: "5rem", height: "0.5rem" }}
                                  ></span>
                                </p>
                                <span
                                  className="d-inline-block fs-xs lh-1 text-white px-2 py-1 bg-primary rounded-90 placeholder"
                                  style={{ width: "5rem", height: "1rem" }}
                                ></span>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  <div className="board_outer">
                    <DragDropContext onDragEnd={onDragEnd}>
                      <Droppable
                        droppableId="all-columns"
                        direction="horizontal"
                        type="column"
                      >
                        {(provided) => {
                          return (
                            <KanbanBoard
                              provided={provided}
                              kanbanData={kanbanData}
                              isProcessOwner={isProcessOwner}
                              collapseHandler={collapseHandler}
                              setSelectedComponentTagId={
                                setSelectedComponentTagId
                              }
                              setSelectedInvoiceId={setSelectedInvoiceId}
                              setCardItemCount={setCardItemCount}
                              setCardValueCount={setCardValueCount}
                              deleteOpportunityHandler={
                                deleteOpportunityHandler
                              }
                              createContactChatHandler={
                                createContactChatHandler
                              }
                              showAddNewIcon={true}
                            />
                          );
                        }}
                      </Droppable>
                    </DragDropContext>
                  </div>
                )}
              </div>
            )}
          </div>
        </section>

        <AddNewKanbanOpportunityPopup
          processOwnerValue={processOwnerValue}
          isProcessOwner={isProcessOwner}
          reloadList={() => {
            setTimeout(() => {
              getProcessDetails(selectedProcessId);
            }, 2500);
          }}
          selectedComponentTagId={selectedComponentTagId}
          setSelectedComponentTagId={setSelectedComponentTagId}
          moudleSlug={moduleName}
          setShowAlert={setShowAlert}
          setAlertMessage={setAlertMessage}
          setMessageType={setMessageType}
        />

        <SaveInvoicePopup
          leadId={null}
          contactPersonId={null}
          moudleSlug={""}
          moduleName={""}
          selectedInvoiceId={selectedInvoiceId}
          setSelectedInvoiceId={setSelectedInvoiceId}
          reloadList={() => {
            setTimeout(() => {
              getProcessDetails(selectedProcessId);
            }, 2500);
          }}
          setShowAlert={setShowAlert}
          setAlertMessage={setAlertMessage}
          setMessageType={setMessageType}
        />

        <InvoiceListFIlterPopup
          componentName={"crmopportunity"}
          moduleType={"kanban"}
          isReset={isFilterReset}
          setIsReset={setIsFilterReset}
          filterStartDate={filterStartDate}
          setfilterStartDate={setfilterStartDate}
          filterEndDate={filterEndDate}
          setfilterEndDate={setfilterEndDate}
          filterStartingPrice={filterStartingPrice}
          setfilterStartingPrice={setfilterStartingPrice}
          filterEndingPrice={filterEndingPrice}
          setfilterEndingPrice={setfilterEndingPrice}
          filterTags={filterTags}
          setfilterTags={setfilterTags}
          filterOwners={filterOwners}
          setfilterOwners={setfilterOwners}
          filterFollowers={filterFollowers}
          setfilterFollowers={setfilterFollowers}
          filterPromisePercent={filterPromisePercent}
          setfilterPromisePercent={setfilterPromisePercent}
          filterTitle={filterTitle}
          setfilterTitle={setfilterTitle}
          setIsNoDefaultFilter={setIsNoDefaultFilter}
        />

        {/* dnd modals */}
        <SaveColumnModal
          selectedProcessId={selectedProcessId}
          setSelectedProcessId={setSelectedProcessId}
          afterModalClose={afterAddNewColumn}
        />

        <SaveActivityProcessPopup
          moduleSlug={moduleSlug}
          afterPopupClose={getAllProcess}
          setSelectProcessId={() => {}}
          selectedProcessId={null}
          isTemplate={false}
          setShowAlert={setShowAlert}
          setAlertMessage={setAlertMessage}
          setMessageType={setMessageType}
        />

        <AddProcessFromTemplateModal
          moduleSlug={moduleSlug}
          afterProcessModalClose={afterProcessModalClose}
          setShowAlert={setShowAlert}
          setAlertMessage={setAlertMessage}
          setMessageType={setMessageType}
        />

        {showAlert && (
          <AlertNotification
            showAlert={showAlert}
            message={alertMessage}
            alertType={messageType}
            onClose={onAlertClose}
          />
        )}
      </div>
    );
  } else {
    return (
      <div className="empty_access text-center">
        <div className="empty_pic mb-4">
          {" "}
          <img src={assetImages.emptyVector} alt="" />
        </div>
        <div className="empty_text">
          <p className="fs-lg text-gray fw-semibold mb-4">
            {t("Sorry....! You don't have privilege to see this content")}
          </p>
        </div>
      </div>
    );
  }
};

export default OpportunityKanbanBody;
