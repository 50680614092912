/* eslint-disable */
import { useState } from "react";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { putData } from "utils/Gateway";

import { assetImages } from "constants";

const UpdateTaskSchedulePopup = ({
  closePopupHandler = () => {},
  setUpdateScheduleTaskUserList = () => {},
  updateScheduleTaskUserList = [],
  reloadList = () => {},
  setShowAlert,
  setAlertMessage,
  setMessageType,
}) => {
  const token = localStorage.getItem("token");

  const [startDate, setstartDate] = useState("");
  const [isUpdating, setisUpdating] = useState(false);

  const [validation, setValidation] = useState({
    startDate: false,
    workHours: {},
  });

  //function for validation
  const validationHandler = () => {
    let isValid = true;

    //check start date is empty
    if (startDate === "") {
      setValidation((prevState) => ({ ...prevState, startDate: true }));
      isValid = false;
    }

    // Check if any workhours field is empty
    const workHoursErrors = {};
    updateScheduleTaskUserList.forEach((task, index) => {
      if (!task.workhours || task.workhours <= 0 || task.workhours === "") {
        workHoursErrors[index] = "Enter a valid work hour!";
        isValid = false;
      }
    });

    setValidation((prevState) => ({
      ...prevState,
      workHours: workHoursErrors,
    }));

    return isValid;
  };

  //function for change workhours
  const updateWorkHourHandler = (value, index) => {
    const newUpdatedTaskList = [...updateScheduleTaskUserList];
    const workHoursErrors = { ...validation.workHours };

    const rowIndex = newUpdatedTaskList.findIndex((task, idx) => idx === index);

    // Update the task's workhours if the task is found
    if (rowIndex !== -1) {
      newUpdatedTaskList[rowIndex] = {
        ...newUpdatedTaskList[rowIndex], // Keep other properties unchanged
        workhours: value, // Update the workhours property
      };
      setUpdateScheduleTaskUserList(newUpdatedTaskList);

      // Check if the input is valid
      if ((value && value > 0) || (value && value !== "")) {
        delete workHoursErrors[index]; // Clear validation error for this index
      } else {
        workHoursErrors[index] = "Enter a valid work hour!";
      }

      setValidation((prevState) => ({
        ...prevState,
        workHours: workHoursErrors,
      }));
    }
  };

  //function for change checkbox check
  const updateCheckBoxHandler = (checked = false, index, filedName = "") => {
    const newUpdatedTaskList = [...updateScheduleTaskUserList];

    const rowIndex = newUpdatedTaskList.findIndex((task, idx) => idx === index);

    // Update the task's workhours if the task is found
    if (rowIndex !== -1) {
      newUpdatedTaskList[rowIndex] = {
        ...newUpdatedTaskList[rowIndex], // Keep other properties unchanged
        [filedName]: checked, // Update the field property
      };

      setUpdateScheduleTaskUserList(newUpdatedTaskList);
    }
  };

  //function for save schedule
  const saveTaskScheduleHandler = async () => {
    if (validationHandler()) {
      try {
        setisUpdating(true);

        let taskData = {
          startdate: startDate,
          usertaskschedulelist: updateScheduleTaskUserList,
        };

        let requestUrl =
          url.API_BASE_URL +
          url.API_UPDATE_BULK_TASK_SCHEDULE +
          `?token=${token}`;

        let response = await putData(requestUrl, taskData);

        setisUpdating(false);

        if (response.status) {
          setMessageType("success");
          bootstrap.Offcanvas.getInstance(
            document.querySelector("#UpdateTaskSchedule")
          ).hide();

          resetHandler();
          reloadList();
        } else {
          setMessageType("error");
        }
        setAlertMessage(response.message);
      } catch (error) {
        setAlertMessage(error.message);
        setMessageType("error");
      }
    } else {
      setAlertMessage("Please enter required fields");
      setMessageType("error");
    }
    setShowAlert(true);
  };

  const resetHandler = () => {
    closePopupHandler();
    setUpdateScheduleTaskUserList([]);
    setstartDate("");
    setValidation({
      startDate: false,
      workHours: {},
    });
  };

  return (
    <div
      className="offcanvas lg offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="UpdateTaskSchedule"
      aria-labelledby="UpdateTaskScheduleLabel"
    >
      <div className="offcanvas-header p-4 pb-0">
        <h3 className="offcanvas-title" id="UpdateTaskScheduleLabel">
          Update Task Schedule
        </h3>
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetHandler}
        ></button>
      </div>
      <div className="offcanvas-body p-4">
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="table-wrapper">
            <div className="table-responsive mb-4">
              <table className="table align-middle no-arrow fs-sm text-nowrap border-0 mb-0">
                <thead>
                  <tr>
                    <th className="member bg-transparent fs-md fw-semibold border-0">
                      Member
                    </th>
                    <th className="work-hours bg-transparent fs-md fw-semibold border-0">
                      Working Hours / Day
                    </th>
                    <th
                      className="days bg-transparent fs-md fw-semibold border-0"
                      colSpan="7"
                    >
                      Check the work days
                    </th>
                  </tr>
                </thead>
                {updateScheduleTaskUserList.length > 0 ? (
                  <tbody>
                    {updateScheduleTaskUserList.map(
                      (updatedScheduleUser, index) => {
                        return (
                          <tr key={index}>
                            <td className="member border-bottom-0">
                              <div className="profile d-flex align-items-center gap-2">
                                <div
                                  className="avatar rounded-circle overflow-hidden"
                                  style={{
                                    width: "2.188rem",
                                    height: " 2.188rem",
                                  }}
                                >
                                  <img
                                    src={
                                      updatedScheduleUser.assigneduserimage ===
                                      ""
                                        ? assetImages.defaultUser
                                        : url.SERVER_URL +
                                          updatedScheduleUser.assigneduserimage
                                    }
                                    alt="userimage"
                                    className="w-100 h-100 object-fit-cover object-center"
                                  />
                                </div>
                                <div>
                                  <p className="fs-md fw-semibold mb-0">
                                    {updatedScheduleUser.assignedusername === ""
                                      ? "Unassigned"
                                      : updatedScheduleUser.assignedusername}
                                  </p>
                                  <p className="d-block lh-1">
                                    {updatedScheduleUser.tasks?.length} tasks
                                  </p>
                                </div>
                              </div>
                            </td>
                            <td className="work-hours border-bottom-0">
                              <input
                                type="number"
                                className="form-control fs-sm text-gray bg-white py-2 border-gray-300 shadow-none"
                                style={{ width: "6.25rem" }}
                                value={updatedScheduleUser.workhours}
                                onChange={(e) => {
                                  updateWorkHourHandler(e.target.value, index);
                                }}
                              />

                              {validation.workHours[index] && (
                                <div className="error-message mt-1">
                                  <p className="d-flex align-items-center gap-1 text-danger">
                                    <span className="material-symbols-outlined icon-sm">
                                      warning
                                    </span>
                                    <span>{validation.workHours[index]}</span>
                                  </p>
                                </div>
                              )}
                            </td>

                            <td className="days border-bottom-0">
                              <div className="form-check d-flex align-items-center gap-2 fw-semibold p-0 m-0">
                                <input
                                  type="checkbox"
                                  id="mon"
                                  className="form-check-input bg-transparent border-2 border-gray-600 shadow-none m-0 cursor-pointer"
                                  checked={updatedScheduleUser.Monday}
                                  onChange={(e) => {
                                    updateCheckBoxHandler(
                                      e.target.checked,
                                      index,
                                      "Monday"
                                    );
                                  }}
                                />
                                <label
                                  className="form-check-label cursor-pointer"
                                  htmlFor="mon"
                                >
                                  Mon
                                </label>
                              </div>
                            </td>
                            <td className="days border-bottom-0">
                              <div className="form-check d-flex align-items-center gap-2 fw-semibold p-0 m-0">
                                <input
                                  type="checkbox"
                                  id="tue"
                                  className="form-check-input bg-transparent border-2 border-gray-600 shadow-none m-0 cursor-pointer"
                                  checked={updatedScheduleUser.Tuesday}
                                  onChange={(e) => {
                                    updateCheckBoxHandler(
                                      e.target.checked,
                                      index,
                                      "Tuesday"
                                    );
                                  }}
                                />
                                <label
                                  className="form-check-label cursor-pointer"
                                  htmlFor="tue"
                                >
                                  Tue
                                </label>
                              </div>
                            </td>
                            <td className="days border-bottom-0">
                              <div className="form-check d-flex align-items-center gap-2 fw-semibold p-0 m-0">
                                <input
                                  type="checkbox"
                                  id="wed"
                                  className="form-check-input bg-transparent border-2 border-gray-600 shadow-none m-0 cursor-pointer"
                                  checked={updatedScheduleUser.Wednesday}
                                  onChange={(e) => {
                                    updateCheckBoxHandler(
                                      e.target.checked,
                                      index,
                                      "Wednesday"
                                    );
                                  }}
                                />
                                <label
                                  className="form-check-label cursor-pointer"
                                  htmlFor="wed"
                                >
                                  Wed
                                </label>
                              </div>
                            </td>
                            <td className="days border-bottom-0">
                              <div className="form-check d-flex align-items-center gap-2 fw-semibold p-0 m-0">
                                <input
                                  type="checkbox"
                                  id="thu"
                                  className="form-check-input bg-transparent border-2 border-gray-600 shadow-none m-0 cursor-pointer"
                                  checked={updatedScheduleUser.Thursday}
                                  onChange={(e) => {
                                    updateCheckBoxHandler(
                                      e.target.checked,
                                      index,
                                      "Thursday"
                                    );
                                  }}
                                />
                                <label
                                  className="form-check-label cursor-pointer"
                                  htmlFor="thu"
                                >
                                  Thu
                                </label>
                              </div>
                            </td>
                            <td className="days border-bottom-0">
                              <div className="form-check d-flex align-items-center gap-2 fw-semibold p-0 m-0">
                                <input
                                  type="checkbox"
                                  id="fri"
                                  className="form-check-input bg-transparent border-2 border-gray-600 shadow-none m-0 cursor-pointer"
                                  checked={updatedScheduleUser.Friday}
                                  onChange={(e) => {
                                    updateCheckBoxHandler(
                                      e.target.checked,
                                      index,
                                      "Friday"
                                    );
                                  }}
                                />
                                <label
                                  className="form-check-label cursor-pointer"
                                  htmlFor="fri"
                                >
                                  Fri
                                </label>
                              </div>
                            </td>
                            <td className="days border-bottom-0">
                              <div className="form-check d-flex align-items-center gap-2 fw-semibold p-0 m-0">
                                <input
                                  type="checkbox"
                                  id="sat"
                                  className="form-check-input bg-transparent border-2 border-gray-600 shadow-none m-0 cursor-pointer"
                                  checked={updatedScheduleUser.Saturday}
                                  onChange={(e) => {
                                    updateCheckBoxHandler(
                                      e.target.checked,
                                      index,
                                      "Saturday"
                                    );
                                  }}
                                />
                                <label
                                  className="form-check-label cursor-pointer"
                                  htmlFor="sat"
                                >
                                  Sat
                                </label>
                              </div>
                            </td>
                            <td className="days border-bottom-0">
                              <div className="form-check d-flex align-items-center gap-2 fw-semibold p-0 m-0">
                                <input
                                  type="checkbox"
                                  id="sun"
                                  name="sun"
                                  className="form-check-input bg-transparent border-2 border-gray-600 shadow-none m-0 cursor-pointer"
                                  checked={updatedScheduleUser.Sunday}
                                  onChange={(e) => {
                                    updateCheckBoxHandler(
                                      e.target.checked,
                                      index,
                                      "Sunday"
                                    );
                                  }}
                                />
                                <label
                                  className="form-check-label cursor-pointer"
                                  htmlFor="sun"
                                >
                                  Sun
                                </label>
                              </div>
                            </td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                ) : null}
              </table>
            </div>
          </div>

          <div className="d-flex align-items-center justify-content-between">
            {/* <!-- ========== Start date Section ========== --> */}
            <div className="d-flex align-items-center gap-2">
              <div className="form-group mb-4">
                <label
                  htmlFor="deadline"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  Start Date
                </label>
                <div className="field-container d-flex flex-column flex-lg-row gap-3 gap-lg-0 align-items-lg-center px-lg-3 body-lg-bg rounded-10">
                  <input
                    type="date"
                    id="startdate"
                    className="form-control fs-sm shadow-none"
                    placeholder="Start Date"
                    value={startDate}
                    onChange={(e) => {
                      setstartDate(e.target.value);
                      setValidation((prevState) => ({
                        ...prevState,
                        startDate: false,
                      }));
                    }}
                  />
                </div>
              </div>
              {/* <!-- ========== Start warning Section ========== --> */}
              {validation.startDate && (
                <div className="error-message mt-2">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined icon-sm">
                      warning
                    </span>
                    <span>Please enter start date!</span>
                  </p>
                </div>
              )}
            </div>

            {/* <!-- ========== End date Section ========== --> */}

            <div className="action">
              <button
                type="button"
                className="btn btn-primary"
                onClick={saveTaskScheduleHandler}
                disabled={isUpdating ? true : false}
                style={{
                  cursor: isUpdating ? "not-allowed" : "pointer",
                }}
              >
                Update Schedule
                {isUpdating && (
                  <div
                    className="mx-2 spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                )}
              </button>
            </div>
          </div>

          {/* <!-- ========== End warning Section ========== --> */}
        </form>
      </div>
    </div>
  );
};

export default UpdateTaskSchedulePopup;
