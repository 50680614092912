import React from "react";
import { Link } from "react-router-dom";
//import dnds
import { Droppable, Draggable } from "react-beautiful-dnd";

import KanbanCardElem from "../KanbanCard/KanbanCardElem";
import { formatNumber } from "helper/Common/CommonHelper";

const KanbanColumn = ({
  provided = {},
  columnData = {},
  viewAccessCount = 0,
  setSelectedComponentTagId,
  collapseHandler,
  isProcessOwner,
  setSelectedInvoiceId,
  deleteOpportunityHandler,
  totalInvoiceAmount = 0,
  createContactChatHandler,
  showAddNewIcon = false,
}) => {
  return (
    <div
      className="col-lg-3 d-flex flex-column gap-2 "
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
    >
      <div className="card-title gradient-light p-3 rounded-10 d-flex justify-content-between align-items-center">
        <h3 className="lh-1 mb-0">{columnData.processtitle}</h3>
        <div className="counting_arws d-flex align-items-center gap-3">
          <h4 className="text-primary fw-semibold fs-md mb-0">
            {viewAccessCount}
          </h4>
          {showAddNewIcon ? (
            <Link
              to="#"
              className="bg-gray-300 rounded-5 p-1"
              data-bs-toggle="offcanvas"
              data-bs-target="#addNewKanbanInvoice"
              aria-controls="addNewKanbanInvoice"
              onClick={() => {
                setSelectedComponentTagId(columnData.tagid);
              }}
            >
              <span className="material-symbols-outlined fs-lg">
                add_circle
              </span>
            </Link>
          ) : null}
          <Link
            to="#"
            className="bg-gray-300 rounded-5 p-1"
            onClick={() => {
              collapseHandler(true, columnData.componentid);
            }}
          >
            <span className="material-symbols-outlined fs-lg">
              chevron_left
            </span>
          </Link>
        </div>
      </div>

      <Droppable droppableId={columnData.tagid} type="opportunity">
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={{
              height: "400px",
              overflowY: "scroll",
            }}
            className="pt-1"
          >
            {columnData?.invoicelist.map((invoiceItem, invoiceIndex) => {
              return (
                <Draggable
                  key={invoiceItem._id}
                  draggableId={invoiceItem._id}
                  index={invoiceIndex}
                >
                  {(provided) => {
                    return (
                      <KanbanCardElem
                        columnData={columnData}
                        deleteOpportunityHandler={deleteOpportunityHandler}
                        invoiceItem={invoiceItem}
                        isProcessOwner={isProcessOwner}
                        provided={provided}
                        setSelectedInvoiceId={setSelectedInvoiceId}
                        createContactChatHandler={createContactChatHandler}
                      />
                    );
                  }}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>

      <div className="total_count rounded text-center bg-white px-3 py-2">
        <h5 className="fs-lg text-black d-flex align-items-center gap-2 mb-0">
          <span className="text-black fw-semibold">Total:</span>{" "}
          <span className="price">{formatNumber(totalInvoiceAmount)} </span>
          <span className="d-block material-symbols-outlined icon-sm">
            euro
          </span>
          <span className="material-symbols-outlined icon-fill fs-md text-gray">
            info
          </span>
        </h5>
      </div>
    </div>
  );
};

export default KanbanColumn;
